<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - Revista DEMS</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/revista/img-banner-revista.webp" alt="..." class="img-fluid rounded shadow" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Publicaciones</h2>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
      <div class="col text-center" v-for="publication of publications">
        <a :href="publication.path" target="_blank">
          <img :src="publication.image" alt="..." class="img-fluid rounded shadow">
          <p class="fw-bold c_base">{{ publication.name }}</p>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Daniel Ponce Maldonado',
          area: 'Departamento de Vinculación y Fortalecimiento Acádemico',
          telefono: '449 149 2400 ext. 7816',
          fecha: '02/08/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "RevistaDEMSView",
  components: {
    RIComponent
  },
  data() {
    return {
      publications: [
        {
          name: "Revista DEMS 1° Edición",
          type: "file",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/revista/01-Revista DEMS.pdf",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/revista/01-Revista DEMS.webp"
        },
        {
          name: "Revista DEMS 2° Edición",
          type: "file",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/revista/02-Revista DEMS.pdf",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/revista/02-Revista DEMS.webp"
        },
        {
          name: "Revista DEMS 3° Edición",
          type: "file",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/revista/03-Revista DEMS.pdf",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/revista/03-Revista DEMS.webp"
        },
        {
          name: "Revista DEMS 4° Edición",
          type: "file",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/revista/04-Revista DEMS.pdf",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/revista/04-Revista DEMS.webp"
        },
        {
          name: "Revista DEMS 5° Edición",
          type: "file",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/revista/05-Revista DEMS.pdf",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/revista/05-Revista DEMS.webp"
        },
        {
          name: "Revista DEMS 6° Edición",
          type: "file",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/revista/06-Revista DEMS.pdf",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/revista/06-Revista DEMS.webp"
        },
        {
          name: "Revista DEMS 7° Edición",
          type: "file",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/revista/07-Revista DEMS.pdf",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/revista/07-Revista DEMS.webp"
        },
        {
          name: "Revista DEMS 8° Edición",
          type: "file",
          path: "https://www.iea.gob.mx/IEA/niveles-educativos/educacion-media-superior/revista/08-Revista DEMS.pdf",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/revista/08-Revista DEMS.webp"
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
