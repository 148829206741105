<template>
  <div class="container border rounded shadow p-3">
    <div class="row">
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos" class="btn btn-ln-bg1">
          <span class="iconify" data-icon="ph:arrow-bend-down-left-bold"></span>
          Regresar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Programas Interinstitucionales</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          Los Programas Interinstitucionales, tienen como objetivo reforzar los
          contenidos académicos, además de contribuir al desarrollo de las
          competencias para el aprendizaje permanente, para el
          manejo de información, y de situaciones, así como para la convivencia y
          para la vida en sociedad.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm bg_dg-2 mb-3">
          <div class="item1">
            <p class="titulo">Programas Interinstitucionales</p>
          </div>
          <div class="item2">
            <p class="contenido">Cartel de Programas Interinstitucionales</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/interinstitucionales/Cartel-de-Programas-Insterinstitucionales.pdf" target="_blank" class="btn btn-principal-light">
              Ver cartel
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-3" id="podcast">
      <div class="col-12">
        <h2 class="subtitulo">Podcast de Programas Interinstitucionales</h2>
      </div>
      <div class="col-12 col-md-6">
        <ReproductorAudioComponent title="Programas Interinstitucionales" subtitle="Podcast IEA" :audio="audio" bg="bg_dg-11" />
      </div>
      <div class="col-12 col-md-6">
        <div class="container">
          <div class="row row-cols-1 g-1">
            <div class="col d-flex" v-for="audio of podcast">
              <a href="#podcast" class="btn btn-ln-bg1 w-100 text-start p-2" @click="changeAudio(audio)">{{ audio.name }}</a>
              <a :href="audio.path" download class="c_1 p-2">
                <span class="iconify" data-icon="material-symbols:download" width="35" height="35"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Rosario Elizabeth Gutiérrez Carreón',
          area: 'Subdirección de Relación Interinstitucional y Social',
          telefono: '449 910 5600 ext. 4074',
          fecha: '21/08/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import podcast from '@/assets/json/interinstitucionalesData.json';
import ReproductorAudioComponent from '@/pages/root/components/ReproductorAudioComponent.vue';
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "InterinstitucionalesView",
  data() {
    return {
      podcast: podcast,
      audio: podcast[0],
    }
  },
  components: {
    ReproductorAudioComponent,
    RIComponent,
  },
  methods: {
    changeAudio(audio) {
      this.audio = audio;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>