<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_video.svg" alt="...">
          <h1 class="titulo">Streaming del Instituto de Educación de Aguascalientes</h1>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col" v-if="nextEvent">
        <h2 class="subtitulo">Próximo Streaming: {{ nextEvent.title }}</h2>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" :src="nextEvent.url" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col" v-else>
        <div class="loading position-relative" style="aspect-ratio: 16/9;">
          <div class="position-absolute top-50 start-50 translate-middle">
            <p><b>PRÓXIMAMENTE</b></p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="nextEvents.length > 0">
      <div class="col">
        <h2 class="subtitulo">Próximamente</h2>
        <div class="container-fluid">
          <div class="row row-cols-1 row-cols-lg-3 g-3 mb-5">
            <div class="col" v-for="event of nextEvents">
              <div class="ratio ratio-16x9">
                <iframe width="100%" height="100%" :src="event.url" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <p class="fw-bold c_base">{{ event.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="prevEvents.length > 0">
      <div class="col">
        <h2 class="subtitulo">Previamente</h2>
        <div class="container-fluid">
          <div class="row row-cols-1 row-cols-lg-3 g-3">
            <div class="col" v-for="event of prevEvents">
              <div class="ratio ratio-16x9">
                <iframe width="100%" height="100%" :src="event.url" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <p class="fw-bold c_base">{{ event.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Instituto de Educación de Aguascalientes',
          area: 'Instituto de Educación de Aguascalientes',
          telefono: '449 910 5600',
          fecha: null
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "VideotecaView",
  components: {
    RIComponent,
  },
  data() {
    return {
      events: [
        {
          title: "Uso de Chromebooks en el Aula",
          url: "https://www.youtube.com/embed/3_b1FfGaI4s?si=gfe6Gl5mnNmingmo",
          publicationDate: "09/05/2024" // 12:00 hrs
        },
        {
          title: "Declaración Patrimonial 2024",
          url: "https://www.youtube.com/embed/YAmVHvQyyOQ?si=agR1derKELi0MYYu",
          publicationDate: "16/04/2024" // 11:00 hrs
        },
        {
          title: "Planeación Didáctica y Evaluación Formativa",
          url: "https://www.youtube.com/embed/hGEEmiAaNe4?si=JGh2wc28_yPbj6r9",
          publicationDate: "12/04/2024" // 09:00 hrs
        },
        {
          title: "La Educación en México Desde la Responsabilidad del Estado a la Protección Internacional",
          url: "https://www.youtube.com/embed/lWF0_X-_Fus?si=CFwY-UT8Q-DwCqAw",
          publicationDate: "04/04/2024" // 09:00 hrs
        },
        {
          title: "1° Foro Virtual de Discusión: Investigar para Mejorar la Educación",
          url: "https://www.youtube.com/embed/PjcAAYTZxvE?si=79Y-QZ1PN-c5Menw",
          publicationDate: "03/04/2024" // 16:00 hrs
        },
        {
          title: "1° Foro Virtual de Discusión: Investigar para Mejorar la Educación",
          url: "https://www.youtube.com/embed/Ay0TrCOFNLk?si=Rw92TtP6ZgX2RDJ4",
          publicationDate: "02/04/2024" // 16:00 hrs
        },
        {
          title: "1° Foro Virtual de Discusión: Investigar para Mejorar la Educación",
          url: "https://www.youtube.com/embed/YOC8KU4KIEk?si=ElXsSipQTgDjF-NP",
          publicationDate: "01/04/2024" // 16:00 hrs
        },
        {
          title: "¡Hablemos de Educación Multigrado!",
          url: "https://www.youtube.com/embed/6H1K6l6OFbE?si=lv1KLyp2CecFAGZC",
          publicationDate: "01/03/2024" // 11:00 hrs
        }
      ]
    }
  },
  computed: {
    prevEvents() {
      const currentDate = new Date();
      return this.events.filter((event) => this.parseDate(event.publicationDate) < currentDate);
    },
    nextEvents() {
      const currentDate = new Date();
      return this.events.filter((event) => this.parseDate(event.publicationDate) > currentDate);
    },
    nextEvent() {
      const nextEvents = this.nextEvents;
      if (nextEvents.length === 0) {
        return null; // No upcoming events
      }
      // Sorting the upcoming events by date
      nextEvents.sort((a, b) => this.parseDate(a.publicationDate) - this.parseDate(b.publicationDate));
      return nextEvents[0];
    }
  },
  methods: {
    parseDate(dateString) {
      const dateParts = dateString.split('/');
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Month is 0-based
      const year = parseInt(dateParts[2], 10);

      return new Date(year, month, day, 23, 59, 59, 0);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>