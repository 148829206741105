<template>
  <div class="container border rounded shadow p-3">
    <div class="row">
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos" class="btn btn-ln-bg1">
          <span class="iconify" data-icon="ph:arrow-bend-down-left-bold"></span>
          Regresar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Programa Expansión de la Educación Inicial (PEEI)</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Objetivo</h2>
        <p>
          General Contribuir a la ampliación de la cobertura para que las niñas y los
          niños de 0 días de nacidas(os) a 2 años 11 meses de edad accedan a los
          servicios públicos de educación inicial integral a través de las Modalidades
          Escolarizada y No Escolarizada.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Beneficiarios</h2>
        <p>
          <b>Modalidad No Escolarizada:</b> Agentes Educativos de Educación Indígena que
          implementen la Estrategia de Visitas a Hogares; · Agentes Educativos que
          implementa la Estrategia de Visitas a Hogares; · Agentes Educativos
          Comunitarios de CCAPI.
        </p>
        <p>
          <b>Modalidad Escolarizada:</b> CAI-Federalizados; · CENDI en proceso de
          regularización con inmueble federalizado; · CENDI en proceso de regularización
          con apoyo voluntario.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Descripción</h2>
        <p>
          La <b>Secretaría de Educación Pública (SEP)</b> a través del
          <b>PEEI</b> establece seis diferentes apoyos para el cumplimiento de los
          objetivos general y específicos de dicho programa:
        </p>
        <ol>
          <li>
            Fortalecer el mantenimiento preventivo y correctivo de los inmuebles en
            materia de seguridad y bienestar de niñas y niños para la operación de los
            CAI-Federalizados y CENDI en proceso de regularización con inmueble
            federalizado (Modalidad Escolarizada).
          </li>
          <li>
            Implementar, por conducto de Agentes Educativos, la Estrategia de Visitas a
            Hogares para brindar orientación a madres y padres de familia o tutoras(es),
            o Cuidadores principales sobre prácticas de crianza (Modalidad No
            Escolarizada).
          </li>
          <li>
            Operar los CCAPI, en coordinación con las autoridades educativas locales y
            municipales
          </li>
          <li>
            Capacitar a los Agentes Educativos de los servicios de educación inicial de
            la modalidad escolarizada y No Escolarizada.
          </li>
          <li>
            Apoyar a los CENDI en proceso de regularización con inmueble federalizado o
            con apoyo voluntario, con el pago al personal voluntario, los servicios
            básicos e insumos necesarios para la alimentación.
          </li>
          <li>
            Contribuir a la sensibilización social de los beneficios de la educación en
            los primeros años de vida de niñas y niños, mediante la difusión e
            implementación de la PNEI.
          </li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Reglas de Operación</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>REGLAS DE OPERACIÓN PARA EL PROGRAMA EXPANSIÓN DE LA EDUCACIÓN INICIAL PARA EL EJERCICIO FISCAL 2024</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PEEI/ROP/PEEI-ROP-2024.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Contraloría Social en el PEEI</h2>
        <p>
          Se reconoce a la Contraloría Social como el mecanismo de las personas
          beneficiarias, de manera organizada, independiente, voluntaria y honorífica,
          para verificar el cumplimiento de las metas y la correcta aplicación de los
          recursos públicos asignados a los programas federales, de conformidad con los
          artículos 69, 70 y 71 de la Ley General de Desarrollo Social; 67, 68, 69 y 70
          de su Reglamento.
        </p>
        <p>
          En materia de promoción, operación y seguimiento del mecanismo Contraloría
          Social, este programa federal se encuentra sujeto a los Lineamientos y la
          Estrategia Marco vigentes emitidos por la Secretaría de la Función Pública,
          así como a los documentos normativos (Esquema, Guía Operativa y Programa Anual
          de Trabajo de Contraloría Social) validados por dicha dependencia, mismos que
          integran la Estrategia Marco de Contraloría Social.
        </p>
        <p>
          Los Comités de Contraloría Social, para solicitar su registro deberá
          presentarse ante la Dirección General de Gestión Escolar y Enfoque Territorial
          (DGGEyET), a través de la AEL como Instancia Ejecutora en cada entidad
          federativa, un escrito libre en el que se especifique, como mínimo, el nombre
          del programa, el ejercicio fiscal de que se trate, su representación y
          domicilio legal, así como los mecanismos e instrumentos que utilizarán para el
          ejercicio de sus funciones.
        </p>
        <p>
          La Secretaría de la Función Pública, brindará orientación en la materia a
          través del correo electrónico: contraloriasocial@funcionpublica.gob.mx.
        </p>
        <p>
          Adicionalmente, la DGGEyET podrá promover e implementar los mecanismos de
          participación ciudadana en la prevención y el combate a la corrupción
          impulsados por la Secretaría de la Función Pública, por medio del uso de
          herramientas digitales, que faciliten a las personas beneficiarias y la
          ciudadanía en general, la posibilidad de incidir en dicha materia.
        </p>
      </div>
      <div class="col-12 col-lg-6">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PEEI/PEEI-Folleto-de-Contraloría-Social.pdf" target="_blank" class="pillLink m-0">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_familiaDos.svg" alt="..." class="img-fluid" />
              <div>Folleto de Contraloría Social</div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-6">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PEEI/PEEI-Tríptico-de-Contraloría-Social.pdf" target="_blank" class="pillLink m-0">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_familiaDos.svg" alt="..." class="img-fluid" />
              <div>Tríptico de Contraloría Social</div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-6">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PEEI/PEEI-Folleto-de-Comité-de-Contraloría-Social.pdf" target="_blank" class="pillLink m-0">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_ciudadano.svg" alt="..." class="img-fluid" />
              <div>Folleto de Comité de Contraloría Social</div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-6">
        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PEEI/PEEI-Tríptico-de-Comité-de-Contraloría-Social.pdf" target="_blank" class="pillLink m-0">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_ciudadano.svg" alt="..." class="img-fluid" />
              <div>Tríptico de Comité de Contraloría Social</div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Quejas y Denuncias</h2>
        <p>
          La o el beneficiario y la ciudadanía en general, podrán presentar sus quejas o
          denuncias con respecto a la ejecución de las presentes RO de manera personal,
          escrita o por internet, ante el área que establezcan las AEL por medio de las
          Contralorías o equivalentes Locales. Asimismo, a nivel central, a través del
          OIC comunicarse a los teléfonos: en la Ciudad de México al 55 36 01 86 50,
          extensiones 66232, 66224, 66227, 66229, 66242 y 66243; o del interior de la
          República al 800 22 88 368 (lada sin costo), en un horario de 09:00 a 15:00 y
          de 16:00 a 18:00 horas (tiempo del centro de México), de lunes a viernes.
          Acudir de manera personal dentro del referido horario, a las oficinas que
          ocupa el Área de Quejas de este Órgano Fiscalizador ubicado en: Avenida
          Universidad 1074, Colonia Xoco, C.P. 03330, demarcación territorial Benito
          Juárez, Ciudad de México; o a través de un escrito entregado en la Oficialía
          de Partes Común, localizada en el referido domicilio de las 09:00 a 15:00
          horas, o enviar al correo electrónico a quejas@nube.sep.gob.mx; o también en
          la página http://www.oic.sep.gob.mx/portal3/quejas.php. En EDUCATEL en la
          Ciudad de México y área metropolitana al 55 36 01 75 99, o en el interior de
          la República al 800 288 66 88 (lada sin costo), en horario de atención de
          lunes a viernes de 08:00 a 20:00 horas (tiempo del centro de México).
        </p>
        <p>
          De igual forma, podrá realizarse en el Centro de Contacto Ciudadano de la SFP,
          ubicado en Avenida Insurgentes Sur número 1735, planta baja, módulo 3, colonia
          Guadalupe Inn, demarcación territorial Álvaro Obregón, C.P. 01020, CDMX; vía
          telefónica: en el interior de la República al 800 11 28 700, y en la Ciudad de
          México al 55 20 00 20 00 y 55 20 00 30 00, en un horario de 09:00 a 18:00
          horas, de lunes a viernes; vía correspondencia: enviar escrito libre a la
          Dirección General de Denuncias e Investigaciones de la SFP con domicilio en
          Avenida Insurgentes Sur número. 1735, piso 2 ala norte, colonia Guadalupe Inn,
          demarcación territorial Álvaro Obregón, C.P. 01020.
        </p>
        <p>
          En el Instituto de Educación de Aguascalientes, Dirección de Educación Básica,
          Subdirección de Niveles de Educación Básica, Departamento de Educación
          Inicial, Coordinación Local del PEEI, con domicilio en Av. Tecnológico No.
          601, , Fracc. Ojocaliente; vía telefónica al 449 910 56 00 Ext. 4093.
        </p>
        <div class="banner_sm bg_dg-5">
          <div class="item1">
            <p class="titulo">
              Quejas y Denuncias PEEI
            </p>
          </div>
          <div class="item2">
            <p class="contenido">Programa Expansión de la Educación Inicial (PEEI)</p>
          </div>
          <div class="item3 d-flex">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PEEI/PEEI-Folleto-de-Quejas-y-Denuncias.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light me-3">
              Ver Folleto
            </a>
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PEEI/PEEI-Tríptico-de-Quejas-y-Denuncias.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light">
              Ver Tríptico
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Ma. del Carmen Hernández Torres',
          area: 'Departamento de Educación Inicial',
          telefono: '449 910 5600 ext. 4254',
          fecha: '09/07/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "PEEIView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>