<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Índice de expedientes clasificados como reservados del IEA</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Formatos</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>ÍNDICE DE EXPEDIENTES CLASIFICADOS COMO RESERVADOS (FONDO DE VIVIENDA PARA EL MAGISTERIO DE AGUASCALIENTES FOVIMAGS)</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/IECR/IECR-FOVIMAGS.xlsx" target="_blank">
                  <span class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>ÍNDICE DE EXPEDIENTES CLASIFICADOS COMO RESERVADOS (INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES IEA)</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/IECR/IECR-IEA.xlsx" target="_blank">
                  <span class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Erika Fabiola Ibarra Medina',
          area: 'Dirección de Gestión Institucional',
          telefono: '449 910 5600 ext. 4214',
          fecha: '12/07/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "IndiceExpedientesClasificadosView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>
