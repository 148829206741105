<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">
          IEA (DEB) - Sistema de Asesoría y Acompañamiento a las Escuelas
          (SisAAE)
        </h1>
        <p>
          El Sistemas de Asesoría y Acompañamiento a la Escuela (SisAAE) está
          conformado por 19 organizaciones regionales del Instituto de Educación
          de Aguascalientes (IEA), que articulan e integran, las tareas
          pedagógicas y administrativas a efecto de crear las condiciones
          necesarias para que la educación responda a las necesidades de la
          población. Estas organizaciones contribuyen a acercar el servicio
          educativo a la ciudadanía y al personal adscrito al IEA, para
          facilitar sus trámites, dudas y necesidades relacionadas con aspectos
          laborales y educativos.
        </p>
        <p>
          El SisAAE está integrado por zonas escolares de diferentes niveles y
          modalidades que forman la Educación Básica, es un equipo académico y
          administrativo, que es capaz de dar repuestas pertinentes a la
          población que le corresponde atender. El SisAAE depende de la
          Subdirección del Sistema de Asesoría y Acompañamiento a la Escuela
          adscrita a la Dirección de Educación Básica. Esta subdirección del IEA
          mantiene un puente claro y fluido, que le permite al Sistema mantener
          informado al personal educativo y administrativo de las demandas tanto
          de información como de normatividad que es necesario hacer llegar a
          todas las escuelas y a las familias de las y los estudiantes.
        </p>
        <p>
          La Subdirección del Sistema de Asesoría y Acompañamiento a la Escuela
          (SisAAE) trabaja de manera articulada con las 19 SisAAE distribuidas
          en el Municipio Capital y los municipios del Estado de Aguascalientes,
          brindando asesoría y acompañamiento en temas académicos,
          administrativos y de gestión educativa a los Titulares del SisAAE. Su
          objetivo es dirigir, orientar y supervisar su labor para asegurar la
          articulación entre niveles y modalidades de la educación básica,
          garantizando el cumplimiento de los planes y programas de estudio, así
          como el acceso equitativo a recursos educativos. Además, la
          subdirección supervisa la entrega de materiales educativos (libros de
          texto gratuitos, uniformes escolares, libros para maestros, material
          didáctico para escuelas, para maestros y alumnos, material de oficina
          y aseo), colabora en el proceso de inscripciones, y difunde
          oportunidades de formación, capacitación y actualización para el
          personal docente.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col"></div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Conoce los SisAAE</h2>

        <select class="form-select" v-model="sisAAEIndex">
          <option disabled value="">Seleccione un centro SisAEE</option>
          <option v-for="(sisAAE, index) in sisAAEs" :value="index">
            {{ sisAAE.name }}
          </option>
        </select>

        <div class="container-fluid rounded shadow p-3 mt-3">
          <div class="row">
            <div class="col">
              <div class="d-flex align-items-center">
                <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_urbano.svg" alt="..." />
                <h3 class="subtitulo">
                  {{ sisAAEs[sisAAEIndex].name }}
                </h3>
              </div>
              <p>
                El <b>{{ sisAAEs[sisAAEIndex].name }}</b> se encarga de
                gestionar, administrar, coordinar y supervisar el funcionamiento
                eficaz de los recursos humanos, materiales y de infraestructura,
                así como el de todos los planteles bajo su responsabilidad, para
                brindar los servicios educativos y las herramientas que permitan
                cumplir y mejorar la calidad en la educación. Su objetivo es
                apoyar a las supervisiones escolares en sus tareas pedagógicas y
                administrativas para crear las condiciones necesarias que
                permitan a las escuelas cumplir con su función de ofrecer una
                educación equitativa, inclusiva y de excelencia.
              </p>
              <p>
                El <b>{{ sisAAEs[sisAAEIndex].name }}</b> es un espacio donde
                Supervisores, ATP, Directores y Docentes unen esfuerzos para
                garantizar el derecho a la educación de niñas, niños y
                adolescentes, cuyo éxito en la mejora continua depende de su
                preparación, motivación, interés, compromiso y conocimiento del
                sistema educativo.
              </p>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-lg-2 g-3">
            <div class="col order-lg-2">
              <div class="d-flex align-items-center">
                <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_rutas.svg" alt="..." />
                <h3 class="subtitulo">
                  {{ sisAAEs[sisAAEIndex].name }}
                </h3>
              </div>
              <p class="fw-bold c_base">
                {{ sisAAEs[sisAAEIndex].address }}
              </p>
              <div class="d-flex align-items-center justify-content-center fw-bold c_base">
                <span class="iconify" data-icon="akar-icons:phone" width="35" height="35"></span>
                {{ sisAAEs[sisAAEIndex].phone }}
              </div>
            </div>
            <div class="col order-lg-1">
              <iframe :src="sisAAEs[sisAAEIndex].location" width="100%" height="350" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="rounded"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Francisco Díaz Alvarado',
          area: 'Subdirección del Sistema de Asesoría y Acompañamiento a la Escuela (SisAAE)',
          telefono: '449 910 5600 ext. 4068',
          fecha: '23/08/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "sisAAEsView",
  components: {
    RIComponent,
  },
  data: () => {
    return {
      sisAAEIndex: 0,
      sisAAEs: [
        {
          name: "SisAAE CENTRO",
          address: "Calle Av. Manuel Gómez Morín s/n, Complejo Tres Centurias (FICOTRECE), 20270 Aguascalientes, Ags. (Frente a Radio y Televisión de Aguascalientes).",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.204819722395!2d-102.28341305021272!3d21.888187263189497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee6c655ac885%3A0x5ea391211a567031!2sFICOTRECE!5e0!3m2!1ses!2smx!4v1676852438331!5m2!1ses!2smx",
          phone: "449 910 5696",
        },
        {
          name: "SisAAE POCITOS",
          address: "Condominio Desarrollo Ecológico Club Campestre II, Carretera a Pocitos s/n, 20130 Aguascalientes, Ags. (Frente al entronque a carretera a San Ignacio, frente a la planta tratadora de aguas residuales).",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2201.0505411733575!2d-102.32807473175305!3d21.907201087068838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429eedc471497df%3A0xcce05c1884c753d0!2sCentro%20de%20Desarrollo%20Educativo%20Pocitos%20IEA!5e0!3m2!1ses!2smx!4v1676852102209!5m2!1ses!2smx",
          phone: "449 910 5692",
        },
        {
          name: "SisAAE NORTE",
          address: "Calle Juan Bautista s/n, Esquina con Av. Pozo Bravo, Villas de Ntra. Sra. de la Asunción, 20126 Aguascalientes, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1308.6389375276658!2d-102.27646990934785!3d21.91955867703836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efca8899c16f%3A0x789c5fe3c8f0ac89!2sIEA%20CENTRO%20DE%20DESARROLLO%20EDUCATIVO%20%22NORTE%22!5e0!3m2!1ses!2smx!4v1676852197439!5m2!1ses!2smx",
          phone: "449 910 5691",
        },
        {
          name: "SisAAE JESÚS TERÁN",
          address: "Calle Guamares s/n, Villas de Ntra. Sra. de la Asunción, 20126 Aguascalientes, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2200.696450901109!2d-102.26976063324327!3d21.930110280755194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efcff23acd89%3A0x9a97d0cf3dcebeb3!2sCENTRO%20DE%20DESARROLLO%20EDUCATIVO%20JES%C3%9AS%20TERAN!5e0!3m2!1ses!2smx!4v1676852754465!5m2!1ses!2smx",
          phone: "449 910 5695",
        },
        {
          name: "SisAAE OJO CALIENTE",
          address: "Calle Cieneguillas 212, Ojocaliente II, 20196 Aguascalientes, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d925.6512211255254!2d-102.25148870806935!3d21.872771199999995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429f201dcb17fdd%3A0xfeb1de51670927ee!2sURSE%20Ojocaliente!5e0!3m2!1ses!2smx!4v1676856926350!5m2!1ses!2smx",
          phone: "449 910 5690",
        },
        {
          name: "SisAAE MORELOS",
          address: "Calle Mariano Jiménez s/n, Morelos II, 20298 Aguascalientes, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3703.118908441996!2d-102.26905045021336!3d21.852948664437196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429eded82dc75c9%3A0x869cd6a146e2a381!2sCENTRO%20DE%20DESARROLLO%20EDUCATIVO%20MORELOS!5e0!3m2!1ses!2smx!4v1676853058126!5m2!1ses!2smx",
          phone: "449 910 5698",
        },
        {
          name: "SisAAE ORIENTE",
          address: "Calle Isaac Díaz de León s/n, Solidaridad III, 20263 Aguascalientes, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1851.5376538115843!2d-102.2518165062009!3d21.85463072600087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429f20be5993719%3A0x2d440240195e4631!2sUnidad%20Regional%20de%20Servicios%20Educativos%20ORIENTE!5e0!3m2!1ses!2smx!4v1676853362681!5m2!1ses!2smx",
          phone: "449 910 5699",
        },
        {
          name: "SisAAE SUR",
          address: "Calle Lomas de Aguascalientes s/n, Lomas del Sur, 20298 Aguascalientes, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1851.897506605196!2d-102.26754188511214!3d21.826849664815988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ed82b302291d%3A0x900cef30ed9fb0ed!2sUnidad%20regional%20de%20servicios%20educativos%20Sur!5e0!3m2!1ses!2smx!4v1676854548561!5m2!1ses!2smx",
          phone: "449 910 5697",
        },
        {
          name: "SisAAE INSURGENTES",
          address: "Carretera a la Cantera s/n, Condominio San Nicolás, 20200 Aguascalientes, Ags. (Casi frente a la Universidad Tecnológica de Aguascalientes).",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1851.6533363955587!2d-102.35489918745773!3d21.845703558381512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ebf3a6bc506f%3A0x1f21189df425cf8e!2sURSE%20Insurgentes!5e0!3m2!1ses!2smx!4v1676854807531!5m2!1ses!2smx",
          phone: "449 910 5693",
        },
        {
          name: "SisAAE ASIENTOS",
          address: "Francisco Javier Martinez 750, La Loma, Villa Juárez, 20755 Asientos, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2135.4425426453286!2d-102.06398391014109!3d22.10281456753551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842a01f64306f329%3A0xbbe310d3237606a!2sUnidad%20Regional%20de%20Servicios%20Educativos%20%22Asientos%22!5e0!3m2!1ses!2smx!4v1676858079018!5m2!1ses!2smx",
          phone: "449 910 5681",
        },
        {
          name: "SisAAE CALVILLO",
          address: "Calle Priv. Artículo No. 115 Constitucional, Zona Centro, 20800 Calvillo, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.649509075967!2d-102.71257396738298!3d21.84634789649563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429cea9a7309b85%3A0x953c5526a5ba2eb3!2sURSE%20Calvillo!5e0!3m2!1ses!2smx!4v1676856283546!5m2!1ses!2smx",
          phone: "449 910 5680",
        },
        {
          name: "SisAAE COSÍO",
          address: "Calle 5 de Febrero 620, Coplamar, 20469 Cosío, Ags. (Cerca del Panteón).",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1844.7698052194175!2d-102.30638033652343!3d22.37100620000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868211519a051157%3A0xbca4d3baa51fc1dc!2sURSE%20COSIO!5e0!3m2!1ses!2smx!4v1676856007795!5m2!1ses!2smx",
          phone: "449 910 5683",
        },
        {
          name: "SisAAE EL LLANO",
          address: "Calle Guadalupe Victoria s/n, Col de Zaragoza, Palo Alto, 20330 El Llano, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d925.3512626070523!2d-101.96031360806933!3d21.918974600000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842a1ba74a1d64ed%3A0x6f4a1d3b37349408!2sUnidad%20Regional%20de%20Servicios%20Educativos%20%E2%80%9CEl%20Llano%E2%80%9D!5e0!3m2!1ses!2smx!4v1676856202585!5m2!1ses!2smx",
          phone: "449 910 5682",
        },
        {
          name: "SisAAE JESÚS MARÍA",
          address: "Calle Leona Vicario s/n Esquina con Román Loera, 20926 Jesús María, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15682.072696344816!2d-102.36389684932817!3d21.954675779494778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429e8acca22edcd%3A0xf9519195068cc6eb!2zU2lzQUFFICJKRVPDmlMgTUFSw41BIiBJRUE!5e0!3m2!1ses!2smx!4v1676856375085!5m2!1ses!2smx",
          phone: "449 910 5689",
        },
        {
          name: "SisAAE PABELLÓN DE ARTEAGA",
          address: "Calle Av. Heroico Colegio Militar 65B, Zona Centro, 20670 Pabellón de Arteaga, Ags. (Entre la Secundaria Técnica No. 3 y la gasolinera).",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29563.582882564977!2d-102.29755461426731!3d22.1470179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429e29f2ad063ef%3A0x48171c13c393eaa3!2sCentro%20De%20Desarrollo%20Educativo%20Pabell%C3%B3n%20URSE!5e0!3m2!1ses!2smx!4v1676856550858!5m2!1ses!2smx",
          phone: "449 910 5685",
        },
        {
          name: "SisAAE RINCON DE ROMOS",
          address: "Calle San Antonio s/n, Villa de Jesús, 20414 Rincón de Romos, Ags. (A la entrada de Rincón de Romos).",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d776.4732418080449!2d-102.31482873061653!3d22.21920097140301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86820917c79e468f%3A0x237035b9fc08b14f!2sUnidad%20Regional%20De%20Servicos%20Educativos%20Rinc%C3%B3n%20De%20Romos!5e0!3m2!1ses!2smx!4v1676857137443!5m2!1ses!2smx",
          phone: "449 910 5687",
        },
        {
          name: "SisAAE SAN FRANCISCO DE LOS ROMO",
          address: "Calle Naranjo s/n, Agropecuario La Trinidad, 20303 San Francisco de los Romo, Ags.",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3697.545026438337!2d-102.27351365020931!3d22.066993056829844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429e3567b00632d%3A0xbd5e078117303c09!2sUnidad%20Regional%20de%20Servicios%20Educativos!5e0!3m2!1ses!2smx!4v1676857276800!5m2!1ses!2smx",
          phone: "449 910 5688",
        },
        {
          name: "SisAAE SAN JOSÉ DE GRACIA",
          address: "Calle Prof. Edmundo Gámez Orozco Norte s/n, San José de Gracia, Ags. (A un costado de la Secundaria Técnica Número 6).",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d923.8302027339174!2d-102.4159393138289!3d22.151857099999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86820aa28381d425%3A0xe46cb2358509ad67!2sEscuela%20Secundaria%20Tecnica%20%23%206!5e0!3m2!1ses!2smx!4v1676857882284!5m2!1ses!2smx",
          phone: "449 910 5686",
        },
        {
          name: "SisAAE TEPEZALÁ",
          address: "Carretera Rincón de Romos - Loreto s/n, San Antonio, 20616 Tepezalá, Ags. (Cerca de la Secundaria Técnica No. 14).",
          location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d923.3342265616579!2d-102.2479078966191!3d22.227292499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8682077f6a2fc3fb%3A0xf559edfaf8f0ac6b!2sURSE%20TEPEZAL%C3%81!5e0!3m2!1ses!2smx!4v1676857563383!5m2!1ses!2smx",
          phone: "449 910 5684",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
