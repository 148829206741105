/**
 * Módulo RootModule. Contiene las definiciones de Componentes (Components), 
 * Vistas (Views) y Rutas (Routes) de la Página Oficial de la Dependencia del
 * IEA (https://www.iea.gob.mx).
 * 
 * * Componente principal: ModuleComponent.vue
 * * Módulo de rutas: /routes/index.js
 * * Componentes: /components
 * * Vistas: /views
 * 
 * * Página Web: https://www.iea.gob.mx
 */

import routes from "./routes";

export default {
  routes
};