<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Normatividad</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Constituciones</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Constitución</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CONSTITUCIÓN POLÍTICA DE LOS ESTADOS UNIDOS MEXICANOS</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/CPEUM.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CONSTITUCIÓN POLÍTICA DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-5-1.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Leyes Generales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Ley General</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>DECRETO por el que se reforma el artículo 63 y se adiciona un artículo 63 Bis a la Ley General de los Derechos de Niñas, Niños y Adolescentes.</td>
              <td class="text-end">
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5690245&fecha=26/05/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE VÍCTIMAS</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LGV.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE COMUNICACIÓN SOCIAL</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LGCS.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE CULTURA FÍSICA Y DEPORTE</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LGCFD.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY FEDERAL DEL TRABAJO</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LFT.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE ARCHIVOS</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LGA.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE ACCESO DE LAS MUJERES A UNA VIDA LIBRE DE VIOLENCIA</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LGAMVLV.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE BIENES NACIONALES</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGBN.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE CONTABILIDAD GUBERNAMENTAL</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGCG_300118.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE EDUCACIÓN</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGE.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE LOS DERECHOS DE NIÑAS, NIÑOS Y ADOLESCENTES</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LGDNNA.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE MEJORA REGULATORIA</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGMR_200521.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE PRESTACION DE SERVICIOS PARA LA ATENCIÓN, CUIDADO    Y DESARROLLO INTEGRAL INFANTIL</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGPSACDII_250618.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE RESPONSABILIDADES ADMINISTRATIVAS</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGRA.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE SALUD</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGS.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE TRANSPARENCIA Y ACCESO A LA INFORMACIÓN PÚBLICA</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGTAIP_200521.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DEL SISTEMA NACIONAL ANTICORRUPCION</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGSNA_200521.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DEL SISTEMA PARA LA CARRERA DE LAS MAESTRAS Y LOS MAESTROS</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGSCMM_300919.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL PARA LA IGUALDAD ENTRE MUJERES Y HOMBRES</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGIMH.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL PARA LA INCLUSIÓN DE LAS PERSONAS CON DISCAPACIDAD</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LGIPD.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY ORGÁNICA DE LA ADMINISTRACIÓN PÚBLICA FEDERAL</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LOAPF.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY GENERAL DE EDUCACIÓN SUPERIOR</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGES_200421.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Acuerdos Generales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Acuerdo General</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CALENDARIO ESCOLAR 2024-2025, EDUCACIÓN PREESCOLAR, PRIMARIA Y SECUNDARIA 190 DÍAS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10242.pdf#page=22" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CALENDARIO ESCOLAR 2024-2025, PARA LA EDUCACIÓN NORMAL Y DEMÁS PARA LA FORMACIÓN DE MAESTRAS Y MAESTROS DE EDUCACIÓN BÁSICA 194 DÍAS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10268.pdf#page=87" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CALENDARIO ESCOLAR 2024-2025, PARA LA EDUCACIÓN MEDIA SUPERIOR/ PLAN SEMESTRAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10268.pdf#page=88" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CALENDARIO ESCOLAR 2024-2025, PARA LA EDUCACIÓN MEDIA SUPERIOR/ PLAN CUATRIMESTRAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10268.pdf#page=89" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CALENDARIO ESCOLAR 2024-2025, PARA LA EDUCACIÓN MEDIA SUPERIOR/ PLAN SEMESTRAL. TELEBACHILLERATOS COMUNITARIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10268.pdf#page=90" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>AVISO por el que se da a conocer el incremento en remuneraciones acordado para el personal a que se refieren los artículos 26 y 26-A de la Ley de Coordinación Fiscal.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5733003&fecha=11/07/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 10/09/23 por el que se establecen las normas generales para la evaluación del aprendizaje, acreditación, promoción, regularización y certificación de las alumnas y los alumnos de educación preescolar, primaria y secundaria.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5703099&fecha=27/09/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 12/06/24 por el que se modifican las Reglas de Operación del Programa para el Desarrollo Profesional Docente para el ejercicio fiscal 2024, emitidas mediante diverso número 23/12/23, publicado el 29 de diciembre de 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5730347&fecha=13/06/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 11/06/24 por el que se emiten los Lineamientos para formular los Programas de Fortalecimiento de las Capacidades de Gestión y de Administración Escolar en Educación Básica.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5730346&fecha=13/06/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 10/06/24 por el que se establecen los calendarios escolares para el ciclo lectivo 2024-2025, aplicables en toda la República para la educación preescolar, primaria, secundaria, normal y demás para la formación de maestras y maestros de educación básica.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5729964&fecha=10/06/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO por el que se reforman, adicionan y derogan diversas disposiciones del Reglamento de la Ley General de Cultura Física y Deporte.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5721837&fecha=01/04/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 04/03/24 por el que se modifican los Lineamientos por los que se conceptualizan y definen las opciones educativas del tipo superior, emitidos mediante diverso número 20/10/22, publicado el 25 de octubre de 2022.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5721838&fecha=01/04/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>AVISO por el que se da a conocer la publicación en la página web de la Unidad del Sistema para la Carrera de las Maestras y los Maestros, de los acuerdos para el ciclo escolar 2024-2025, que en el mismo se señalan.</td>
              <td class="text-end">
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5716958&fecha=15/02/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>AVISO por el que se da a conocer la publicación del Código de Conducta para las personas servidoras públicas de la Unidad del Sistema para la Carrera de las Maestras y los Maestros, en la página web de la Unidad del Sistema para la Carrera de las Maestras y los Maestros.</td>
              <td class="text-end">
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5716959&fecha=15/02/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 17/12/23 por el que se emiten las Reglas de Operación del Programa La Escuela es Nuestra para el ejercicio fiscal 2024.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5712643&fecha=27/12/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 18/12/23 por el que se emiten las Reglas de Operación del Programa Fortalecimiento a la Excelencia Educativa para el ejercicio fiscal 2024.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5712644&fecha=27/12/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 19/12/23 por el que se emiten las Reglas de Operación del Programa para la Atención de Planteles Públicos de Educación Media Superior con Estudiantes con Discapacidad para el ejercicio fiscal 2024.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5712645&fecha=27/12/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 09/08/23 por el que se establece y regula el Marco Curricular Común de la Educación Media Superior.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5699835&fecha=25/08/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 02/06/23 por el que se establecen los calendarios escolares para el ciclo lectivo 2023-2024, aplicables en toda la República para la educación preescolar, primaria, secundaria, normal y demás para la formación de maestras y maestros de educación básica.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5693346&fecha=26/06/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>AVISO por el que se da a conocer la publicación en la página web de la Unidad del Sistema para la Carrera de las Maestras y los Maestros, de los acuerdos que en el mismo se señalan.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5689661&fecha=23/05/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 22/10/22 por el que se abrogan los acuerdos secretariales que en el mismo se indican.</td>
              <td class="text-end">
                <a href="http://www.diariooficial.gob.mx/nota_detalle.php?codigo=5669802&fecha=27/10/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 33/12/22 por el que se emiten las Reglas de Operación del Programa para la Atención de Planteles Públicos de Educación Media Superior con Estudiantes con Discapacidad para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676174&fecha=30/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 36/12/22 por el que se emiten las Reglas de Operación del Programa Fortalecimiento a la Excelencia Educativa para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676176&fecha=30/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 38/12/22 por el que se emiten las Reglas de Operación del Programa Beca Universal para Estudiantes de Educación Media Superior Benito Juárez para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676177&fecha=30/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 39/12/22 por el que se emiten las Reglas de Operación del Programa Educación Inicial y Básica Comunitaria para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676178&fecha=30/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo número 27/12/22 por el que se emiten las Reglas de Operación del Programa Fortalecimiento de los Servicios de Educación Especial (PFSEE) para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676022&fecha=29/12/2022" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo número 30/12/22 por el que se emiten las Reglas de Operación del Programa de Cultura Física y Deporte para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676025&fecha=29/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo número CB/AI/2022-09/074 por el que el Colegio de Bachilleres otorga Reconocimiento de Validez Oficial de Estudios a la ESN Sunland, S.C., para que a través de la Escuela Sierra Nevada Sunland, imparta estudios de tipo medio superior.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676028&fecha=29/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo número 28/12/22 por el que se emiten las Reglas de Operación del Programa Expansión de la Educación Inicial para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676023&fecha=29/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo número 29/12/22 por el que se emiten las Reglas de Operación del Programa de Becas de Educación Básica para el Bienestar Benito Juárez para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676024&fecha=29/12/2022" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo número 31/12/22 por el que se emiten las Reglas de Operación del Programa la Escuela es Nuestra para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5676026&fecha=29/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO Número 25/12/22 por el que se emiten las Reglas de Operación del Programa Educación para Adultos (INEA) para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/scii/normatividad/ACUERDO-251222.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 449 por el que se establecen las competencias que definen el Perfil del Director en los planteles que imparten educación del tipo medio superior.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7aa2c3ff-aab8-479f-ad93-db49d0a1108a/a449.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 592 por el que se establece la Articulación de la Educación Básica. (Se abroga el Acuerdo número 07/06/17), (Se deja sin efecto el presente Acuerdo, conforme lo indica el artículo Cuarto Transitorio del Acuerdo número 12/10/17); VER Acuerdo número 26/10/21)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/9721849d-666e-48b7-8433-0eec1247f1ab/a592.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 03/03/15 por el que se abrogan las disposiciones que se indican. (Acuerdo número 364 y Acuerdo número 636)</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/bd689123-e7c5-48b7-834c-35403f75f709/a03_03_15.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 08/07/16 por el que se establece el Plan de Estudios del Bachillerato Internacional. (Abroga el Acuerdo número 91)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/1ac9318e-758d-4506-b7a3-0d00478b85d9/a08_07_16.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 29/12/16 por el que se abrogan las disposiciones que se indican. (Ver Acuerdos: número 399; número 498; número 659, y número 697)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/1ac9318e-758d-4506-b7a3-0d00478b85d9/a29_12_16.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 02/04/17 por el que se modifica el diverso número 286 por el que se establecen los lineamientos que determinan las normas y criterios generales, a que se ajustarán la revalidación de estudios realizados en el extranjero y la equivalencia de estudios, así como los procedimientos por medio de los cuales se acreditarán conocimientos correspondientes a niveles educativos o grados escolares adquiridos en forma autodidacta, a través de la experiencia laboral o con base en el régimen de certificación referido a la formación para el trabajo. (Abroga los Acuerdos número 328, 379 y 07/06/15); (Ver Acuerdo número 286)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/38735731-9155-46a2-9584-369e119dc079/a02_04_17.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 12/10/17 por el que se establece el plan y los programas de estudio para la educación básica: aprendizajes clave para la educación integral. (Se abroga el Acuerdo número 07/06/17) (Se abroga el Acuerdo 592, conforme lo indica el artículo Cuarto Transitorio del presente Acuerdo) (Una vez que entre en vigor el presente Acuerdo quedará abrogado el Acuerdo número 593) Modificado mediante el Acuerdo número 15/06/19)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/38735731-9155-46a2-9584-369e119dc079/a12_10_17.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 05/02/18 por el que se expiden las normas para el ingreso, promoción y otorgamiento de estímulos del personal académico en las escuelas normales y demás para la formación de maestros de educación básica dependientes de la Secretaría de Educación Pública, y se establece la Comisión Técnica Nacional como una instancia de asesoría en esa materia.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/15509/3/images/a05_02_18.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 15/08/18 por el que se abrogan las disposiciones que se indican. Abroga los Acuerdos 139; 180; 599; 635, y el 28/12/16.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/15509/3/images/a15_08_18.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 19/11/18 por el que se emiten los Lineamientos de Operación del Programa de la Reforma Educativa. (Abroga el Acuerdo número 24/12/17), (Modificado mediante el Acuerdo número 17/08/19)</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/15509/3/images/a19_11_18.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 15/06/19 por el que se modifica el diverso número 12/10/17 por el que se establece el plan y los programas de estudio para la educación básica: aprendizajes clave para la educación integral. (Ver Acuerdo número 12/10/17)</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/17615/3/images/a15_06_19.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 17/08/19 por el que se modifica el diverso número 19/11/18 por el que se emiten los Lineamientos de Operación del Programa de la Reforma Educativa. (Modifica el Acuerdo número 19/11/18)</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/17615/3/images/a17_08_19.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 24/08/21 por el que se da el aviso respecto de la conclusión del periodo de suspensión de los plazos y términos relacionados con los trámites y procedimientos administrativos que se llevan a cabo ante la Secretaría de Educación Pública.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/21119/2/images/a24_08_21.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 27/10/21 por el que se modifica el diverso número 653 por el que se establece el Plan de Estudios del Bachillerato Tecnológico. (VER Acuerdo número 653) (Una vez que quede implementado el Marco Curricular Común de la Educación Media Superior, queda abrogado mediante el Acuerdo número 17/08/22)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/21119/2/images/a27_10_21.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL QUE SE DETERMINA EL REGRESO A CLASES PRESENCIALES DE FORMA GRADUAL Y ESCALONADA</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/8730.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS GENERALES PARA LA OPERACIÓN DE LOS PROGRAMAS INTERINSTITUCIONALES DE LA DIRECCION DE SERVICIOS EDUCATIVOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-19-37.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Leyes Federales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Ley Federal</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>LEY DE LA COMISIÓN NACIONAL DE LOS DERECHOS HUMANOS</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LCNDH.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE DISCIPLINA FINANCIERA DE LAS ENTIDADES FEDERATIVAS Y LOS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/FED-18-86.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY AGRARIA</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LAgra.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS DEL SECTOR PÚBLICO</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/14_200521.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE AMPARO, REGLAMENTARIA DE LOS ARTÍCULOS 103 Y 107 DE LA CONSTITUCIÓN POLÍTICA DE LOS ESTADOS UNIDOS MEXICANOS</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LAmp.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE COORDINACIÓN FISCAL</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LCF.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE FISCALIZACIÓN Y RENDICIÓN DE CUENTAS DE LA FEDERACIÓN</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LFRCF_200521.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE FOMENTO PARA LA LECTURA Y EL LIBRO</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LFLL.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE LA COMISIÓN NACIONAL DE LOS DERECHOS HUMANOS</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/47_200521.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE PREMIOS, ESTÍMULOS Y RECOMPENSAS CIVILES</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LPERC.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DEL IMPUESTO AL VALOR AGREGADO</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LIVA.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DEL IMPUESTO SOBRE LA RENTA</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LISR.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DEL INSTITUTO DE SEGURIDAD Y SERVICIOS SOCIALES DE LOS TRABAJADORES DEL ESTADO</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LISSSTE.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DEL INSTITUTO DEL FONDO NACIONAL DE LA VIVIENDA PARA LOS TRABAJADORES</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LIFNVT.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DEL SEGURO SOCIAL</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LSS.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY FEDERAL DE PRESUPUESTO Y RESPONSABILIDAD HACENDARIA</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LFPRH.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY FEDERAL DE PROCEDIMIENTO ADMINISTRATIVO</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/112_180518.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY FEDERAL DE PROCEDIMIENTO CONTENCIOSO ADMINISTRATIVO</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LFPCA_270117.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY FEDERAL DE RESPONSABILIDAD PATRIMONIAL DEL ESTADO</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LFRPE.pdf.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY FEDERAL DE TELECOMUNICACIONES Y RADIODIFUSION</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LFTR.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY FEDERAL DE TRANSPARENCIA Y ACCESO A LA INFORMACIÓN PÚBLICA</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LFTAIP.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY FEDERAL PARA PREVENIR Y ELIMINAR LA DISCRIMINACIÓN</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LFPED.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY REGLAMENTARIA DEL ARTÍCULO 3° DE LA CONSTITUCIÓN POLÍTICA DE LOS ESTADOS UNIDOS MEXICANOS, EN MATERIA DE MEJORA CONTINUA DE LA EDUCACIÓN</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LRArt3_MMCE_300919.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY SOBRE EL ESCUDO, LA BANDERA Y EL HIMNO NACIONALES</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LEBHN.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Códigos Federales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Código Federal</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CÓDIGO CIVIL FEDERAL</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/2_110121.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CÓDIGO FEDERAL DE PROCEDIMIENTOS CIVILES</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/CFPC.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CÓDIGO FISCAL DE LA FEDERACION</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/CFF.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CÓDIGO PENAL FEDERAL</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/CPF.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CÓDIGO NACIONAL DE PROCEDIMIENTOS PENALES</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/CNPP.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Decretos Federales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Decreto Federal</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>DECRETO PARA LA CELEBRACIÓN DE CONVENIOS EN EL MARCO DEL ACUERDO NACIONAL PARA LA MODERNIZACIÓN DE LA EDUCACIÓN BÁSICA	19/05/1992	</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/b490561c-5c33-4254-ad1c-aad33765928a/07103.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Reglamentos Federales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Reglamento Federal</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>REGLAMENTO INTERIOR DE LA SECRETARÍA DE EDUCACIÓN PÚBLICA</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/scii/normatividad/REGLAMENTO Interior de la Secretaría de Educación Pública.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY DE ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS DEL SECTOR PÚBLICO</td>
              <td class="text-end">
                <a href="https://www.diputados.gob.mx/LeyesBiblio/regley/Reg_LAASSP.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY DEL IMPUESTO SOBRE LA RENTA</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/regley/Reg_LISR_060516.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY FEDERAL DE LAS ENTIDADES PARAESTATALES</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/regley/Reg_LFEP.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY GENERAL DE LA INFRAESTRUCTURA FÍSICA EDUCATIVA</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/regley/Reg_LGIFE.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY GENERAL DE LOS DERECHOS DE NIÑAS, NIÑOS Y ADOLESCENTES</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/regley/Reg_LGDNNA.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY GENERAL DE VICTIMAS</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/regley/Reg_LGV.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LAS CONDICIONES GENERALES DE TRABAJO DEL PERSONAL DE LA SECRETARÍA DE EDUCACIÓN PÚBLICA</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/15110/1/images/reglamento_condiciones_generales_trabajo_sep.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DEL CODIGO FISCAL DE LA FEDERACION</td>
              <td class="text-end">
                <a href="http://www.diputados.gob.mx/LeyesBiblio/regley/Reg_CFF.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE ESCALAFON DE LOS TRABAJADORES AL SERVICIO DE LA SECRETARIA DE EDUCACION PUBLICA</td>
              <td class="text-end">
                <a href="https://www.sep.gob.mx/work/models/sep1/Resource/24e8c91d-d2fc-4977-ad19-dc572c3e4439/reglamento_escalafon_sep.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Acuerdos Federales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Acuerdo Federal</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CRITERIOS que deben cumplir las instancias evaluadoras para los procesos valorativos, cualitativos, continuos y formativos de la mejora continua de la educación.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5734054&fecha=19/07/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Convenio número 0190/2023, por el cual se emite el de Apoyo Financiero, en lo sucesivo el “CONVENIO”, que tiene por objeto establecer las bases conforme a las cuales “LA SEP” y “EL GOBIERNO DEL ESTADO” proporcionarán subsidio para la operación de los planteles del Telebachillerato Comunitario en el Estado de Aguascalientes durante el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/scii/normatividad/CONVENIO-019023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 05/04/24 por el que se emiten los Lineamientos para la integración, operación y funcionamiento de los Consejos Técnicos Escolares de Educación Básica.</td>
              <td class="text-end">
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5722476&fecha=08/04/2024#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 26/10/21 por el que se determina que los Lineamientos de ajuste a las horas lectivas señaladas en el Acuerdo número 592 por el que se establece la Articulación de la Educación Básica, emitidos mediante diverso número 01/01/20, continuarán aplicándose durante el ciclo escolar 2021-2022.</td>
              <td class="text-end">
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5632302&fecha=12/10/2021#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>AVISO mediante el cual se da a conocer a los gobiernos de las entidades federativas la distribución y calendarización para la ministración durante el ejercicio fiscal de 2023, de los recursos correspondientes al Fondo de Aportaciones Múltiples (FAM), en sus componentes de Infraestructura Educativa Básica, Media Superior y Superior.</td>
              <td class="text-end">
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5678358&fecha=31/01/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>AVISO mediante el cual se dan a conocer las fórmulas, así como las variables consideradas para la distribución de los recursos correspondientes a la prestación de servicios de educación para adultos, previstos en el Fondo de Aportaciones para la Educación Tecnológica y de Adultos (FAETA) para el ejercicio fiscal de 2023.</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5678263&fecha=30/01/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO Número 26/12/22 por el que se emiten las Reglas de Operación del Programa Jóvenes Escribiendo el Futuro para el ejercicio fiscal 2023.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/scii/normatividad/ACUERDO-261222.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 18/08/22 por el que se establece el Modelo de Educación para la Vida, AprendeINEA, del Instituto Nacional para la Educación de los Adultos.  (Quedarán abrogados los Acuerdos números 363 y 06/03/22. En tanto, se dé cumplimiento al contenido de la estrategia metodológica señalada en el transitorio CUARTO, seguirán siendo aplicables los Acuerdos mencionados).</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a18_08_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 17/08/22 por el que se establece y regula el Marco Curricular Común de la Educación Media Superior. (Una vez que quede implementado el Marco Curricular Común de la Educación Media Superior, quedarán abrogados los Acuerdos números: 442, 444, 447, 653, 656 y 27/10/21).</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a17_08_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 16/08/22 por el que se establecen los Planes y Programas de Estudio de las Licenciaturas para la Formación de Maestras y Maestros de Educación Básica que se indican.  (Deja sin efectos el Acuerdo número 14/07/18).</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a16_08_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 15/08/22 por el que se determina que los Lineamientos de ajuste a las horas lectivas señaladas en el Acuerdo número 592 por el que se establece la Articulación de la Educación Básica, emitidos mediante diverso número 01/01/20, continuarán aplicándose durante el ciclo escolar 2022-2023.  (VER Acuerdo número 592 y Acuerdo número 01/01/20)</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a15_08_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 14/08/22 por el que se establece el Plan de Estudio para la educación preescolar, primaria y secundaria.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a14_08_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 13/08/22 por el que se modifica el diverso número 05/02/18 por el que se expiden las Normas para el ingreso, promoción y otorgamiento de estímulos del personal académico en las escuelas normales y demás para la formación de maestros de educación básica dependientes de la Secretaría de Educación Pública, y se establece la Comisión Técnica Nacional como una instancia de asesoría en esa materia. (VER Acuerdo número 05/02/18).</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a13_08_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo número 01/01/22 por el que se expedirá título profesional a los egresados de las escuelas formadoras de docentes</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5641139&amp;fecha=21/01/2022" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 02/02/22 por el que se emiten los Lineamientos Generales para la impartición del Tipo Medio Superior mediante la Opción de Educación Dual., (Abroga el Acuerdo número 06/06/15) </td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a02_02_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 06/03/22 por el que se establece la estructura curricular de AprendeINEA del Instituto Nacional para la Educación de los Adultos en la modalidad no escolarizada, en línea. </td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a06_03_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 07/03/22 por el que se emite la Política Nacional de Educación Inicial.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a07_03_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td> ACUERDO número 02/02/22 por el que se emiten los Lineamientos Generales para la impartición del Tipo Medio Superior mediante la Opción de Educación Dual</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/22906/1/images/a02_02_22.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO NACIONAL PARA LA MODERNIZACIÓN DE LA EDUCACIÓN BÁSICA</td>
              <td class="text-end">
                <a href="https://www.sep.gob.mx/work/models/sep1/Resource/b490561c-5c33-4254-ad1c-aad33765928a/07104.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 37, por el cual la Dirección General de Relaciones Internacionales tendrá a su cargo representar a la Secretaría de Educación    Pública ante las Entidades y Organismos Internacionales que realicen    funciones en materia educativa y cultural.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/d3c9731a-2b58-4778-a9c9-ec1a7021eb34/a37.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 71, por el que se determinan objetivos y contenidos del ciclo de Bachillerato. (Adicionado mediante Acuerdo número 77)&nbsp;    (Ver&nbsp;FE de erratas&nbsp;del Acuerdo número 71)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/d3c9731a-2b58-4778-a9c9-ec1a7021eb34/a71.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 75 por el que se instituye el Registro Nacional de Establecimientos Educativos y demás Centros de Trabajo de la Secretaría de    Educación Pública.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/d3c9731a-2b58-4778-a9c9-ec1a7021eb34/a75.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 77 por el que se adiciona el diverso número 71 que determina objetivos y contenidos del ciclo de Bachillerato. (Adiciona el    Acuerdo número 71)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/d3c9731a-2b58-4778-a9c9-ec1a7021eb34/a77.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 93, que establece normas para la prestación de servicios del personal directivo y funcionarios docentes de los Institutos Tecnológicos    dependientes de la Secretaría de Educación Pública.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/d3c9731a-2b58-4778-a9c9-ec1a7021eb34/a93.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 96, que establece la organización y funcionamiento de las escuelas primarias.</td>
              <td class="text-end">
                <a href="https://www.aefcm.gob.mx/convivencia/conoce_marco/archivos/acuerdo96.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 97, que establece la organización y funcionamiento de las Escuelas Secundarias Técnicas.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/d3c9731a-2b58-4778-a9c9-ec1a7021eb34/a97.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 98, por el que se establece la organización y funcionamiento de las Escuelas de Educación Secundaria.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/d3c9731a-2b58-4778-a9c9-ec1a7021eb34/a98.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 131, por el que se otorga reconocimiento de validez oficial a los estudios de tipo medio superior que imparta la Universidad del    Valle de México, A.C.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/3f848841-8476-4596-9835-d71e306221a3/a131.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 132, por el que se establece la organización de la Escuela Nacional de Entrenadores Deportivos y se aprueba su plan de estudios    correspondiente.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/3f848841-8476-4596-9835-d71e306221a3/a132.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 142, por el que se otorga reconocimiento de validez oficial a los estudios de tipo medio superior, licenciatura y posgrado que    imparta la Universidad Tecnológica de México, dependiente de la Universidad    Tecnológica de México, S.C.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/3f848841-8476-4596-9835-d71e306221a3/a142.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO QUE ESTABLECE LAS BASES MÍNIMAS DE INFORMACIÓN PARA LA COMERCIALIZACIÓN DE LOS SERVICIOS EDUCATIVOS QUE PRESTAN LOS PARTICULARES</td>
              <td class="text-end">
                <a href="https://sep.gob.mx/work/models/sep1/Resource/915b6a5c-4d36-4209-9310-5f9d2ed95508/acuerdo_comercializacion_particulares.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 205, por el que se determinan los lineamientos generales para regular el otorgamiento de becas en las instituciones particulares, de    educación primaria y secundaria que cuentan con autorización de estudios, así    como las de educación inicial, preescolar y especial que cuentan con    reconocimiento de validez oficial de estudios otorgados por la Secretaría de    Educación Pública.&nbsp;&nbsp;</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/a205.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 224, mediante el cual las instituciones de educación de tipos medio-superior terminal y superior dependientes o incorporadas a la    Secretaría de Educación Pública darán validez a la prestación del servicio    social realizado por estudiantes que participan en los diferentes modelos de    educación comunitaria que opera el Consejo Nacional de Fomento Educativo    (CONAFE).</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/a224.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 243 POR EL QUE SE ESTABLECEN LAS BASES GENERALES DE AUTORIZACIÓN O RECONOCIMIENTO DE VALIDEZ OFICIAL DE ESTUDIOS</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/a243.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 251 por el que se establece la Comisión Mexicana de    Cooperación con la UNESCO.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/3af6bc9c-c085-498a-968b-9cc825563f70/a251.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 254 POR EL QUE SE ESTABLECEN LOS TRÁMITES Y    PROCEDIMIENTOS RELACIONADOS CON LA AUTORIZACIÓN PARA IMPARTIR EDUCACIÓN    PRIMARIA</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/a254.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 255 POR EL QUE SE ESTABLECEN LOS TRÁMITES Y    PROCEDIMIENTOS RELACIONADOS CON LA AUTORIZACIÓN PARA IMPARTIR EDUCACIÓN    SECUNDARIA</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/a255.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 276 POR EL QUE SE ESTABLECEN LOS TRÁMITES Y    PROCEDIMIENTOS RELACIONADOS CON LA AUTORIZACIÓN PARA IMPARTIR EDUCACIÓN    SECUNDARIA TÉCNICA</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/a276.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ADÉNDUM AL ACUERDO 276 por el que se establecen los trámites y    procedimientos relacionados con la autorización para impartir educación    secundaria técnica, publicado el 27 de junio de 2000.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/adendumacuerdo276.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 284, por el que se establece el Plan de Estudios de la    Licenciatura en Educación Secundaria en modalidad mixta, para la superación y    el perfeccionamiento profesional de los profesores en servicio.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/a284.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 286, por el que se establecen los lineamientos que    determinan las normas y criterios generales, a que se ajustarán la    revalidación de estudios realizados en el extranjero y la equivalencia de    estudios, así como los procedimientos por medio de los cuales se acreditarán    conocimientos correspondientes a niveles educativos o grados escolares    adquiridos en forma autodidacta, a través de la experiencia laboral o con    base en el régimen de certificación referido a la formación para el trabajo.&nbsp; (Modificado por los Acuerdos número 328 y    379); (Modificado mediante el ACUERDO número 07/06/15); (Modificado mediante    el ACUERDO número 02/04/17)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/a286.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 295, por el que se establece el plan y los programas de    estudio para la Educación Secundaria a Distancia para Adultos.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/42b00ee7-33da-4bff-85e3-ef45b0f75255/a295.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 357 por el que se establecen los requisitos y procedimientos relacionados con la autorización para impartir educación preescolar. (Abroga el Acuerdo número 278)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/ca8cef5b-610b-4d55-8a52-03f1b84d0d6c/a357.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 363 por el que se establece el Modelo Educación para la Vida y el Trabajo. (En tanto, se dé cumplimiento al contenido de la estrategia metodológica señalada en el transitorio CUARTO, queda abrogado mediante el ACUERDO número 18/08/22)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/ca8cef5b-610b-4d55-8a52-03f1b84d0d6c/a363.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 384 por el que se establece el nuevo Plan y Programas de Estudio para Educación Secundaria.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/ca8cef5b-610b-4d55-8a52-03f1b84d0d6c/a384.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 442 por el que se establece el Sistema Nacional de    Bachillerato en un marco de diversidad</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7aa2c3ff-aab8-479f-ad93-db49d0a1108a/a442.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 444 por el que se establecen las competencias que    constituyen el marco curricular común del Sistema Nacional de    Bachillerato.&nbsp;</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7aa2c3ff-aab8-479f-ad93-db49d0a1108a/a444.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 445 por el que se conceptualizan y definen para la    Educación Media Superior las opciones educativas en las diferentes    modalidades.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7aa2c3ff-aab8-479f-ad93-db49d0a1108a/a445.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 447 por el que se establecen las competencias docentes    para quienes impartan educación media superior en la modalidad escolarizada.    (Modificado mediante el Acuerdo número 488)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7aa2c3ff-aab8-479f-ad93-db49d0a1108a/a447.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 450 POR EL QUE SE ESTABLECEN LOS LINEAMIENTOS QUE    REGULAN LOS SERVICIOS QUE LOS PARTICULARES BRINDAN EN LAS DISTINTAS OPCIONES    EDUCATIVAS EN EL TIPO MEDIO SUPERIOR</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7aa2c3ff-aab8-479f-ad93-db49d0a1108a/a450.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 480 POR EL QUE SE ESTABLECEN LOS LINEAMIENTOS PARA EL    INGRESO DE INSTITUCIONES EDUCATIVAS AL SISTEMA NACIONAL DE BACHILLERATO</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7aa2c3ff-aab8-479f-ad93-db49d0a1108a/a480.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 482 por el que se establecen las disposiciones para    evitar el mal uso, el desvío, o la incorrecta aplicación de los recursos del    Fondo de Aportaciones para la Educación Básica y Normal (FAEB).</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7aa2c3ff-aab8-479f-ad93-db49d0a1108a/a482.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 484 por el que se establecen las bases para la creación    y funcionamiento del Comité Directivo del Sistema Nacional de Bachillerato.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7aa2c3ff-aab8-479f-ad93-db49d0a1108a/a484.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 538 por el que se abrogan diversas disposiciones    administrativas de la Secretaría de Educación Pública.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/7ecc5f47-dbca-4e16-9f2c-d1a863283b61/a538.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 653 POR EL QUE SE ESTABLECE EL PLAN DE ESTUDIOS DEL    BACHILLERATO TECNOLÓGICO</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/9721849d-666e-48b7-8433-0eec1247f1ab/a653.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 634 por el que se dan a conocer los requisitos y plazos    de respuesta a que quedan sujetos diversos trámites y servicios que presta la    Secretaría de Educación Pública, así como los formatos&nbsp; aplicables a los mismos.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/9721849d-666e-48b7-8433-0eec1247f1ab/a634.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 645 por el que se instituye la Semana Nacional de la    Alimentación Escolar.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/9721849d-666e-48b7-8433-0eec1247f1ab/a645.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 654 POR EL QUE SE ESTABLECE LA COMISIÓN DE BECAS Y    APOYOS ECONÓMICOS A ESTUDIANTES</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/9721849d-666e-48b7-8433-0eec1247f1ab/a654.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 656 por el que se reforma y adiciona el Acuerdo número    444 por el que se establecen las competencias que constituyen el marco    curricular común del Sistema Nacional de Bachillerato, y se adiciona el    diverso número 486 por el que se establecen las competencias disciplinares    extendidas del bachillerato general.&nbsp;    (VER Acuerdos número 444 y 486)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/9721849d-666e-48b7-8433-0eec1247f1ab/a656.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 717 por el que se emiten los lineamientos para formular    los Programas de Gestión Escolar.&nbsp; (Se    deroga el “Capítulo IV, mediante el Acuerdo número 15/10/17)</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/62550be0-b2d6-4c65-9324-0f0ca932e616/a717.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 01/03/14 por el que se abrogan los acuerdos    secretariales que en el mismo se indican, en virtud de que su objeto se ha    cumplido; se dan a conocer los que han quedado sin efectos, por disposición    expresa o por tener establecida una vigencia temporal, así como los que    continúan vigentes para el sector educativo.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/62550be0-b2d6-4c65-9324-0f0ca932e616/a01_03_14.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO MEDIANTE EL CUAL SE ESTABLECEN LOS LINEAMIENTOS    GENERALES PARA EL EXPENDIO, DISTRIBUCIÓN DE ALIMENTOS Y BEBIDAS PREPARADOS Y    PROCESADOS EN LAS ESCUELAS DEL SISTEMA EDUCATIVO NACIONAL</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/62550be0-b2d6-4c65-9324-0f0ca932e616/aalimentosbebidaspreparados.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 05/06/14 por el que se emiten los lineamientos de    operación del Programa Escuelas de Excelencia para Abatir el Rezago    Educativo. (Modificado mediante el ACUERDO número 28/12/14)</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/62550be0-b2d6-4c65-9324-0f0ca932e616/a05_06_14.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 02/05/16 POR EL QUE SE ESTABLECEN LOS LINEAMIENTOS PARA    LA CONSTITUCIÓN, ORGANIZACIÓN Y FUNCIONAMIENTO CONSEJOS DE&nbsp; PARTICIPACIÓN SOCIAL EN LA EDUCACIÓN.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/1ac9318e-758d-4506-b7a3-0d00478b85d9/a02_05_16.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 08/08/17 QUE MODIFICA EL DIVERSO NÚMERO 02/05/16 POR EL    QUE SE ESTABLECEN LOS LINEAMIENTOS PARA LA CONSTITUCIÓN, ORGANIZACIÓN, Y    FUNCIONAMIENTO DE LOS CONSEJOS DE PARTICIPACIÓN SOCIAL EN LA EDUCACIÓN,    PUBLICADO EL 11 DE MAYO DE 2016.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/38735731-9155-46a2-9584-369e119dc079/a08_08_17.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 15/10/17 POR EL QUE SE EMITEN LOS LINEAMIENTOS PARA LA ORGANIZACIÓN Y FUNCIONAMIENTO DE LOS CONSEJOS TÉCNICOS ESCOLARES DE EDUCACIÓN    BÁSICA.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/38735731-9155-46a2-9584-369e119dc079/a15_10_17.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 17/11/17 POR LE QUE SE ESTABLECEN LOS TRAMITES Y PROCEDIMIENTOS RELACIONADOS CON EL RECONOCIMIENTO DE VALIDEZ OFICIAL DE    ESTUDIOS DEL TIPO SUPERIOR</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/15215/2/images/a17_11_17.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 01/01/18 POR EL QUE SE ESTABLECE Y REGULA EL SISTEMA    NACIONAL DE EDUCACIÓN MEDIA SUPERIOR.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/15509/2/images/a01_01_18.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 03/01/18 POR EL QUE SE ESTABLECEN LOS TRÁMITES Y    PROCEDIMIENTOS RELACIONADOS CON EL RECONOCIMIENTO DE VALIDEZ OFICIAL DE    ESTUDIOS DE FORMACIÓN PARA EL TRABAJO.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/15509/2/images/a03_01_18.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 04/01/18 POR EL QUE SE ESTABLECE EL PROGRAMA DE    EDUCACIÓN INICIAL: UN BUEN COMIENZO.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/15509/2/images/a04_01_18.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 07/03/18 POR EL QUE SE EMITEN LOS LINEAMIENTOS GENERALES    DEL SISTEMA DE INFORMACIÓN Y GESTIÓN EDUCATIVA.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/15509/2/images/a07_03_18.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 11/05/18 por el que se emiten los Lineamientos para el    desarrollo y el ejercicio de la autonomía curricular en las escuelas de    educación básica del Sistema Educativo Nacional.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/15509/2/images/a11_05_18.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 18/11/18 POR EL    QUE SE EMITEN LOS LINEaMIENTOS POR LOS QUE SE CONCEPTUALIZAN Y DEFINEN LOS    NIVELES, MODALIDADES Y OPCIONES EDUCATIVAS DEL TIPO SUPERIOR</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/15509/2/images/a18_11_18.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 11/03/19 POR EL QUE SE ESTABLECEN LAS NORMAS GENERALES    PARA LA EVALUACION DEL APRENDIZAJE, ACREDITACION, PROMOCION, REGULARIZACION Y    CERTIFICACION DE LOS EDUCANDOS DE LA EDUCACION BASICA.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/17615/2/images/a11_03_19.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 12/05/19 por el que se modifica el diverso número    15/10/17 por el que se emiten los Lineamientos para la organización y    funcionamiento de los Consejos Técnicos Escolares de Educación Básica.&nbsp;</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/17615/2/images/a12_05_19.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 14/05/19 por el que se expiden los Lineamientos    Específicos para que las Autoridades Escolares soliciten autorización para    realizar ajustes al Calendario Escolar que determine la Secretaría de    Educación Pública.&nbsp; (Abroga los    Acuerdos número 06/06/16 y 06/05/17)</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/17615/2/images/a14_05_19.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 20/11/19 POR EL QUE SE MODIFICA EL DIVERSO NÚMERO    12/10/17 POR EL QUE SE ESTABLECE EL PLAN Y LOS PROGRAMAS DE ESTUDIO PARA LA    EDUCACIÓN BÁSICA: APRENDIZAJES CLAVE PARA LA EDUCACIÓN INTEGRAL.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/17615/2/images/a20_11_19.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 02/03/20 por el que se suspenden las clases en las    escuelas de educación preescolar, primaria, secundaria, normal y demás para    la formación de maestros de educación básica del Sistema Educativo Nacional,    así como aquellas de los tipos medio superior y superior dependientes de la    Secretaría de Educación Pública.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/19413/3/images/a02_03_20.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 06/03/20 por el que se amplía el periodo suspensivo del 27 de marzo al 30 de abril del año en curso y se modifica el diverso número    02/03/20 por el que se suspenden las clases en las escuelas de educación    preescolar, primaria, secundaria, normal y demás para la formación de    maestros de educación básica del Sistema Educativo Nacional, así como    aquellas de los tipos medio superior y superior dependientes de la Secretaría    de Educación Pública.&nbsp;</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/19413/3/images/a06_03_20.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 08/04/20 por el que se modifica el diverso número 17/11/17 por el que se establecen los trámites y procedimientos relacionados    con el reconocimiento de validez oficial de estudios del tipo superior.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/19413/3/images/a08_04_20.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 09/04/20 por el que se amplía el periodo suspensivo del    23 de marzo al 30 de mayo del año en curso y se modifica el diverso número    02/03/20 por el que se suspenden las clases en las escuelas de educación    preescolar, primaria, secundaria, normal y demás para la formación de    maestros de educación básica del Sistema Educativo Nacional, así como    aquellas de los tipos medio superior y superior dependientes de la Secretaría    de Educación Pública.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/19413/3/images/a09_04_20.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 10/05/20 por el que se modifica el diverso número    03/03/20 por el que se suspenden los plazos y términos relacionados con los    trámites y procedimientos administrativos que se llevan a cabo ante la    Secretaría de Educación Pública.&nbsp;</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/19413/3/images/a10_05_20.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 11/06/20 por el que se modifica el diverso número 03/03/20 por el que se suspenden los plazos y términos relacionados con los trámites y procedimientos administrativos que se llevan a cabo ante la Secretaría de Educación Pública.</td>
              <td class="text-end">
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5594651&fecha=09/06/2020#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO 13/06/20 por el que se dan a conocer los medios de    comunicación electrónica para la recepción de solicitudes de reconocimiento    de validez oficial de estudios del tipo superior, así como para la    autenticación de certificados de estudios parciales y/o totales, títulos,    diplomas y grados de Instituciones Particulares de Educación Superior, y su    respectiva fecha de apertura.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/19413/3/images/a13_06_20.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 23/09/20 por el que se amplía provisionalmente el    plazo previsto en el artículo 22 del Acuerdo número 17/11/17 por el que se    establecen los trámites y procedimientos relacionados con el reconocimiento    de validez oficial de estudios del tipo superior.</td>
              <td class="text-end">
                <a href="http://sep.gob.mx/work/models/sep1/Resource/19413/3/images/a23_09_20.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 03/01/21 por el que se establecen medidas    extraordinarias para agilizar ante la Secretaría de Educación Pública los    trámites y procedimientos que se indican.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/21119/1/images/a03_01_21.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 04/02/21 por el que de manera extraordinaria se    implementa el uso de medios electrónicos para agilizar ante la Secretaría de    Educación Pública la realización de los trámites y procedimientos que se    indican.</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/21119/1/images/a04_02_21.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO por el que se emiten los Lineamientos para la selección,    adquisición y distribución de libros de texto gratuitos del nivel secundaria</td>
              <td class="text-end">
                <a href="http://dof.gob.mx/nota_detalle.php?codigo=5613532&amp;fecha=12/03/2021" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 11/05/21 por el que se modifica el diverso número    13/06/20 por el que se dan a conocer los medios de comunicación electrónica    para la recepción de solicitudes de reconocimiento de validez oficial de    estudios del tipo superior, así como para la autenticación de certificados de    estudios parciales y/o totales, títulos, diplomas y grados de instituciones    particulares de educación superior, y su respectiva fecha de apertura.&nbsp; (VER Acuerdo número 13/06/20)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/21119/2/images/a11_05_21.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 13/06/21 por el que se modifica el diverso número    03/03/20 por el que se suspenden los plazos y términos relacionados con los    trámites y procedimientos administrativos que se llevan a cabo ante la    Secretaría de Educación Pública.&nbsp; (VER    Acuerdo número 03/03/20)</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/21119/2/images/a13_06_21.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO número 10/06/22 por el que se emiten los Lineamientos que regulan la operación y funcionamiento del Registro Nacional de Opciones para Educación Superior</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5655697&amp;fecha=21/06/2022&num;gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Lineamientos Federales</h2>
        <table class="table table-stripted table-hover">
          <thead>
            <tr>
              <th>Lineamieto Federal</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ENTORNOS ESCOLARES SEGUROS EN ESCUELAS DE EDUCACIÓN BÁSICA</td>
              <td class="text-end">
                <a href="https://educacionbasica.sep.gob.mx/multimedia/RSC/BASICA/Documento/202009/202009-RSC-leLPWSqZY7-5_EntornosEscolaresSegurosenEscuelasdeEducacinBsicaSimplificadoSept2020.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Leyes Locales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Ley Local</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>DECRETO NÚMERO 766.- SE EXPIDE LA LEY DE BIBLIOTECAS PÚBLICAS Y DE FOMENTO A LA LECTURA DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10268.pdf#page=17" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 770.- SE REFORMA LA LEY DE EDUCACIÓN PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10268.pdf#page=26" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 743.- SE REFORMA LA LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10254.pdf#page=4" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 710.- SE REFORMA LA LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10232.pdf#page=33" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 555.- SE REFORMA LA LEY PARA PREVENIR, ATENDER Y ERRADICAR LA VIOLENCIA ESCOLAR EN EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10105.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 567.- SE REFORMA LA LEY DE EDUCACIÓN DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10105.pdf#page=59" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 568.- SE REFORMA LA LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10105.pdf#page=60" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 576.- SE EXPIDE LA LEY DE INGRESOS DEL ESTADO DE AGUASCALIENTES, PARA EL EJERCICIO FISCAL DEL AÑO 2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10076.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 575.- SE REFORMA LA LEY DE LA ADMINISTRACIÓN PÚBLICA ESTATAL; Y SE EXPIDE LA LEY DE BIENES PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10075.pdf#page=11" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 459.- SE ADICIONA LA LEY DE ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS DEL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10037.pdf#page=19" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 452.- SE ADICIONA LA LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10037.pdf#page=13" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 448.- SE REFORMA LA LEY DE EDUCACIÓN DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10037.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 412.- SE CREA LA LEY PARA LA PREVENCIÓN, ATENCIÓN INTEGRAL Y CONTROL DE LAS ADICCIONES PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9915.pdf#page=24" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 402.- SE REFORMA LA LEY DE EDUCACIÓN DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9915.pdf#page=6" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 366.- SE REFORMAN LA LEY DE ACCESO DE LAS MUJERES A UNA VIDA LIBRE DE VIOLENCIA PARA EL ESTADO DE AGUASCALIENTES; EL CÓDIGO CIVIL DEL ESTADO DE AGUASCALIENTES; LA LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES PARA EL ESTADO DE AGUASCALIENTES; Y, EL CÓDIGO PENAL PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9741.pdf#page=10" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 364- SE REFORMA LA LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9741.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 355- SE REFORMA LA LEY DE ACCESO DE LAS MUJERES A UNA VIDA LIBRE DE VIOLENCIA PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9690.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 356.- SE REFORMAN LA LEY DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS SUJETOS OBLIGADOS DEL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS; LA LEY DE TRANSPARENCIA Y ACCESO A LA INFORMACIÓN PÚBLICA DEL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS; Y, LA LEY DE LA COMISIÓN DE DERECHOS HUMANOS DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9690.pdf#page=3" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 357.- SE REFORMA LA LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9690.pdf#page=4" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PRESUPUESTO DE EGRESOS DEL ESTADO DE AGUASCALIENTES, PARA EL EJERCICIO FISCAL DEL AÑO 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-30-6.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE INGRESOS DEL ESTADO DE AGUASCALIENTES PARA EL EJERCICIO FISCAL DEL AÑO 2023</td>
              <td class="text-end">
                <a href="https://congresoags.gob.mx/agenda_legislativa/descargar_pdf/4042" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE PLANEACION PARA EL DESARROLLO DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-171.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE SALUD DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-139.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE VÍCTIMAS DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-177.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE ACCESO DE LAS MUJERES A UNA VIDA LIBRE DE VIOLENCIA PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-2.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS DEL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-3.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE ARCHIVOS DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-172.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE BIENES DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10075.pdf#page=11" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE COORDINACIÓN FISCAL DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-11.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE CIENCIA, TECNOLOGÍA, INNOVACIÓN Y EMPRENDIMIENTO PARA EL DESARROLLO DE LA SOCIEDAD DEL CONOCIMIENTO DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-184.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE CULTURA DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-13.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE CULTURA FÍSICA Y DEPORTE DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-14.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE EDUCACIÓN DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-168.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE FISCALIZACION SUPERIOR DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-23.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE HACIENDA DEL ESTADO DE AGUASCALIENTES&nbsp;</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-27.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE IMAGEN INSTITUCIONAL PARA EL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-23-16.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE INSTITUCIONES ASISTENCIALES PARA NIÑAS, NIÑOS Y ADOLESCENTES EN EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-154.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE LA COMISIÓN DE DERECHOS HUMANOS DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-33.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-136.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE MEJORA REGULATORIA Y GESTIÓN EMPRESARIAL PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-163.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE OBRAS PÚBLICAS Y SERVICIOS RELACIONADOS PARA EL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-159.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE PRESUPUESTO, GASTO PÚBLICO Y RESPONSABILIDAD HACENDARIA DEL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-45.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE PROFESIONES DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9508.pdf#page=3" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE PROMOCION A LA LECTURA, EL LIBRO Y LAS BIBLIOTECAS PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-48.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE PROTECCION DE DATOS PERSONALES EN POSESION DE LOS SUJETOS OBLIGADOS DEL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-120.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE REMUNERACIONES DE LOS SERVIDORES PÚBLICOS DEL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-160.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE SEGURIDAD Y SERVICIOS SOCIALES PARA LOS SERVIDORES PUBLICOS DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-137.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE RESPONSABILIDAD PATRIMONIAL DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-54.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE RESPONSABILIDADES ADMINISTRATIVAS DEL ESTADO DE    AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-124.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DE TRANSPARENCIA Y ACCESO A LA INFORMACIÓN PÚBLICA DEL    ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-59.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-169.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DEL PROCEDIMIENTO ADMINISTRATIVO DEL ESTADO DE    AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-75.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DEL PROCEDIMIENTO CONTENCIOSO ADMINISTRATIVO PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-123.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY DEL SISTEMA ESTATAL ANTICORRUPCIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-78.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY ORGÁNICA DE LA ADMINISTRACIÓN PÚBLICA ESTATAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-183.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY PARA EL CONTROL DE LAS ENTIDADES PARAESTATALES DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-92.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY PARA LA IGUALDAD ENTRE MUJERES Y HOMBRES DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-167.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY PARA PREVENIR, ATENDER Y ERRADICAR LA VIOLENCIA ESCOLAR EN EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-100.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY PARA PREVENIR Y ERRADICAR LA DISCRIMINACIÓN DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-98.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY QUE CREA EL INSTITUTO DE INFRAESTRUCTURA FÍSICA EDUCATIVA DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-107.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LEY SOBRE EL ESCUDO Y EL HIMNO DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-116.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Códigos Locales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Código</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>DECRETO NÚMERO 358.- SE REFORMA EL CÓDIGO PENAL PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9690.pdf#page=6" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CODIGO PENAL PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-4-11.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CÓDIGO CIVIL DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-4-1.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CÓDIGO DE ORDENAMIENTO TERRITORIAL, DESARROLLO URBANO Y VIVIENDA    PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-4-7.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CÓDIGO DE PROCEDIMIENTOS CIVILES DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-4-8.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CÓDIGO DE ÉTICA DE LA ADMINISTRACIÓN PÚBLICA CENTRALIZADA DEL    ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-231.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CÓDIGO FISCAL DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-4-10.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CODIGO DE CONDUCTA DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-4-97.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CODIGO DE ÉTICA DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-4-68.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Reglamentos Locales</h2>
        <table class="table table-striped table-hover">
           <thead>
            <tr>
              <th>Reglamento Local</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA EL OTORGAMIENTO DE APOYOS DE MOVILIDAD A ESTUDIANTES DE UNIVERSIDADES TECNOLÓGICAS DEL ESTADO DE AGUASCALIENTES CICLO ESCOLAR AGOSTO 2024-AGOSTO 2025</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10248.pdf#page=54" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REFORMA A LAS REGLAS DE OPERACIÓN DEL PROGRAMA DE UNIFORMES ESCOLARES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES PARA EL EJERCICIO FISCAL 2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10213.pdf#page=5" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MODIFICACIONES A LAS REGLAS DE OPERACIÓN PARA LA APLICACIÓN DE LOS “PROGRAMAS ESPECIALES” DEL REGLAMENTO PARA LA ASIGNACIÓN DE BECAS PARA EL EJERCICIO 2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10182.pdf#page=12" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MODIFICACIONES AL REGLAMENTO PARA LA ASIGNACIÓN DE BECAS EJERCICIO 2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10182.pdf#page=14" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA EL PROGRAMA DE APOYOS PARA MOVILIDAD “HARVARD MEXICO CONFERENCE 2024”</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10166.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA ENTREGA DE APOYOS A TRABAJADORES DE LA EDUCACIÓN JUBILADOS Y PENSIONADOS DEL ESTADO DE AGUASCALIENTES CORRESPONDIENTES A LAS APORTACIONES DE LOS MESES DE MAYO Y DICIEMBRE DEL EJERCICIO FISCAL 2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10166.pdf#page=5" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA ENTREGA DE APOYOS A LOS TRABAJADORES HOMOLOGADOS, DE EDUCACIÓN MEDIA SUPERIOR, SUPERIOR Y CENTROS DE CAPACITACIÓN Y FORMACIÓN PARA EL TRABAJO INDUSTRIAL Y PERSONAL SINDICALIZADO POR EL S.N.T.E. DE CONALEP Y CECYTEA, DEL ESTADO DE AGUASCALIENTES, CORRESPONDIENTES A LAS APORTACIONES DE LOS MESES DE MAYO Y DICIEMBRE DEL EJERCICIO FISCAL 2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10166.pdf#page=9" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN DEL PROGRAMA DE UNIFORMES ESCOLARES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES PARA EL EJERCICIO FISCAL 2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10148.pdf#page=10" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO INTERIOR DEL ALBERGUE ESCOLAR ANSELMO CHÁVEZ QUIROZ</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10108.pdf#page=8" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO PARA LA ASIGNACIÓN DE BECAS EJERCICIO 2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10108.pdf#page=18" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN DEL GASTO PARA PLANTELES DE TELEBACHILLERATOS COMUNITARIOS EN EL ESTADO PARA EL EJERCICIO FISCAL 2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10108.pdf#page=38" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA EL CICLO ESCOLAR 2023-2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10108.pdf#page=43" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA ENTREGA DE APOYOS DEL PROGRAMA TECNOLOCHICAS EN AGUASCALIENTES 2023-2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10020.pdf#page=3" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA EL OTORGAMIENTO DE APOYOS DE MOVILIDAD A ESTUDIANTES DE UNIVERSIDADES TECNOLÓGICAS DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9796.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE COOPERATIVAS ESCOLARES DE CONSUMO PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10012.pdf#page=31" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MODIFICACIÓN A LAS REGLAS DE OPERACIÓN PARA LA APLICACIÓN DE LOS “PROGRAMAS ESPECIALES” DEL REGLAMENTO PARA LA ASIGNACIÓN DE BECAS, FINANCIAMIENTO EDUCATIVO Y ESTÍMULOS PARA EL SEGUNDO TRIMESTRE 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9936.pdf#page=4" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
            <td>REFORMAS AL REGLAMENTO PARA LA ASIGNACIÓN DE BECAS, FINANCIAMIENTO EDUCATIVO Y ESTÍMULOS SEGUNDO SEMESTRE 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9936.pdf#page=5" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REFORMAS A LAS REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA EL CICLO ESCOLAR 2023-2024</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9946.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO PARA LA ASIGNACIÓN DE BECAS, FINANCIAMIENTO EDUCATIVO Y ESTÍMULOS SEGUNDO SEMESTRE 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9741.pdf#page=39" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA APLICACIÓN DE LOS “PROGRAMAS ESPECIALES” DEL REGLAMENTO PARA LA ASIGNACIÓN DE BECAS, FINANCIAMIENTO EDUCATIVO Y ESTÍMULOS, PARA EL SEGUNDO SEMESTRE 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9741.pdf#page=48" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA ENTREGA DE APOYOS A LOS TRABAJADORES HOMOLOGADOS, DE EDUCACIÓN MEDIA SUPERIOR, SUPERIOR Y CENTROS DE CAPACITACIÓN Y FORMACIÓN PARA EL TRABAJO INDUSTRIAL; ADSCRITO AL SUBSISTEMA DE NORMALES, UPN Y CAM Y PERSONAL SINDICALIZADO POR EL S.N.T.E. DE CONALEP Y CECYTEA, DEL ESTADO DE AGUASCALIENTES, CORRESPONDIENTES A LAS APORTACIONES DE LOS MESES DE MAYO Y DICIEMBRE DEL EJERCICIO FISCAL 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9741.pdf#page=72" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO A TRAVÉS DEL CUAL SE REGULA LA INSCRIPCIÓN, REGISTRO, SEGUIMIENTO, SUPERVISIÓN Y ACREDITACIÓN DE LAS OBLIGACIONES DE LOS COLEGIOS DE PROFESIONISTAS Y SUS MIEMBROS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9548.pdf#page=69" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE COOPERATIVAS ESCOLARES DE CONSUMO PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9629.pdf#page=10" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA EL CICLO ESCOLAR 2022-2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9610.pdf#page=13" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MANUAL DE INTEGRACIÓN Y FUNCIONAMIENTO DEL COMITÉ DE ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES EN MATERIA ESTATAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9605.pdf#page=26" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO PARA LA ASIGNACIÓN DE BECAS, FINANCIAMIENTO EDUCATIVO Y ESTÍMULOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9605.pdf#page=31" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA APLICACIÓN DE LOS PROGRAMAS ESPECIALES DEL REGLAMENTO PARA LA ASIGNACIÓN DE BECAS, FINANCIAMIENTO EDUCATIVO Y ESTÍMULOS, PARA EL EJERCICIO FISCAL 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9605.pdf#page=39" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ANEXO APROBADO EN LA PRIMERA SESIÓN ORDINARIA DEL CONSEJO INTERIOR DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES DEL 13 DE ENERO DEL 2023, CORRESPONDIENTE A LAS REGLAS DE OPERACIÓN PARA LA APLICACIÓN DE LOS PROGRAMAS DE BECAS, FINANCIAMIENTO EDUCATIVO Y ESTÍMULOS, 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9605.pdf#page=60" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>FE DE ERRATAS A LAS REFORMAS Y ADICIONES A DETERMINADAS DISPOSICIONES DEL REGLAMENTO INTERIOR DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES”, PUBLICADAS EN EL PERIÓDICO OFICIAL DEL ESTADO DE AGUASCALIENTES, NÚMERO 45, TOMO LXXXV, TERCERA SECCIÓN DE LA EDICIÓN ORDINARIA, DE FECHA 7 DE NOVIEMBRE DE 2022</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9531.pdf#page=20" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE BECAS ACADEMICAS PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-26-5.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LAS ASOCIACIONES DE PADRES DE FAMILIA PARA LAS ESCUELAS PÚBLICAS DE EDUCACIÓN BÁSICA DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-26-210.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO INTERIOR DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-26-259.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO PARA LA ASIGNACIÓN DE BECAS, FINANCIAMIENTO EDUCATIVO Y ESTÍMULOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-26-212.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO INTERIOR DEL CONSEJO PREVENTIVO DE LA VIOLENCIA ESCOLAR EN EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-26-99.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY DE ACCESO DE LAS MUJERES A UNA VIDA LIBRE DE VIOLENCIA PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-26-17.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY DE CULTURA FÍSICA Y DEPORTE DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-26-19.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-26-239.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAMENTO DE LA LEY DE OBRAS PÚBLICAS Y SERVICIOS RELACIONADOS PARA EL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-26-282.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Acuerdos Locales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Acuerdo Local</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>NORMAS PARA LA INTEGRACIÓN, INSTALACIÓN Y FUNCIONAMIENTO DE LOS COMITÉS DE BIENES DE LAS ENTIDADES PARAESTATALES DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10254.pdf#page=14" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL QUE SE ESTABLECE EL PLAN DE BACHILLERATO GENERAL, MODALIDADES ESCOLARIZADA, MIXTA Y NO ESCOLARIZADA, PERIODICIDAD SEMESTRAL Y CUATRIMESTRAL (PLAN IEA 2023)</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10044.pdf#page=6" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL CUAL LA DIRECTORA GENERAL DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES ESTABLECE EL “DÍA DE LA TELESECUNDARIA”</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9936.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO DE INCORPORACIÓN DE LOS CENTROS DE DESARROLLO INFANTIL CENDI EDUCACIÓN INICIAL Y PREESCOLAR SIERRA FRÍA, CENDI EDUCACIÓN INICIAL Y PREESCOLAR SIERRA FRÍA CALVILLO Y CENDI SIERRA FRÍA</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9851.pdf#page=20" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO MEDIANTE EL CUAL SE ABROGAN LOS LINEAMIENTOS PARA LA SUBSTANCIACIÓN DEL PROCESO DE INVESTIGACIÓN Y SANCIÓN POR PARTE DEL DIRECTOR GENERAL DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES EN MATERIA DE HOSTIGAMIENTO SEXUAL Y ACOSO SEXUAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9741.pdf#page=71" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL QUE SE INFORMA QUE EL REGRESO A CLASES, CONCLUIDO EL PERIODO VACACIONAL, SE REALIZARÁ DE FORMA PRESENCIAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9175.pdf&num;page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MANUAL DE INTEGRACIÓN Y FUNCIONAMIENTO DEL COMITÉ DE ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES EN MATERIA ESTATAL </td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-20-175.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO GENERAL 005/2022 DEL PLENO DEL INSTITUTO DE TRANSPARENCIA DEL ESTADO DE AGUASCALIENTES, MEDIANTE EL CUAL SE APRUEBAN LOS LINEAMIENTOS PARA LA PROTECCIÓN DE DATOS PERSONALES DEL ESTADO DE AGUASCALIENTES Y SUS MUNICIPIOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-568.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO QUE ESTABLECE LAS MEDIDAS PARA GARANTIZAR LA PRESTACIÓN DEL SERVICIO EDUCATIVO Y LA DISMINUCIÓN DE LOS RIESGOS A LA SALUD EN LAS COMUNIDADES ESCOLARES 10/01/2022</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/8569.pdf&num;page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO MEDIANTE EL CUAL SE ESTABLECEN LAS NORMAS GENERALES DE CONTROL INTERNO PARA LA ADMINISTRACIÓN PÚBLICA ESTATAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-12.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO QUE ESTABLECE EL REGISTRO ESTATAL DE TRÁMITES O SERVICIOS Y EL USO DE LA VENTANILLA ÚNICA DE GOBIERNO DEL ESTADO</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-61.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO QUE ESTABLECE LOS LINEAMIENTOS QUE DEBERÁN SER OBSERVADOS POR LA ADMINISTRACIÓN PÚBLICA CENTRALIZADA Y PARAESTATAL, EN    CUANTO A LA FIJACIÓN DE CARGAS REGULATORIAS.</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-60.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL QUE SE ESTABLECE EL PLAN DE ESTUDIOS DE BACHILLERATO GENERAL, MODALIDADES ESCOLARIZADA, MIXTA Y NO ESCOLARIZADA, PERIODICIDAD SEMESTRAL Y CUATRIMESTRAL (PLAN IEA 2018)</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-237.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL QUE SE EMITEN LOS LINEAMIENTOS QUE ESTABLECEN LAS FACULTADES, OBLIGACIONES Y FUNCIONAMIENTO DE LA COMISIÓN ESTATAL PARA LA PLANEACIÓN DE LA EDUCACIÓN MEDIA SUPERIOR</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-460.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL QUE SE EMITEN LOS LINEAMIENTOS QUE ESTABLECEN LAS FACULTADES, OBLIGACIONES Y FUNCIONAMIENTO DE LA COMISIÓN ESTATAL PARA LA PLANEACIÓN DE LA EDUCACIÓN SUPERIOR</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-461.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL QUE SE ESTABLECEN LOS LINEAMIENTOS PARA LA    INTEGRACIÓN Y FUNCIONAMIENTO DEL&nbsp;    SISTEMA ESTATAL PARA EL FORTALECIMIENTO DE LA EDUCACIÓN MEDIA SUPERIOR    Y SUPERIOR</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-463.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL QUE SE ESTABLECEN LOS LINEAMIENTOS PARA LA    INTEGRACIÓN, FACULTADES, OBLIGACIONES Y FUNCIONAMIENTO DEL CONSEJO ESTATAL    PARA LA EDUCACIÓN SUPERIOR EN LA MODALIDAD DUAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-462.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO POR EL QUE SE EXPIDEN LAS MEDIDAS DE SEGURIDAD SANITARIA ANTE LA REACTIVACIÓN ECONÓMICA Y SOCIAL DURANTE LA EMERGENCIA SANITARIA    GENERADA POR EL VIRUS SARS-CoV2 (COVID-19) EN EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-1-405.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS GENERALES DE LA COMISION DICTAMINADORA DE DEPURACION Y ELIMINACION DE DOCUMENTOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-19-10.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Manuales Locales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Manual Local</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>BASES GENERALES PARA LA INTEGRACIÓN, INSTALACIÓN Y FUNCIONAMIENTO DEL COMITÉ DE BIENES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10252.pdf#page=3" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MANUAL DE INTEGRACIÓN Y FUNCIONAMIENTO DEL COMITÉ DE BIENES MUEBLES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9937.pdf#page=41" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MANUAL DE LINEAMIENTOS Y POLÍTICAS GENERALES PARA EL CONTROL DE LOS RECURSOS DE LAS DEPENDENCIAS Y ENTIDADES DEL GOBIERNO DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-20-188.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MANUAL ÚNICO DE ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS DEL GOBIERNO DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-20-38.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MANUAL DE REMUNERACIONES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/scii/normatividad/MANUAL-DE-REMUNERACIONES-DEL-IEA.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Lineamientos Locales</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Lineamiento Local</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>LINEAMIENTOS INTERNOS PARA LA IMPLEMENTACIÓN DE LA FORMACIÓN CON LA PARTICIPACIÓN DE ESCUELAS NORMALES FEDERALES EN EL ESTADO Y EL CENTRO DE ACTUALIZACIÓN DEL MAGISTERIO DE AGUASCALIENTES (CAM), CON TRANSFERENCIA DE RECURSOS DEL PROGRAMA PARA EL DESARROLLO PROFESIONAL DOCENTE PARA EDUCACIÓN BÁSICA 2024, PRODEP, EN LO SUCESIVO EL PROGRAMA, QUE ESTABLECEN EL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES A TRAVÉS DE LA DIRECCIÓN DE CARRERA DEL MAGISTERIO DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10228.pdf#page=15" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ACUERDO MEDIANTE EL CUAL SE MODIFICAN LOS LINEAMIENTOS GENERALES PARA LA OPERACIÓN DE LOS PROGRAMAS INTERINSTITUCIONALES DE LA DIRECCIÓN DE SERVICIOS EDUCATIVOS”</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10108.pdf#page=16" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REFORMAS A LOS “LINEAMIENTOS PARA LA PARTICIPACIÓN DE ESCUELAS NORMALES FEDERALES Y CENTROS DE ACTUALIZACIÓN DEL MAGISTERIO EN EL ESTADO, EN LOS PROCESOS DE FORMACIÓN DE LOS DOCENTES DE EDUCACIÓN BÁSICA CON RECURSOS DEL PROGRAMA PARA EL DESARROLLO PROFESIONAL DOCENTE PARA EDUCACIÓN BÁSICA 2023, (PRODEP)”</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10108.pdf#page=17" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS GENERALES PARA EL FUNCIONAMIENTO DE LOS COMITÉS DE ÉTICA DE LA ADMINISTRACIÓN PÚBLICA ESTATAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10090.pdf#page=21" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REFORMAS AL REGLAMENTO INTERIOR DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES EN RELACIÓN A LOS LINEAMIENTOS GENERALES PARA LA CELEBRACIÓN DE SESIONES DE LOS ÓRGANOS DE GOBIERNO Y ÓRGANOS EQUIVALENTES DE LAS ENTIDADES PARAESTATALES DE LA ADMINISTRACIÓN PÚBLICA ESTATAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10045.pdf#page=16" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MODIFICACIONES A LOS LINEAMIENTOS PARA EL PROGRAMA DE REFINANCIAMIENTOS Y QUITA DE INTERESES DE CRÉDITOS EDUCATIVOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10045.pdf#page=28" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS PARA LA PARTICIPACIÓN DE ESCUELAS NORMALES FEDERALES Y CENTROS DE ACTUALIZACIÓN DEL MAGISTERIO EN EL ESTADO, EN LOS PROCESOS DE FORMACIÓN DE LOS DOCENTES DE EDUCACIÓN BÁSICA CON RECURSOS DEL PROGRAMA PARA EL DESARROLLO PROFESIONAL DOCENTE PARA EDUCACIÓN BÁSICA 2023, (PRODEP)</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10012.pdf#page=23" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REFORMAS A LOS LINEAMIENTOS GENERALES PARA ESTABLECER LAS BASES DE LA INTEGRACIÓN, ORGANIZACIÓN, ATRIBUCIONES Y FUNCIONAMIENTO DEL COMITÉ DE ÉTICA DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10012.pdf#page=32" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS DE ACTUACIÓN PARA ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES EN MATERIA ESTATAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9605.pdf#page=18" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS PARA EXPEDICIÓN DE CÉDULAS PROFESIONALES DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9548.pdf#page=72" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS PARA EL REGISTRO DE TÍTULOS PROFESIONALES Y GRADOS ACADÉMICOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9548.pdf#page=80" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS PARA EL PROGRAMA DE REFINANCIAMIENTOS Y QUITA DE INTERESES DE CRÉDITOS EDUCATIVOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9629.pdf#page=23" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS DE ACTUACIÓN PARA ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES EN MATERIA ESTATAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9605.pdf#page=18" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS PARA LA REGULACIÓN DE LOS PROCESOS Y ACTOS DE ENTREGA-RECEPCIÓN EN LA ADMINISTRACIÓN PÚBLICA ESTATAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-19-85.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS GENERALES PARA ESTABLECER LAS BASES DE LA INTEGRACIÓN, ORGANIZACIÓN, ATRIBUCIONES Y FUNCIONAMIENTO DEL COMITÉ DE ÉTICA DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-19-59.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS PARA EXPEDICIÓN DE CÉDULAS PROFESIONALES ESTATALES </td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-19-50.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS PARA EL USO DE LOS RECURSOS TECNOLÓGICOS Y DE COMUNICACIÓN DENTRO DE LAS ESCUELAS PÚBLICAS DE EDUCACIÓN BÁSICA Y MEDIA SUPERIOR</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-19-32.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS DE LA MANIFESTACIÓN DE IMPACTO REGULATORIO PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-19-13.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Protocolos</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Protocolo</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PROTOCOLO DE INSPECCIÓN PARA PREVENIR Y ERRADICAR EL TRABAJO INFANTIL Y PROTEGER EL TRABAJO ADOLESCENTE EN EDAD PERMITIDA</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/scii/normatividad/Protocolo-de-Inspección-para-Prevenir-y-Erradicar-el-Trabajo-Infantil-y-Proteger-el-Trabajo-Adolescente-en-Edad-Permitida.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLO NACIONAL DE ACTUACIÓN PARA EL PERSONAL DE LAS INSTANCIAS DE PROCURACIÓN DE JUSTICIA DEL PAÍS, EN CASOS QUE INVOLUCREN LA ORIENTACIÓN SEXUAL O LA IDENTIDAD DE GÉNERO</td>
              <td class="text-end">
                <a href="https://www.gob.mx/cms/uploads/attachment/file/383250/Protocolo_LGBTI_.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>GUÍA ESCOLAR DE INTERVENCIÓN PARA SITUACIONES DE EMERGENCIA, CRISIS Y VULNERABILIDAD</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/scii/normatividad/Guia-de-Intervencion-para-Situaciones-de-Emergencia-Crisis.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLO PARA LA PREVENCIÓN, ATENCIÓN Y SANCIÓN DE LA VIOLENCIA SEXUAL HACIA NIÑAS, NIÑOS Y ADOLESCENTES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10017.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>GUIA PARA DOCENTES, ESTRATEGIA EN EL AULA: PREVENCIÓN DE ADICCIONES</td>
              <td class="text-end">
                <a href="https://estrategiaenelaula.sep.gob.mx/storage/recursos/Guias/KRlj5XDD1b-Guia_para_docentes_10MB.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>GUÍA PARA LA PREVENCIÓN, DETECCIÓN Y REACCIÓN ANTE LA PRESENCIA DE ARMAS ENLAS ESCUELAS</td>
              <td class="text-end">
                <a href="https://www.gob.mx/cms/uploads/attachment/file/342152/Guia_prevencion_de_armas_en_las_escuelas.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MANUAL DE SEGURIDAD ESCOLAR DEL PROGRAMA ESCUELA SEGURA</td>
              <td class="text-end">
                <a href="https://educacionbasica.sep.gob.mx/multimedia/RSC/BASICA/Documento/201611/201611-3-RSC-aROCd3IpOQ-manualseguridadsep12.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLO NACIONAL DE COORDINACIÓN INTERINSTITUCIONAL PARA LA PROTECCIÓN DE NIÑAS, NIÑOS Y ADOLESCENTES VÍCTIMAS DE VIOLENCIA</td>
              <td class="text-end">
                <a href="https://www.cndh.org.mx/sites/default/files/documentos/2021-09/Protocolo_Nacional_NNA-VF-MAR2021.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLOS DE SEGURIDAD PARA LOS CENTROS EDUCATIVOS FEDERALES DE EDUCACIÓN MEDIA SUPERIOR</td>
              <td class="text-end">
                <a href="https://www.gob.mx/cms/uploads/attachment/file/184619/Protocolos-de-Seguridad.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLO PARA LA PREVENCIÓN, ATENCIÓN Y SANCIÓN DEL HOSTIGAMIENTO SEXUAL Y ACOSO SEXUAL EN LA ADMINISTRACIÓN PÚBLICA DEL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-23-94.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLO PARA PREVENIR, ATENDER, SANCIONAR Y ERRADICAR EL ACOSO Y EL HOSTIGAMIENTO SEXUAL EN LAS INSTITUCIONES FORMADORAS Y ACTUALIZADORAS DE DOCENTES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-23-145.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLO DE ACTUACIÓN ANTE LA PRESENCIA DE SUSTANCIAS ADICTIVAS O NARCÓTICOS EN LAS INSTITUCIONES DE EDUCACIÓN PÚBLICA DEPENDIENTES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-23-66.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLO DE ACTUACIÓN ANTE LA PRESENCIA O HALLAZGO DE AGENTES VULNERANTES EN LAS INSTITUCIONES DE EDUCACIÓN PÚBLICA DEPENDIENTES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-23-67.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLO DE ACTUACIÓN DEL PROGRAMA DE PREVENCIÓN “HAZLO POR TU HIJO” EN LAS INSTITUCIONES EDUCATIVAS PÚBLICAS DEPENDIENTES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-23-68.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PROTOCOLO PARA PREVENIR, ATENDER Y ERRADICAR LA VIOLENCIA ESCOLAR EN EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-23-136.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Otros</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>LISTADO DE PROFESIONES PARA CUYO EJERCICIO EN EL ESTADO DE AGUASCALIENTES SERÁ NECESARIO CONTAR CON TÍTULO PROFESIONAL</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10254.pdf#page=31" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PACTO INTERNACIONAL DE DERECHOS CIVILES Y POLÍTICOS</td>
              <td class="text-end">
                <a href="https://www.ohchr.org/sites/default/files/ccpr_SP.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PRINCIPIOS DE YOGYAKARTA</td>
              <td class="text-end">
                <a href="https://www.refworld.org/cgi-bin/texis/vtx/rwmain/opendocpdf.pdf?reldoc=y&docid=48244e9f2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA ENTREGA DE APOYOS A LOS TRABAJADORES HOMOLOGADOS, DE EDUCACIÓN MEDIA SUPERIOR, SUPERIOR Y CENTROS DE CAPACITACIÓN Y FORMACIÓN PARA EL TRABAJO INDUSTRIAL Y PERSONAL SINDICALIZADO POR EL S.N.T.E. DE CONALEP Y CECYTEA, DEL ESTADO DE AGUASCALIENTES, CORRESPONDIENTES A LAS APORTACIONES DE LOS MESES DE MAYO Y DICIEMBRE DEL EJERCICIO FISCAL 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9650.pdf#page=15" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA ENTREGA DE APOYOS A TRABAJADORES DE LA EDUCACIÓN JUBILADOS Y PENSIONADOS DEL ESTADO DE AGUASCALIENTES CORRESPONDIENTES A LAS APORTACIONES DE LOS MESES DE MAYO Y DICIEMBRE DEL EJERCICIO FISCAL 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9650.pdf#page=9" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>PLAN ESTATAL DE DESARROLLO 2022-2027</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9648.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN DEL PROGRAMA DE UNIFORMES ESCOLARES DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES PARA EL EJERCICIO FISCAL 2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9635.pdf#page=23" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>MODIFICACIÓN AL CONTENIDO DEL ARTÍCULO 44 BIS DEL REGLAMENTO INTERIOR DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9548.pdf#page=68" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REGLAS DE OPERACIÓN PARA LA ENTREGA DE APOYOS A PENSIONADOS Y JUBILADOS DE LA EDUCACIÓN DEL ESTADO DE AGUASCALIENTES, CON RELACIÓN A LA APORTACIÓN CORRESPONDIENTE AL MES DE DICIEMBRE DE 2022</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9531.pdf#page=12" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REFORMAS Y ADICIONES A LAS REGLAS DE OPERACIÓN PARA LA APLICACIÓN EN EL ESTADO DEL PROGRAMA DE APOYO A LA GESTIÓN ESCOLAR PARA EL CICLO ESCOLAR 2022-2023</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9531.pdf#page=17" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>POLÍTICAS PARA AUTORIZAR LA CONDONACIÓN DE INTERESES MORATORIOS POR LA COORDINACIÓN DE BECAS Y FINANCIAMIENTO EDUCATIVO</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-25-15.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>NORMAS GENERALES A QUE SE SUJETARÁ EL REGISTRO, AFECTACIÓN, DISPOSICIÓN FINAL Y BAJA DE LOS BIENES MUEBLES PROPIEDAD O AL SERVICIO DEL    INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-22-40.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Varios</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>DECRETO NÚMERO 527.- PUNTO DE ACUERDO POR EL QUE SE SOLICITA AL GOBIERNO DEL ESTADO DE AGUASCALIENTES, POR CONDUCTO DEL INSTITUTO DE EDUCACIÓN, GARANTICE LAS CONDICIONES LABORALES, ECONÓMICAS Y MATERIALES DE LAS Y LOS AGENTES EDUCATIVOS, A EFECTO DE QUE RECIBAN LA EDUCACIÓN INICIAL TODAS LAS NIÑAS Y NIÑOS QUE TIENEN EL DERECHO HUMANO DE RECIBIRLA</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10045.pdf#page=4" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ANEXO DE EJECUCIÓN DEL CONVENIO DE COLABORACIÓN PARA EL DESARROLLO DEL PROGRAMA FORTALECIMIENTO A LA EXCELENCIA EDUCATIVA, QUE CELEBRAN LA SECRETARÍA DE EDUCACIÓN PÚBLICA Y EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5677411&fecha=19/01/2023#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 247.- SE REFORMA EL ESTATUTO JURÍDICO DE LOS TRABAJADORES AL SERVICIO DE LOS GOBIERNOS DEL ESTADO DE AGUASCALIENTES, SUS MUNICIPIOS, ÓRGANOS CONSTITUCIONALES AUTÓNOMOS Y ORGANISMOS DESCENTRALIZADOS</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9576.pdf#page=2" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>DECRETO NÚMERO 249.- SE ADICIONA Y SE REFORMA LA LEY DE LOS DERECHOS DE LAS NIÑAS, NIÑOS Y ADOLESCENTES PARA EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/9576.pdf#page=3" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CONVENIO DE COLABORACIÓN PARA EL DESARROLLO DEL PROGRAMA FORTALECIMIENTO A LA EXCELENCIA EDUCATIVA, QUE CELEBRAN LA SECRETARÍA DE EDUCACIÓN PÚBLICA Y EL ESTADO DE AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5673168&fecha=02/12/2022#gsc.tab=0" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CONVENIO QUE CONFORMIDAD CON EL ACUERDO NACIONAL PARA LA MODERNIZACIÓN DE LA EDUCACIÓN BÁSICA CELEBRAN POR UNA PARTE, EL EJECUTIVO FEDERAL Y, POR LA OTRA, EL EJECUTIVO DEL ESTADO LIBRE Y SOBERANO DE AGUASCALIENTES CON LA COMPARECENCIA DEL INSTITUTO DE SEGURIDAD Y SERVICIOS SOCIALES DE LOS TRABAJADORES DEL ESTADO</td>
              <td class="text-end">
                <a href="http://www.sep.gob.mx/work/models/sep1/Resource/b490561c-5c33-4254-ad1c-aad33765928a/07201.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>ESTATUTO JURIDICO DE LOS TRABAJADORES AL SERVICIO DE LOS GOBIERNOS DEL ESTADO DE AGUASCALIENTES, SUS MUNICIPIOS, ORGANOS    CONSTITUCIONALES AUTONOMOS Y ORGANISMOS DESCENTRALIZADOS DEL ESTADO DE    AGUASCALIENTES</td>
              <td class="text-end">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-13-1.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" v-for="directory of normatividad">
      <div class="col-12">
        <h2 class="subtitulo">{{ directory.title }}</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) of directory.contents">
              <td>{{  file.name }}</td>
              <td class="text-end">
                <a :href="file.path" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'José Martín Salazar Sánchez',
          area: 'Departamento de Normatividad y Consulta',
          telefono: '449 910 5600 ext. 4147',
          fecha: '12/08/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

import normatividadData from '@/assets/json/normatividadData.json'

export default {
  name: "NormatividadView",
  components: {
    RIComponent
  },
  data() {
    return {
      normatividad: normatividadData
    }
  }
}
</script>

<style lang="scss" scoped>

</style>