<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div v-if="loading">
          <div v-for="index in 10" :key="index" class="d-flex flex-column flex-md-row">
            <div class="d-flex flex-fill m-3">
              <div class="loading" style="aspect-ratio: 2/1;"></div>
            </div>
            <div class="d-flex flex-column flex-fill m-3">
              <div class="loading mb-3" style="aspect-ratio: 16/2;"></div>
              <div class="loading w-25 mb-3" style="aspect-ratio: 2/1;"></div>
              <div class="loading mb-3" style="aspect-ratio: 16/1;"></div>
              <div class="loading mb-3" style="aspect-ratio: 16/1;"></div>
            </div>
          </div>
        </div>

        <div v-else class="newsWrapper">
          <div class="noticiasContenedor">
            <div class="grid-container noticias-wrapper" v-for="news of newsList">
              <div class="img-container">
                <div class="img-noticias" :style="'background-image: url(' + news.images[0].base64 + ');'"></div>
              </div>
              <div class="info-container">
                <h3 class="noticias-titulo">
                  {{ news.title }}
                </h3>
                <div class="fecha">{{ news.date }}</div>
                <p class="noticias-texto">
                  {{ news.body }}
                </p>
                <div class="link-container">
                  <a href="#" class="noticias-link wide-link" data-bs-toggle="modal" :data-bs-target="'#modal' + news.id">
                    Seguir leyendo
                  </a>
                </div>
              </div>

              <NoticiaModalComponent :news="news" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NoticiaModalComponent from '@/pages/root/components/NoticiaModalComponent.vue';

export default {
  name: 'NoticiasComponent',
  components: {
    NoticiaModalComponent,
  },
  data() {
    return {
      newsList: [],
      loading: true,
    }
  },
  created() {
    gea.iwin(() => {
      axios.get("https://servicios.iea.edu.mx/ieaws/ws-sgc/news/getAll")
      .then((response) => {
        this.loading = false;
        this.newsList = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    });
  }
}
</script>

<style lang="scss" scoped>

</style>