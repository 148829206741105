<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEB) - Cifras de la Educación</h1>
      </div>
    </div>

    <div class="row row-cols-1 justify-content-center">
      <div class="col">
        <h2 class="subtitulo">Cifras de la Educación</h2>
        <p>
          Las Cifras de la Educación del Instituto de Educación de Aguascalientes
          reportan un total de 291,299 alumnos de educación inicial, educación
          primaria y educación secundaria, en la modalidad pública y privada,
          inscritos en el ciclo escolar 2022-2023.
        </p>
      </div>
      <div class="col col-md-6">
        <div class="cartaBorde">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_familiaDos.svg">
          <p>291,299 <br /> Alumnos de Educación Básica</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Blanca Annette Venegas Haro',
          area: 'Dirección de Educación Básica',
          telefono: '449 910 5600 ext. 4196 / 4179',
          fecha: '19/10/2023'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "CifrasEducacionView",
  components: {
    RIComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>
