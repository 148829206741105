<template>
  <header class="bg-img" style="background-image: url('https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/bg-header.webp');  background-size: cover; background-repeat: no-repeat; position: relative;">
    <div class="container">
      <div class="row ">
        <div class="col-lg-12 align-middle headerContent" style="padding-bottom: 18%;">
          <p class="supLong mb-0" style="line-height: 1;">IEA</p>
          <div class="d-flex">
            <p class="inf">2023</p>
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/i_support.svg" class="digitalImg d-none d-lg-block" />
          </div>
          <p class="frase">9, 10 y 11 de noviembre de 2023</p>
        </div>
      </div>
    </div>

    <img class="divider" src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/divider.svg" alt="">
  </header>

  <main id="main" style="margin-top: 100px; margin-bottom: 120px">
    <div class="container">
      <div class="row g-5 mb-5">
        <div class="col-lg-8">
          <section>
            <div class="sliderGea swiper-container" style="display: block;">
              <div class="swiper-wrapper">
                <div class="swiper-slide p-5" style="background-color: white;" v-for="(taller, index) of talleres">
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <div class="d-flex flex-column flex-lg-row">
                          <div class="mini-1">
                            <h1>Taller {{ index + 1 }} | {{ taller.tema }}</h1>
                            <img src="https://aguascalientes.gob.mx/img/poe/other/elemento123.svg" height="24" class="d-block"/>
                          </div>
                          <p class="text-end">
                            <b class="c_1">{{ taller.fecha }} de {{ taller.horario }}</b><br />
                            <b>{{ taller.lugar }}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="pill-text bg_cyan mb-3"><b>Tallerista(s):</b></p>
                      </div>
                    </div>
                    <div class="row g-3 mb-3">
                      <div class="col" v-for="tallerista of taller.talleristas">
                        <div class="d-flex flex-column align-items-center justify-content-between">
                          <img :src="tallerista.imagen" alt="..." style="height: 100px;" class="img-fluid rounded-circle shadow" />
                          <p class="text-center">
                            <b>{{ tallerista.nombre }}</b>
                          </p>
                          <button class="btn btn-principal" @click="showTalleristaModal(tallerista)">Ver Semblanza</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="text-align: justify;">
                      <div class="col">
                        <div>
                          <p class="pill-text bg_cyan mb-3"><b>Objetivo del taller:</b></p>
                          <p>{{ taller.objetivo }}</p>
                        </div>
                        <div>
                          <p class="pill-text bg_cyan mb-3"><b>Metodología:</b></p>
                          <p>{{ taller.metodologia }}</p>
                        </div>
                        <div>
                          <p class="pill-text bg_cyan mb-3"><b>Temario:</b></p>
                          <ul>
                            <li v-for="tema in taller.temario">{{ tema }}</li>
                          </ul>
                        </div>
                        <div class="d-flex flex-column align-items-end flex-lg-row justify-content-lg-end">
                          <p class="pill-text bg_cyan mb-3 mb-lg-0 me-lg-3"><b>Público: </b>{{ taller.publico }}</p>
                          <p class="pill-text bg_rosa m-0"><b>Cupo: </b>{{ taller.cupo }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
              
              <!-- <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div> -->

              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
          </section>
        </div>
        <div class="col-lg-4">
          <section>
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/banner.webp" alt="..." class="img-fluid rounded shadow" />
          </section>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <section>
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/congreso/footer.webp" alt="..." class="img-fluid shadow border rounded" />
          </section>
        </div>
      </div>
    </div>
  </main>

  <!-- Modal -->
  <div class="modal fade" id="talleristaModal" tabindex="-1" aria-hidden="true" v-if="talleristaModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="subtitulo">Acerca del Tallerista</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="text-align: justify;">
          <div class="clearfix">
            <div class="float-none float-md-start m-auto m-md-3" style="max-width: 200px;">
              <div class="d-flex flex-column align-items-center">
                <img :src="talleristaModal.imagen" alt="..." style="height: 150px;" class="img-fluid rounded-circle shadow" />
                  <p class="c_base text-center">
                    <b>{{ talleristaModal.nombre }}</b>
                  </p>
              </div>              
            </div>
            <p class="fw-bold" v-for="parrafo in talleristaModal.semblanza.split('\n')">
              {{ parrafo }}
            </p>            
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import talleres from '@/assets/json/talleres.json';

export default {
  name: "CongresoESView",
  data: () => {
    return {
      talleres: talleres,
      talleristaModal: null
    }
  },
  created() {
    gea.iwin(function() {
      setTimeout(() => {
        const swiper = new Swiper(".swiper-container", {
          // obserkver: true,
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev"
          // },
          // scrollbar: {
          //   el: '.swiper-scrollbar'
          // },
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: true
        });

        // const swiperSliders = document.querySelectorAll('.swiper-slide');

        // swiperSliders.forEach((swiperSlider) => {
        //   swiperSlider.style.width = '100%';
        // });
      }, "1000");
    });
  },
  methods: {
    showTalleristaModal(tallerista) {
      this.talleristaModal = tallerista;

      gea.iwin(() => {
        const talleristaModal = bootstrap.Modal.getOrCreateInstance("#talleristaModal");
        talleristaModal.show();
      });
    }
  }
}
</script>

<style lang="scss" scoped></style>
