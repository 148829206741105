<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Coordinación de Becas y Financiamiento Educativo</h1>
      </div>
    </div>

    <div class="row" v-if="isValidity('05/02/2024 - 09/02/2024')">
      <div class="col">
        <div class="banner-body-left bg_6">
          <img src="https://www.iea.gob.mx/img/convocatorias-y-becas/becas/img-banner.webp" alt="..." class="img-fluid"/>
          <div class="baninfo-wrapper">
            <h1>Expediente Digital</h1>
            <div class="d-flex flex-column">
              <p>Papás, mamás; para poder participar en el proceso de Becas correspondiente al Primer Semestre de 2024, es necesario contar con el expediente digital de su hijo o hija</p>
              <a href="https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx" target="_blank" class="btn btn-principal-light">
                Crear Expediente Digital
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col">
        <div class="banner_sm bg_dg-5 mb-3">
          <div class="item1">
            <p class="titulo">Calendario de Pago de Becas</p>
          </div>
          <div class="item2">
            <p class="contenido">Calendario de Pago de Becas</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/Calendario-de-Pago-de-Becas.pdf" target="_blank" class="btn btn-principal-light">
              Ver calendario
            </a>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row" v-if="scholarshipCalls.length > 0">
      <div class="col">
        <h2 class="subtitulo">Convocatorias de Becas</h2>

        <ConvocatoriasComponent :calls="scholarshipCalls" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm" style="background-image: linear-gradient(0deg, rgba(41, 60, 152, 0.5) 23.02%, rgba(41, 60, 152, 0.357) 80.31%), url('https://www.iea.gob.mx/img/bg-banners.png');">
          <div class="item1">
            <p class="titulo">Coordinación de Becas y Financiamiento Educativo</p>
          </div>
          <div class="item2">
            <p class="contenido">Av. Manuel Gomez Morin S/N, Ferronales, 20180 <br> Tel. 449 910 5694</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Alberto Robles Núñez',
          area: 'Coordinación de Becas y Financiamiento Educativo',
          telefono: '449 910 5694',
          fecha: '28/06/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ConvocatoriasComponent from '../../components/ConvocatoriasComponent.vue';
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "PrincipalView",
  components: {
    ConvocatoriasComponent,
    RIComponent
  },
  data() {
    return {
      scholarshipCalls: [
        {
          title: "Beca Académica Formación para el Trabajo, Media Superior y Superior (Segundo Cuatrimestre 2024)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Académica Formación para el Trabajo, Media Superior y Superior.pdf",
              validity: "13/05/2024 - 28/06/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Académica Formación para el Trabajo, Media Superior y Superior.pdf",
              validity: "28/06/2024 - 28/07/2024"
            }
          ],
        },
        // {
        //   title: "Beca IEA‑SNTE Media Superior y Superior (Primer Semestre 2024)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_dinero.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca IEA‑SNTE Media Superior y Superior.pdf",
        //       validity: "29/01/2024 - 31/01/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca IEA‑SNTE Media Superior y Superior.pdf",
        //       validity: "29/04/2024 - 31/05/2024"
        //     }
        //   ],
        // },
        // {
        //   title: "Beca Fortalecimiento a la Permanencia Escolar (Primaria y Secundaria)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_cuidado.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Fortalecimiento a la Permanencia Escolar.pdf",
        //       validity: "19/02/2024 - 19/03/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Fortalecimiento a la Permanencia Escolar.pdf",
        //       validity: "29/04/2024 - 31/05/2024"
        //     }
        //   ],
        // },
        // {
        //   title: "Beca Educación Especial y Barreras para el Aprendizaje (Primaria y Secundaria)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_familiaDos.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Educación Especial y Barreras para el Aprendizaje.pdf",
        //       validity: "19/02/2024 - 19/03/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Educación Especial y Barreras para el Aprendizaje.pdf",
        //       validity: "29/04/2024 - 31/05/2024"
        //     }
        //   ],
        // },
        // {
        //   title: "Beca Tu Futuro es Gigante (Primer Semestre 2024)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_derechos.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Tu Futuro es Gigante.pdf",
        //       validity: "29/01/2024 - 19/02/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Tu Futuro es Gigante.pdf",
        //       validity: "29/04/2024 - 31/05/2024"
        //     }
        //   ],
        // },
        // {
        //   title: "Estímulo a la Excelencia Educativa (Primaria y Secundaria)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_diploma.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Estímulo a la Excelencia Educativa.pdf",
        //       validity: "19/02/2024 - 19/03/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Estímulo a la Excelencia Educativa.pdf",
        //       validity: "29/04/2024 - 31/05/2024"
        //     }
        //   ]
        // }
      ]
    }
  },
  methods: {
    isValidity(validity) {
      let today = new Date();

      let startDate = validity.split("-")[0].trim();

      let startDateParts = startDate.split("/");
      let startDay = parseInt(startDateParts[0], 10);
      let startMonth = parseInt(startDateParts[1], 10) - 1;
      let startYear = parseInt(startDateParts[2], 10);

      let endDate = validity.split("-")[1].trim();

      let endDateParts = endDate.split("/");
      let endDay = parseInt(endDateParts[0], 10);
      let endMonth = parseInt(endDateParts[1], 10) - 1;
      let endYear = parseInt(endDateParts[2], 10);

      startDate = new Date(startYear, startMonth, startDay);
      endDate = new Date(endYear, endMonth, endDay, 23, 59, 59, 0);

      if (today >= startDate && today <= endDate) {
        return true;
      }

      return false;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
