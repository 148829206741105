<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">
          IEA - Sistema de Evaluación del Desempeño (Anexo 13)
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Reportes Trimestrales de Avance de Objetivos y Metas de los Programas
          Presupuestarios 2024
        </h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Reporte</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer Reporte Trimestral de Avance de Objetivos y Metas de los Programas Presupuestarios 2024</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SED/2024/1T_2024.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo Reporte Trimestral de Avance de Objetivos y Metas de los Programas Presupuestarios 2024</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SED/2024/2T_2024.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Reportes Trimestrales de Avance de Objetivos y Metas de los Programas
          Presupuestarios 2023
        </h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Reporte</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer Reporte Trimestral de Avance de Objetivos y Metas de los Programas Presupuestarios 2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SED/2023/1T_2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo Reporte Trimestral de Avance de Objetivos y Metas de los Programas Presupuestarios 2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SED/2023/2T_2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Tercer Reporte Trimestral de Avance de Objetivos y Metas de los Programas Presupuestarios 2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SED/2023/3T_2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Cuarto Reporte Trimestral de Avance de Objetivos y Metas de los Programas Presupuestarios 2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SED/2023/4T_2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Reportes Trimestrales de Avance de Objetivos y Metas de los Programas
          Presupuestarios 2022
        </h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Reporte</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Cuarto Reporte Trimestral de Avance de Objetivos y Metas de los Programas Presupuestarios 2022</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SED/2022/4T_2022.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Zahara Lizzette Ramirez Flores',
          area: 'Departamento De Planeación Estrategica',
          telefono: '449 910 5600 ext. 4099',
          fecha: '15/07/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "ReportesSEDView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
