<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Informes del Programa Anual de Evaluación (PAE)</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Evaluación del Programa Presupuestario</h2>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Periodo 2024</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Programa Anual de Evaluación 2024</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/PAE/2024/PAE_2024.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Periodo 2023</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Tercer trimestre</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/PAE/2023/3T_2023 Evaluacion PAE.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Periodo 2022</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Cuarto trimestre</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/transparencia/PAE/2022/4T_2022 Evaluacion PAE.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'María Antonieta López Medina',
          area: 'Subdirección de Información, Planeación estratégica y Evaluación institucional',
          telefono: '449 910 5600 ext. 4097',
          fecha: '02/05/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "InformesPAEView",
  components: {
    RIComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>