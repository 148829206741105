<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Incorporación</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          La incorporación de estudios al Sistema Educativo Nacional está
          contemplado en dos figuras importantes que son la Autorización y el
          Reconocimiento de Validez Oficial de Estudios.
        </p>
        <p>
          La <strong>Autorización</strong> es la resolución que emite la
          autoridad educativa respecto de Planes y Programas de Estudio de
          Educación Básica (Inicial, Preescolar, Primaria y Secundaria) y
          Formación de Docentes (Normal).
        </p>
        <p>
          El <strong>Reconocimiento de Validez Oficial de Estudios (RVOE)</strong>
          es la resolución respecto de todos aquellos Planes y Programas de
          Estudio que no se encuentran comprendidos en la Educación Básica
          (Inicial, Preescolar, Primaria, Secundaria) y Formación de Docentes
          (Normal), es decir, son los comprendidos por Formación para el
          Trabajo, la Educación Media Superior y la Superior.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm bg_dg-6 mb-3">
          <div class="item1">
            <p class="titulo">Convocatoria de Incorporación de Estudios</p>
          </div>
          <div class="item2">
            <p class="contenido">Ciclo Escolar 2024-2025</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/CVT-Incorporación-de-Estudios.pdf" target="_blank" class="btn btn-principal-light">
              Ver convocatoria
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Trámites de Incorporación</h2>
      </div>
      <div class="col-12">
        <p>
          Los trámites de incorporación para todos los tipos y niveles
          educativos: Inicial, Preescolar, Primaria, Secundaria, Educación Media
          Superior, Superior, Formación para el Trabajo y Profesional Técnico,
          están sujetos a la Convocatoria de Incorporación para el ciclo escolar
          2022-2023.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Procedimiento de Incorporación - Autorización o RVOE 2022-2023.pdf" target="_blank" class="pillLink">
          <div class="pill-card mb-3">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_encuesta.svg" alt="..." class="img-fluid" />
              <div>
                Procedimiento de Incorporación. Autorización o Reconocimiento de
                Validez Oficial de Estudios (RVOE) 2022-2023
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Educación Inicial</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Autorización para impartir Educación Inicial 2022-2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Autorización para impartir Educación Inicial 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Educación Preescolar</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Autorización para impartir Educación Preescolar 2022-2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Autorización para impartir Educación Preescolar 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Educación Primaria</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Autorización para impartir Educación Primaria 2022-2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Autorización para impartir Educación Primaria 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Educación Secundaria</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Autorización para impartir Educación Secundaria 2022-2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Autorización para impartir Educación Secundaria 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Educación Media Superior</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Reconocimiento de Validez Oficial de Estudios (RVOE) Educación Media Superior 2022-2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/RVOE Educación Media Superior 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Educación Superior</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Reconocimiento de Validez Oficial de Estudios (RVOE) Educación Superior 2022-2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/RVOE Educación Superior 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Formación para el Trabajo</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Reconocimiento de Validez Oficial de Estudios (RVOE) Formación para el Trabajo 2022-2023</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/RVOE Formación para el Trabajo 2022-2023.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row justify-content-center mt-5">
      <div class="col-12">
        <h2 class="subtitulo">Escuelas incorporadas</h2>
      </div>
      <div class="col-12 col-md-6">
        <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/Acuerdos de incorporación de estudios vigentes.pdf" target="_blank" class="pillLink">
          <div class="pill-card mb-3">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_registro.svg" alt="..." class="img-fluid" />
              <div>Acuerdos de Incorporación Vigentes</div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col">
        <h2 class="subtitulo">Acuerdos de Incorporación 2023-2024</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Acuerdo</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Acuerdo No. 2958 por el que se otorga Autorización para impartir Educación Primaria a favor de "Colegio Jean Le Boulch, Asociación Civil" de la institución educativa denominada denominada "Colegio Jean Le Boulch, A.C." ubicada en calle Fray Antonio de Segovia No. 109, Fracc. Villa Jardin, 1a. Secc., Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2958.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2959 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio del "Doctorado en Innovación y Transformación Educativa, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "We Are Global University, S.C.", para que se imparta en la institución educativa denominada "Global University", ubicada en Av. Independencia No. 2134, Col. Trojes de Alonso, Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2959.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2960 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Ingeniería en Inteligencia Artificial, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Hybridge Education S.A.P.I. de C.V.", para que se imparta en la institución educativa denominada "Hybridge Education", ubicada en Av. Convención de 1914 Pte. No. 1132-211, Subd. Barranca de Guadalupe, Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2960.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2961 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Licenciatura en Administración e Innovación, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Hybridge Education S.A.P.I. de C.V.", para que se imparta en la institución educativa denominada "Hybridge Education", ubicada en Av. Convención de 1914 Pte. No. 1132-211, Subd. Barranca de Guadalupe, Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2961.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2962 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Ingeniería en Tecnologías Inmersivas y Videojuegos, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Hybridge Education S.A.P.I. de C.V.", para que se imparta en la institución educativa denominada "Hybridge Education", ubicada en Av. Convención de 1914 Pte. No. 1132-211, Subd. Barranca de Guadalupe, Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2962.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2963 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Maestría en Política y Gobierno, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Aurora Bajío Educación y Desarrollo Tecnológico, S.C.", para que se imparta en la institución educativa denominada "Centro Universitario Aurora", ubicada en Av. Aguascalientes Nte. No. 603 Int. 201, Fracc. Bosques del Prado Sur, Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2963.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2964 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Maestría en Relaciones Laborales y Seguridad Social, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Aurora Bajío Educación y Desarrollo Tecnológico, S.C.", para que se imparta en la institución educativa denominada "Centro Universitario Aurora", ubicada en Av. Aguascalientes Nte. No. 603 Int. 201, Fracc. Bosques del Prado Sur, Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2964.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2965 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Maestría en Administración de Hospitales y Servicios de Salud, Modalidad Mixta, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Universidad Cuauhtémoc Plantel Aguascalientes, S.C.", para que se imparta en la institución educativa denominada "Universidad Cuauhtémoc Plantel Aguascalientes", ubicada en Adolfo López Mateos No. 102, Loc. Trojes de Alonso, Jesús María, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2965.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2966, por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de "Bachillerato General Plan Propio, Modalidad Escolarizada, Periodicidad Semestral", turno matutino y vespertino, alumnado mixto, a favor de "Colegio Pascal, A.C.", para que se imparta en la institución educativa denominada "Colegio Pascal".</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2966.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2967, por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de "Bachillerato General Plan Plan Propio, Modalidad Mixta, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto, a favor de "Colegio Pascal, A.C.", para que se imparta en la institución educativa denominada "Colegio Pascal".</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2967.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2968 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Especialidad en Liderazgo y Competitividad, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Formación y Capacitación Corporativa, S.C.", para que se imparta en la institución educativa denominada "Instituto Escadia", ubicada en Av. Aguascalientes Norte No. 1698 Primer Piso, Centro Comercial Agropecuario, Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2968.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2969 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Especialidad en Dirección de Negocios, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Formación y Capacitación Corporativa, S.C.", para que se imparta en la institución educativa denominada "Instituto Escadia", ubicada en Av. Aguascalientes Norte No. 1698 Primer Piso, Centro Comercial Agropecuario, Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2969.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2970 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Licenciatura en Administración y Gestión de la Competitividad, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Formación y Capacitación Corporativa, S.C.", para que se imparta en la institución educativa denominada "Instituto Escadia", ubicada en Av. Aguascalientes Norte No. 1698 Primer Piso, Comercial Agropecuario, Centro Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2970.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2971 por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de la "Especialidad en Gestión del Talento Humano, Modalidad No Escolarizada, Periodicidad Cuatrimestral", turno matutino y vespertino, alumnado mixto a favor de "Formación y y Capacitación Corporativa, S.C.", para que se imparta en la institución educativa denominada "Instituto Escadia", ubicada en Av. Aguascalientes Norte No. 1698 Primer Piso, Centro Comercial Agropecuario, Aguascalientes, Ags.</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2971.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>Acuerdo No. 2972, por el que se otorga Reconocimiento de Validez Oficial de Estudios al Plan y Programas de estudio de "Bachillerato Tecnológico en Cuidado Humano y Bienestar, Modalidad Escolarizada, Periodicidad Semestral", turno matutino y vespertino, alumnado mixto, a favor de "We Are Global University, S.C.", para que se imparta en la institución educativa denominada "Global University".</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/incorporacion/acuerdos/ACUERDO-2972.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Humberto Durán de Anda',
          area: 'Departamento de Incorporación y Revalidación',
          telefono: '449 910 5600 ext. 4230',
          fecha: '05/12/2023'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "IncorporacionView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>