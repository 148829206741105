<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEB) - Educación Especial</h1>
        <p>
          Las principales acciones del Departamento de Educación Especial e
          Inclusión incluyen gestionar y asesorar a supervisores y directores en
          trámites administrativos para docentes, especialistas y personal PAAE,
          con el objetivo de mejorar la pertinencia, calidad y eficiencia del
          servicio educativo. Además, se asesora a los supervisores en temas de
          infraestructura, recursos humanos, materiales, y se atienden quejas y
          solicitudes para asegurar respuestas oportunas. También se verifica,
          con el apoyo de supervisores escolares, la integración y correcto
          funcionamiento del Consejo Técnico Escolar y se coordinan las acciones
          del programa de fortalecimiento de los servicios de educación especial
          e inclusión.
        </p>
        <p>
          El <b>Programa de Fortalecimiento de los Servicios de Educación Especial
          (PFSEE)</b> tiene como objetivo contribuir a que las y los estudiantes de
          educación básica con discapacidad y/o con aptitudes sobresalientes de
          los Servicios de Educación Especial del PFSEE permanezcan en el ciclo
          escolar correspondiente y desarrollen habilidades para potenciar su
          aprendizaje.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Acciones Académicas para Agentes Educativos en Temas de Discapacidad y
          de APTES
        </h2>
        <p class="fw-bold c_base">
          Acciones Académicas para Agentes Educativos en Temas de Discapacidad
        </p>
        <ul>
          <li>
            <b>CURSO:</b> Travesía educativa: desarrollo integral a través de la
            lecto escritura y matemáticas para estudiantes con TEA y TDAH.
          </li>
          <li>
            <b>TALLER:</b> "Desarrollando la lecto escritura y las matemáticas
            para la discapacidad auditiva".
          </li>
          <li>
            <b>TALLER:</b> "Explorando el braille para el desarrollo de la lecto
            escritura y las matemáticas en estudiantes con discapacidad visual".
          </li>
          <li>
            <b>TALLER:</b> "Senderos de aprendizaje fomentando la lecto
            escritura y el desarrollo matemático con adaptaciones para la
            discapacidad intelectual".
          </li>
        </ul>
        <p class="fw-bold c_base">
          Acciones Académicas para Agentes Educativos en Temas de APTES
        </p>
        <ul>
          <li>
            <b>CONFERENCIA MAGISTRAL:</b> "Uniendo caminos: de la exclusión a la
            inclusión".
          </li>
          <li>
            <b>TALLER:</b> "Estrellas en crecimiento: Empoderando a familias con
            niños y niñas talentosos".
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Acciones de Atención Complementaria para Estudiantes con Discapacidad
          y APTES
        </h2>
        <p class="fw-bold c_base">
          Acciones de Atención Complementaria para Estudiantes con Discapacidad
        </p>
        <ul>
          <li>
            <b>FORO:</b> Experiencias existosas de alumnos con discapacidad en
            su trayecto formativo.
          </li>
          <li>
            Equipamiento específico en beneficio de estudiantes con discapacidad.
          </li>
        </ul>
        <p class="fw-bold c_base">
          Acciones de Atención Complementaria para Estudiantes con APTES
        </p>
        <ul>
          <li>
            <b>TALLER:</b> "Adopta un talento para NNA con aptitudes
            sobresalientes en el área intelectual".
          </li>
          <li>
            Equipamiento específico en beneficio de estudiantes con APTES.
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Irma Teresa Díaz Esparza',
          area: 'Departamento de Educación Especial e Inclusión',
          telefono: '449 910 5600 ext. 4075, 4314, 4315 y 4410',
          fecha: '23/08/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "EducacionEspecialView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
