<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Acoso Escolar</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Prevención, Atención y Erradicación de la Violencia Escolar</h2>
        <div class="banner_sm bg_dg-5 mb-3">
          <div class="item1">
            <p class="titulo">Ley para Prevenir, Atender y Erradicar la Violencia Escolar</p>
          </div>
          <div class="item2">
            <p class="contenido">Prevención, Atención y Erradicación de la Violencia Escolar</p>
          </div>
          <div class="item3">
            <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-18-100.pdf" target="_blank" class="btn btn-principal-light">
              Ver más
            </a>
          </div>
        </div>
        <div class="banner_sm bg_dg-7 mb-3">
          <div class="item1">
            <p class="titulo">Protocolo para Prevenir, Atender y Erradicar la Violencia Escolar</p>
          </div>
          <div class="item2">
            <p class="contenido">Prevención, Atención y Erradicación de la Violencia Escolar</p>
          </div>
          <div class="item3">
            <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/2207.pdf#page=2" target="_blank" class="btn btn-principal-light">
              Ver más
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner-body-left bg_dg-3">
          <img src="img/sistema-educativo/acoso-escolar/bg-banner.png" alt="..." class="img-fluid" />
          <div class="baninfo-wrapper">
            <p>Sistema de Prevención, Atención y Erradicación de la Violencia Escolar</p>
            <div class="d-flex align-items-end flex-column">
              <a href="https://acosoescolar.iea.edu.mx/" target="_blank" class="btn btn-principal-light float-end">
                Ir al sitio
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Jorge Humberto Pérez Valenciano',
            area: 'Departamento de Programas Especiales',
            telefono: '449 910 5600  ext. 4134 y 4242',
            fecha: '12/5/2023',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "AcosoEscolarView",
  components: {
    RIComponent
  }
}
</script>

<style>

</style>