<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Objetivos</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Objetivo general</h2>
        <p>
          Diseñar, implementar y evaluar las políticas, programas, servicios y
          acciones orientadas a la educación básica (inicial, preescolar,
          primaria, secundaria), media superior y superior, mediante la
          cooperación con instituciones educativas y sociales; públicas y
          privadas; locales, nacionales e incluso internacionales, así como la
          integración e implementación del Sistema Integral de Formación
          Continua; con la finalidad de mejorar la calidad de la educación, el
          desempeño educativo y el impacto social de la educación.
        </p>
        <p>
          Este objetivo se encuadra dentro de las metas educativas definidas por la UNESCO, en la agenda para la educación 2030, cuyo objetivo de desarrollo sostenible 4 busca: "Garantizar una educación inclusiva y equitativa de calidad y promover oportunidades de aprendizaje permanente para todas y todos".
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'David Alejandro López Rubalcava',
            area: 'Coordinación de Comunicación Social',
            telefono: '449 910 5600 ext. 4372',
            fecha: '5/4/2023'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "ObjetivosView",
  components: {
    RIComponent,
  },
};
</script>

<style></style>
