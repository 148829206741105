<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Servicio Social y Prácticas Profesionales</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          El Instituto de Educación de Aguascalientes, es una de las
          instituciones fundamentales de nuestro Estado, que siempre ha buscado
          la vanguardia con su constante innovación e interés por la sociedad.
          Por ello, es de suma importancia brindar la debida atención, valor y
          formalidad a los proyectos que colaborarán a la formación humana y
          profesional de los prestadores de servicios.
        </p>
        <p>
          A TODOS LOS ALUMNOS de nivel bachillerato y licenciatura a se
          les invita a realizar su servicio social, prácticas profesionales o
          residencias en los siguientes proyectos vigentes en diversas áreas
          del Instituto de Educación de Aguascalientes:
        </p>
        <div class="banner_sm bg_dg-3 mb-3">
          <div class="item1">
            <p class="titulo">Servicio Social Y Prácticas Profesionales</p>
          </div>
          <div class="item2">
            <p class="contenido">Periodo Enero-Junio de 2024</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/CVT-Servicio-Social-y-Prácticas-Profesionales.pdf" target="_blank" class="btn btn-principal-light">
              Consultar proyectos vigentes
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Carolina Marian Santillán Martínez',
          area: 'Capacitación Administrativa, Servicio Social y Prácticas Profesionales',
          telefono: '449 910 5600 ext. 4025',
          fecha: '11/07/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "ServicioSocialView",
  components: {
    RIComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>
