<template>
  <div class="container border rounded shadow p-3">
    <div class="row">
      <div class="col">
        <router-link
          to="/educacion-basica/programas-educativos"
          class="btn btn-ln-bg1"
        >
          <span class="iconify" data-icon="ph:arrow-bend-down-left-bold"></span>
          Regresar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Programa Descubriendo al Mundo con Otros Ojos</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p>
          El programa <b>"Descubriendo al Mundo con otros Ojos"</b> tiene el
          objetivo de apoyar el desarrollo académico y mejorar la calidad de
          vida de niñas y niños de escuelas primarias públicas en todo el país,
          a través de la detección de problemas visuales y su corrección
          oportuna mediante la donación de anteojos.
        </p>
        <div class="banner_sm bg_dg-3 mb-3">
          <div class="item1">
            <p class="titulo">Fundación Clisa</p>
          </div>
          <div class="item2">
            <p class="contenido">
              Sitio Web de la Fundación Clisa
            </p>
          </div>
          <div class="item3 d-flex">
            <a href="https://www.grupoclisa.com.mx/Sites/fundacionclisa" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light me-3">
              Ir al Sitio
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4"></div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Rosario Elizabeth Gutiérrez Carreón',
            area: 'Subdirección de Relación Interinstitucional y Social',
            telefono: '449 910 5600 ext. 4074',
            fecha: '02/07/2024',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "DescubriendoAlMundoConOtrosOjosView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped></style>
