<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DES) - Oferta Educativa</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Tecnologías de la Información y la Comunicación (TIC's)
        </h2>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
      <div class="col d-flex justify-content-center" v-for="universidad of universidades">
        <button class="btn btn-principal-light p-0" @click="showModal(universidad)">
          <div class="card" style="max-width: 18rem; min-height: 22rem;">
            <img :src="universidad.logo" class="card-img-top p-3" alt="..." />
            <p class="fw-bold c_base text-center">{{ universidad.nombre }}</p>
          </div>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Stefany Karina Jiménez Colombo',
          area: 'Departamento de Divulgación y Educación Científica y Tecnológica',
          telefono: '449 149 2400 ext. 7805',
          fecha: '04/07/2024',
        }" />
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="universidadModal" tabindex="-1" aria-hidden="true" v-if="universidadModal">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="titulo">{{ universidadModal.nombre }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-lg-4 text-center">
                <div class="d-flex flex-column align-items-center">
                  <img :src="universidadModal.logo" alt="..." class="img-fluid rounded shadow" width="400" height="400" style="max-height: 300px;" />
                  <a :href="universidadModal.paginaWeb" target="_blank" rel="noopener noreferrer" class="pillLink">
                    <div class="pill-card">
                      <div class="d-inline-flex align-items-center">
                        <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_web.svg" alt="..." class="img-fluid" />
                        <div>Página web de la universidad</div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-12 col-lg-8">
                <div class="container-fluid">
                  <div class="row justify-content-end">
                    <div class="col-12">
                      <h1 class="titulo">{{ universidadModal.nombre }} ({{ universidadModal.siglas }})</h1>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-center">
                        <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_virrete.svg" alt="..." />
                        <h2 class="subtitulo">Oferta Educativa</h2>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="container">
                        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                          <div class="col" v-for="carrera in universidadModal.carreras">
                            <div class="pillLink">
                              <div class="pill-card d-flex align-items-center" style="min-height: 6.5rem;">
                                {{ carrera }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="container rounded shadow p-3 mt-3">
                        <div class="row row-cols-1 row-cols-lg-2 g-3">
                          <div class="col">
                            <div class="d-flex align-items-center">
                              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_rutas.svg" alt="..." />
                              <h2 class="subtitulo">Ubicación</h2>
                            </div>
                            <p class="fw-bold c_base">{{ universidadModal.direccion }}</p>
                            <div class="d-flex align-items-center justify-content-center fw-bold c_base">
                              <span class="iconify" data-icon="akar-icons:phone" width="35" height="35"></span>
                              {{ universidadModal.telefono }}
                            </div>
                          </div>
                          <div class="col">
                            <iframe :src="universidadModal.ubicacion" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="rounded"></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-principal" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "OfertaEducativaView",
  components: {
    RIComponent
  },
  data() {
    return {
      universidades: [
        {
          siglas: "CUOP",
          nombre: "CENTRO UNIVERSITARIO OCTAVIO PAZ",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN SOFTWARE"
          ],
          facebook: "https://www.facebook.com/profile.php?id=100075698664423",
          direccion: "Av. Francisco I. Madero 441, Zona Centro, 20000 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.342631539614!2d-102.2890641!3d21.882877999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee6ff8a37b01%3A0x9a4b51eb65ee0c0d!2sCentro%20Universitario%20Octavio%20Paz!5e0!3m2!1ses!2smx!4v1675884849328!5m2!1ses!2smx",
          telefono: "449 916 2824",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/CUOP.png",
          paginaWeb: "https://cuop.edu.mx"
        },
        {
          siglas: "GU",
          nombre: "GLOBAL UNIVERSITY",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN BIG DATA E INNOVACIÓN TECNOLÓGICA",
            "ING. SEGURIDAD INFORMÁTICA Y DESARROLLO DE SOFTWARE"
          ],
          facebook: "https://www.facebook.com/WeAreGlobalUniversity/",
          direccion: "Av. Independencia 2134, Trojes de Alonso, 20116 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14804.146480550651!2d-102.298426!3d21.9331436!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef89526633dd%3A0x5357250987248fdb!2sGlobal%20University!5e0!3m2!1ses!2smx!4v1675973824768!5m2!1ses!2smx",
          telefono: "449 500 0520",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/GU.png",
          paginaWeb: "https://globaluniversity.edu.mx"
        },
        {
          siglas: "HE",
          nombre: "HYBRIDGE EDUCATION",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN SOFTWARE"
          ],
          facebook: "https://www.facebook.com/hybridge.education/",
          direccion: "Av. Convención de 1914 Pte. 1132, Barrancade Guadalupe, 20210 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462.83254932284575!2d-102.31132452404127!3d21.870631362805124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee82c7bbe7e7%3A0x5b8f1bb82f05f4a3!2sEdificio%20Lafayette!5e0!3m2!1ses-419!2smx!4v1718037022473!5m2!1ses-419!2smx",
          telefono: "55 9225 6413",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/hybridge-education.png",
          paginaWeb: "https://hybridge.education"
        },
        {
          siglas: "ITA",
          nombre: "INSTITUTO TECNOLÓGICO DE AGUASCALIENTES",
          tipo: "PUBLICA",
          carreras: [
            "ING. EN TECNOLOGÍAS DE LA INFORMACIÓN Y COMUNICACIÓN"
          ],
          facebook: "https://www.facebook.com/TecnmCampusAgs/",
          direccion: "Av. Adolfo López Mateos Ote. 1801, Bona Gens, 20256 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14809.854080846577!2d-102.2629172!3d21.8782197!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee069d6cf307%3A0xd93b5294c07171a7!2sInstituto%20Tecnol%C3%B3gico%20de%20Aguascalientes!5e0!3m2!1ses!2smx!4v1676309667028!5m2!1ses!2smx",
          telefono: "449 910 5002",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/ITA.png",
          paginaWeb: "https://www.aguascalientes.tecnm.mx"
        },
        {
          siglas: "TECNOLÓGICO DE MONTERREY",
          nombre: "INSTITUTO TECNOLÓGICO Y DE ESTUDIOS SUPERIORES DE MONTERREY, CAMPUS AGUASCALIENTES",
          tipo: "PRIVADA",
          carreras: [
            "ING. INDUSTRIAL Y DE SISTEMAS"
          ],
          facebook: "https://www.facebook.com/TECcampusAGS/",
          direccion: "Los Pocitos, Av. Eugenio Garza Sada 1500, 20328 Barranquillas, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.024733720701!2d-102.34307872471804!3d21.933600579951808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef342db03f9b%3A0x5ab1a30d3bd0da3!2sTecnol%C3%B3gico%20de%20Monterrey%20en%20Aguascalientes!5e0!3m2!1ses-419!2smx!4v1718035328535!5m2!1ses-419!2smx",
          telefono: "81 8358 2000",
          logo: "...",
          paginaWeb: "https://tec.mx/es/aguascalientes"
        },
        {
          siglas: "ITPA",
          nombre: "INSTITUTO TECNOLÓGICO DE PABELLÓN DE ARTEAGA",
          tipo: "PUBLICA",
          carreras: [
            "ING. EN TECNOLOGÍAS DE LA INFORMACIÓN Y COMUNICACIÓN"
          ],
          facebook: "https://www.facebook.com/TecNMPabellonDeArteaga/",
          direccion: "Carretera a la estación de Rincón de Romos, Km. 1, 20670 Pabellón de Arteaga, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14780.284762146895!2d-102.268966!3d22.161362!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8682081b0bed6747%3A0x34c1762f6b2e30a1!2sInstituto%20Tecnol%C3%B3gico%20de%20Pabell%C3%B3n%20de%20Arteaga!5e0!3m2!1ses!2smx!4v1676309778358!5m2!1ses!2smx",
          telefono: "465 958 2730",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/ITPA.png",
          paginaWeb: "http://pabellon.tecnm.mx"
        },
        {
          siglas: "ITEL",
          nombre: "INSTITUTO TECNOLÓGICO EL LLANO",
          tipo: "PUBLICA",
          carreras: [
            "ING. EN TECNOLOGÍAS DE LA INFORMACIÓN Y COMUNICACIONES"
          ],
          facebook: "https://www.facebook.com/TecNMLlanoAgs/",
          direccion: "Carretera México 70, Km 18, 20330 El Llano, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14815.965100602567!2d-102.100147!3d21.819268!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429f5141e9ebd79%3A0x9f93db09c44b6da5!2sInstituto%20Tecnol%C3%B3gico%20El%20Llano%20Aguascalientes!5e0!3m2!1ses!2smx!4v1676309824844!5m2!1ses!2smx",
          telefono: "449 962 1100",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/ITEL.png",
          paginaWeb: "https://www.economia.gob.mx/datamexico/es/profile/institution/instituto-tecnologico-el-llano-aguascalientes"
        },
        {
          siglas: "TUA",
          nombre: "TECNOLÓGICO UNIVERSITARIO AGUASCALIENTES",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN SISTEMAS COMPUTACIONALES"
          ],
          facebook: "https://www.facebook.com/TUAags/",
          direccion: "Av. Siglo XXI LB, Municipio Libre, 20196 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14807.908471366018!2d-102.2530131!3d21.8969569!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429f1e076d85403%3A0x1d3541d02d865f3d!2sTecnologico%20Universitario%20Aguascalientes!5e0!3m2!1ses!2smx!4v1675973995016!5m2!1ses!2smx",
          telefono: "449 688 9897",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/TUA.png",
          paginaWeb: "https://www.tua.edu.mx"
        },
        {
          siglas: "UNADE",
          nombre: "UNIVERSIDAD DE ALTA TECNOLOGÍA Y EMPRENDIMIENTO",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN CIENCIA DE DATOS E INTELIGENCIA ARTIFICIAL"
          ],
          facebook: "https://www.facebook.com/TUAags/",
          direccion: "Av. Adolfo López Mateos 421, Zona Centro, 20000 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.480880102127!2d-102.30281522471964!3d21.877550679992844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef77ac476107%3A0x4695e3d28e5c42ca!2sUNADE!5e0!3m2!1ses-419!2smx!4v1718035657007!5m2!1ses-419!2smx",
          telefono: "449 678 8271",
          logo: "...",
          paginaWeb: "https://aula.unade.com.mx"
        },
        {
          siglas: "UNITSA",
          nombre: "UNIVERSIDAD UNITSA",
          tipo: "PRIVADA",
          carreras: [
            "MAESTRÍA EN TECNOLOGÍA EDUCATIVA"
          ],
          facebook: "https://www.facebook.com/UniversidadUnitsa",
          direccion: "Av. Aguascalientes Nte 1501, Parras, 20157 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14806.071716120914!2d-102.2786041!3d21.9146318!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef68d08d62d1%3A0x46fd05e347ff3c93!2sUNIVERSIDAD%20UNITSA!5e0!3m2!1ses!2smx!4v1675974154611!5m2!1ses!2smx",
          telefono: "449 903 9406",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/UNITSA.png",
          paginaWeb: "https://www.unitsa.edu.mx"
        },
        {
          siglas: "UA",
          nombre: "UNIVERSIDAD AURORA",
          tipo: "PRIVADA",
          carreras: [
            "LIC. EN COMERCIO INTERNACIONAL E INTELIGENCIA DE NEGOCIOS",
            "LIC. EN CONTABILIDAD Y FINANZAS",
            "ING. EN CIENCIA DE DATOS",
            "MAESTRÍA EN NEURO EDUCACIÓN"
          ],
          facebook: "https://www.facebook.com/Universidad-Aurora",
          direccion: "Av. Aguascalientes Nte. 603, Interior 201, Bosques del Prado Sur, 20130 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.4892674494877!2d-102.31176502471854!3d21.915734579964912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429eefd78a15555%3A0x766896f22ca4d22f!2sUniversidad%20Aurora!5e0!3m2!1ses-419!2smx!4v1719944660665!5m2!1ses-419!2smx",
          telefono: "449 112 9713",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/universidad-aurora.png",
          paginaWeb: "https://universidadaurora.com"
        },
        {
          siglas: "UVAS",
          nombre: "UNIVERSIDAD VILLASUNCIÓN AGUASCALIENTES",
          tipo: "PRIVADA",
          carreras: [],
          facebook: "https://www.facebook.com/Universidad.Villasuncion/",
          direccion: "Av. Las Americas 601, Santa Elena, 20230 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.6536024723905!2d-102.3022103!3d21.870893199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef038036494b%3A0x60ab59c64385fa6c!2sUniversidad%20Villasunci%C3%B3n%20Aguascalientes!5e0!3m2!1ses!2smx!4v1676309145846!5m2!1ses!2smx",
          telefono: "449 918 3092",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/UVAS.png",
          paginaWeb: "https://www.uvas.edu.mx"
        },
        {
          siglas: "UAA",
          nombre: "UNIVERSIDAD AUTÓNOMA DE AGUASCALIENTES",
          tipo: "PUBLICA",
          carreras: [
            "ING. EN COMPUTACIÓN INTELIGENTE",
            "ING. EN SISTEMAS COMPUTACIONALES",
            "LIC. EN INFORMÁTICA Y TECNOLOGÍAS COMPUTACIONALES"
          ],
          facebook: "https://www.facebook.com/uaa.mx/",
          direccion: "Avenida Universidad 940, Ciudad Universitaria, Universidad Autónoma de Aguascalientes, 20100 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7403.0637498571805!2d-102.321155!3d21.9140952!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef1da1ab338d%3A0x89a0246637c42ddb!2sUniversidad%20Aut%C3%B3noma%20de%20Aguascalientes!5e0!3m2!1ses!2smx!4v1676309880472!5m2!1ses!2smx",
          telefono: "449 910 7400",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/UAA.png",
          paginaWeb: "https://www.uaa.mx"
        },
        {
          siglas: "UC",
          nombre: "UNIVERSIDAD CUAUHTÉMOC AGUASCALIENTES",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN CIBERSEGURIDAD",
            "ING. EN CIENCIA DE DATOS E INTELIGENCIA DE NEGOCIOS",
            "ING. EN TECNOLOGÍAS DE LA COMPUTACIÓN Y DESARROLLO DE SOFTWARE",
            "LIC. EN INGENIERÍA PARA EL DISEÑO Y DESARROLLO DE SOFTWARE"
          ],
          facebook: "https://www.facebook.com/UNIVERSIDADCUAUHTEMOCAGS",
          direccion: "Calle Adolfo López Mateos No. 102, El Llano, 20908 Jesús María, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14803.440793901098!2d-102.3009342!3d21.9399253!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efa7278f9fa9%3A0x4cebf85bd7e9c828!2sUniversidad%20Cuauht%C3%A9moc%2C%20Plantel%20Aguascalientes%2C%20Jes%C3%BAs%20Mar%C3%ADa!5e0!3m2!1ses!2smx!4v1675974031937!5m2!1ses!2smx",
          telefono: "449 973 1122",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/UC.png",
          paginaWeb: "https://www.ucuauhtemoc.edu.mx"
        },
        {
          siglas: "USF",
          nombre: "UNIVERSIDAD DE SANTA FE",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN SISTEMAS COMPUTACIONALES Y DE INFORMACIÓN"
          ],
          facebook: "https://www.facebook.com/udesantafe/",
          direccion: "Paseo del Paraíso s/n, Desarrollo Ecológico Paraíso, 20313 Hacienda Nueva, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14807.775294799132!2d-102.3543878!3d21.8982389!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429e9d02a6916f7%3A0xc3f76642397046d7!2sUniversidad%20de%20Santa%20Fe!5e0!3m2!1ses!2smx!4v1675974697895!5m2!1ses!2smx",
          telefono: "449 910 6850",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/USF.png",
          paginaWeb: "https://usantafe.edu.mx"
        },
        {
          siglas: "UNIDEP",
          nombre: "UNIVERSIDAD DEL DESARROLLO PROFESIONAL",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN SISTEMAS COMPUTACIONALES"
          ],
          facebook: "https://www.facebook.com/UnidepOficial/",
          direccion: "Av. José María Chávez 1123, Obraje, 20280 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.7590100807615!2d-102.29738592471995!3d21.866829380000677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429edd73748623b%3A0x1e7d5614ecc0d477!2sUNIDEP!5e0!3m2!1ses-419!2smx!4v1718036679657!5m2!1ses-419!2smx",
          telefono: "449 976 2502",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/UNIDEP.png",
          paginaWeb: "https://unidep.mx"
        },
        {
          siglas: "UVM",
          nombre: "UNIVERSIDAD DEL VALLE DE MÉXICO",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN SISTEMAS COMPUTACIONALES",
            "ING. INDUSTRIAL Y DE SISTEMAS"
          ],
          facebook: "https://www.facebook.com/UVMCampusAguascalientes/",
          direccion: "Blvrd Juan Pablo II 1144, Loma Bonita, 20200 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14812.064397947854!2d-102.3425909!3d21.8569147!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ec0382cb4ead%3A0x29551688d3db72fc!2sUVM%20Aguascalientes%20-%20Universidad%20del%20Valle%20de%20M%C3%A9xico!5e0!3m2!1ses!2smx!4v1676309236379!5m2!1ses!2smx",
          telefono: "800 000 0886",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/UVM.png",
          paginaWeb: "https://www.uvm.mx"
        },
        {
          siglas: "UNID",
          nombre: "UNID CAMPUS AGUASCALIENTES",
          tipo: "PRIVADA",
          carreras: [
            "ING. DE SOFTWARE Y SISTEMAS COMPUTACIONALES"
          ],
          facebook: "https://www.facebook.com/UNIDCampusAguascalientes/",
          direccion: "Av. Convención de 1914 Pte. 1204, Panorama, 20040 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14808.9538990935!2d-102.3129018!3d21.8868908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ee921d8fe543%3A0x415081ad8bdcd311!2sUNID%20Sede%20Aguascalientes!5e0!3m2!1ses!2smx!4v1675974123362!5m2!1ses!2smx",
          telefono: "449 994 1108",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/UNID.png",
          paginaWeb: "https://www.unid.edu.mx"
        },
        {
          siglas: "UP",
          nombre: "UNIVERSIDAD PANAMERICANA",
          tipo: "PRIVADA",
          carreras: [
            "ING. EN INTELIGENCIA ARTIFICIAL"
          ],
          facebook: "https://www.facebook.com/UPinstitucional",
          direccion: "Av. Josemaría Escrivá de Balaguer 101, Villa Bonaterra, 20296 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3703.8639711977125!2d-102.28653562472117!3d21.824186080031705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429eda750afa225%3A0x101e5b693f0a1bc0!2sUniversidad%20Panamericana!5e0!3m2!1ses-419!2smx!4v1718036855561!5m2!1ses-419!2smx",
          telefono: "449 910 6200",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/privadas/UP.png",
          paginaWeb: "https://www.up.edu.mx/campus-aguascalientes"
        },
        {
          siglas: "UPA",
          nombre: "UNIVERSIDAD POLITÉCNICA DE AGUASCALIENTES",
          tipo: "PUBLICA",
          carreras: [
            "ING. EN SISTEMAS COMPUTACIONALES",
            "ING. EN SISTEMAS ESTRATÉGICOS DE INFORMACIÓN ESPECIALIZACIÓN EN REDES"
          ],
          facebook: "https://www.facebook.com/UniversidadPolitecnicaAguascalientes/",
          direccion: "Calle Av. Paseo San Gerardo No. 207, Fracc. San Gerardo, 20342 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14817.41438776336!2d-102.2954797!3d21.8052648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ed1c1bd260a9%3A0xbeafebb7ea976241!2sUniversidad%20Polit%C3%A9cnica%20de%20Aguascalientes!5e0!3m2!1ses!2smx!4v1676310062820!5m2!1ses!2smx",
          telefono: "449 442 1400",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/UPA.png",
          paginaWeb: "https://upa.edu.mx"
        },
        {
          siglas: "UTA",
          nombre: "UNIVERSIDAD TECNOLÓGICA DE AGUASCALIENTES",
          tipo: "PUBLICA",
          carreras: [
            "ING. EN DESARROLLO Y GESTIÓN DE SOFTWARE",
            "ING. REDES INTELIGENTES Y CIBERSEGURIDAD",
            "TSU. EN TECNOLOGÍAS DE LA INFORMACIÓN ÁREA DESARROLLO DE SOFTWARE MULTIPLATAFORMA",
            "TSU. EN TECNOLOGÍAS DE LA INFORMACIÓN ÁREA INFRAESTRUCTURA DE REDES DIGITALES"
          ],
          facebook: "https://www.facebook.com/UniversidadTecnologicaDeAguascalientes",
          direccion: "Blvd. Juan Pablo II No. 1302 Ex hacienda la Cantera, 20200 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14813.896043204808!2d-102.3538124!3d21.8392447!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429eb8c66ba4c57%3A0x800a85fa04315af2!2sUniversidad%20Tecnol%C3%B3gica%20de%20Aguascalientes!5e0!3m2!1ses!2smx!4v1676310116448!5m2!1ses!2smx",
          telefono: "449 910 5000",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/UTA.png",
          paginaWeb: "https://www.utags.edu.mx"
        },
        {
          siglas: "UTC",
          nombre: "UNIVERSIDAD TECNOLÓGICA DE CALVILLO",
          tipo: "PUBLICA",
          carreras: [
            "TSU. EN TECNOLOGÍAS DE LA INFORMACIÓN ÁREA SOFTWARE MULTIPLATAFORMA"
          ],
          facebook: "https://www.facebook.com/UTCalvillo/?locale=es_LA",
          direccion: "Carretera al Tepetate 102 Colonia El Salitre, 20860 Calvillo, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14816.415551596343!2d-102.7695942!3d21.8149166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429cb7241f16d49%3A0x654def15e1ccd643!2sUniversidad%20Tecnol%C3%B3gica%20de%20Calvillo!5e0!3m2!1ses!2smx!4v1676310154427!5m2!1ses!2smx",
          telefono: "495 956 5063",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/UTC.PNG",
          paginaWeb: "https://www.utcalvillo.edu.mx"
        },
        {
          siglas: "UTNA",
          nombre: "UNIVERSIDAD TECNOLÓGICA DEL NORTE DE AGUASCALIENTES",
          tipo: "PUBLICA",
          carreras: [
            "ING. EN DESARROLLO Y GESTIÓN DE SOFTWARE",
            "TSU. EN TECNOLOGÍAS DE LA INFORMACIÓN ÁREA DESARROLLO DE SOFTWARE MULTIPLATAFORMA",
            "TSU. EN TECNOLOGÍAS DE LA INFORMACIÓN ÁREA ENTORNOS VIRTUALES Y NEGOCIOS DIGITALES"
          ],
          facebook: "https://www.facebook.com/utnaoficial/",
          direccion: "La Estación Rincón, Avenida Universidad 1001, El Potrero, 20400 Rincón de Romos, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14773.635629719822!2d-102.281417!3d22.224559!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868208c1ba388a0b%3A0x513aa559bea0f7f9!2sUniversidad%20Tecnol%C3%B3gica%20del%20Norte%20de%20Aguascalientes%20(UTNA)!5e0!3m2!1ses!2smx!4v1676310212297!5m2!1ses!2smx",
          telefono: "465 965 0030",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/UTNA.PNG",
          paginaWeb: "https://www.utna.edu.mx"
        },
        {
          siglas: "UTR",
          nombre: "UNIVERSIDAD TECNOLÓGICA EL RETOÑO",
          tipo: "PUBLICA",
          carreras: [
            "ING. EN DESARROLLO Y GESTIÓN DE SOFTWARE",
            "TSU. EN CIENCIA DE DATOS ÁREA INTELIGENCIA ARTIFICIAL",
            "TSU. EN TECNOLOGÍAS DE LA INFORMACIÓN ÁREA DESARROLLO DE SOFTWARE MULTIPLATAFORMA"
          ],
          facebook: "https://www.facebook.com/UTRBIS/",
          direccion: "Carretera, San Luis Potosi - Aguascalientes S/n, Comunidad, 20337 El Retoño, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14813.92212386557!2d-102.159!3d21.838993!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429f47ec9d93fb9%3A0x38cd71b81ada3c58!2sUniversidad%20Tecnol%C3%B3gica%20El%20Reto%C3%B1o!5e0!3m2!1ses!2smx!4v1676310240270!5m2!1ses!2smx",
          telefono: "449 500 0410",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/UTR.png",
          paginaWeb: "https://utr.edu.mx"
        },
        {
          siglas: "UTMA",
          nombre: "UNIVERSIDAD TECNOLÓGICA METROPOLITANA DE AGUASCALIENTES",
          tipo: "PUBLICA",
          carreras: [
            "ING. EN DESARROLLO Y GESTIÓN DE SOFTWARE",
            "TSU. EN TECNOLOGÍAS DE LA INFORMACIÓN ÁREA DESARROLLO DE SOFTWARE MULTIPLATAFORMA",
            "TSU. EN TECNOLOGÍAS DE LA INFORMACIÓN ÁREA INTELIGENCIA ARTIFICIAL"
          ],
          facebook: "https://www.facebook.com/UTMABiS/",
          direccion: "Av. Gerónimo de la Cueva s/n, Villas del Río, 20126 Aguascalientes, Ags.",
          ubicacion: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14803.219410496957!2d-102.247765!3d21.9420524!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429f1701dc31605%3A0xbb03374e660a22c6!2sUniversidad%20Tecnol%C3%B3gica%20Metropolitana%20de%20Aguascalientes!5e0!3m2!1ses!2smx!4v1676310182778!5m2!1ses!2smx",
          telefono: "449 925 3920",
          logo: "https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/oferta-educativa/universidades/publicas/UTMA.png",
          paginaWeb: "https://utma.edu.mx"
        }
      ],
      universidadModal: null
    }
  },
  methods: {
    showModal(universidad) {
      this.universidadModal = universidad;

      gea.iwin(() => {
        const universidadModal = bootstrap.Modal.getOrCreateInstance("#universidadModal");
        universidadModal.show();
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>