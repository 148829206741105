<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_id2.svg" alt="...">
          <h1 class="titulo">IEA (DES) - Expedición de Cédulas Profesionales Estatales</h1>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          Estimado solicitante, si deseas tramitar tu Cédula Profesional Estatal y/o
          de Pasante, los <b>pasos a seguir</b> son los siguientes:
        </p>
        <ol type="A" class="fw-bold c_base">
          <li>
            Acudir a las oficinas de espacio común (Cerro de la Joya No. 304
            Ojocaliente II, 20256 Aguascalientes, Ags.) en área de profesiones con la
            documentación requerida, según corresponda (Consultar abajo).
          </li>
          <li>
            Entregar la documentación requerida en ventanilla. Una vez cotejado se
            entregará comprobante para realizar pago de derechos en el área de cajas
            del IEA (Av. Tecnológico 601, Ojocaliente, 20196 Aguascalientes, Ags.).
          </li>
          <li>
            En ventanilla de profesiones se le tomará foto y firma para proceso de
            impresión de cédula plastificada.
          </li>
          <li>
            Regresar por su cédula. Pueden llamar con anticipación para verificar si
            ya está en físico la cédula profesional estatal y/o de pasante a los
            teléfonos de atención 449 910 5600 ext. 7823 y 7810.
          </li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Documentación requerida</h2>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 g-3">
      <div class="col">
        <div class="blue-border-card">
          <div class="cabecera d-inline-flex align-items-center mb-3">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_sello.svg" alt="..." class="img-fluid" />
            <h2 class="subtitulo-sm">Cédulas tipo A. Para personas que acrediten haber concluido estudios de Educación Técnica, Técnica Superior, Licenciaturas y Posgrado.</h2>
          </div>
          <div class="contenido">
            <h6 class="fw-bold">Documentación requerida:</h6>
            <ul>
              <li>Título digital en formato XML.</li>
              <li>Original y copia de INE.</li>
              <li>Recibo de pago de derechos (Realizado en cajas del IEA costo según Ley de Ingresos Vigente).</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="blue-border-card">
          <div class="cabecera d-inline-flex align-items-center mb-3">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_sello.svg" alt="..." class="img-fluid" />
            <h2 class="subtitulo-sm">Cédulas tipo B. Para profesionistas que cuenten con Cédula Profesional Federal y se encuentren registrados en la base nacional de la Dirección General de Profesiones de la SEP.</h2>
          </div>
          <div class="contenido">
            <h6 class="fw-bold">Documentación requerida:</h6>
            <ul>
              <li>Estar en el Registro Profesional Federal.</li>
              <li>Original y copia de INE.</li>
              <li>Recibo de pago de derechos (Realizado en cajas del IEA costo según Ley de Ingresos Vigente).</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="blue-border-card">
          <div class="cabecera d-inline-flex align-items-center mb-3">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_sello.svg" alt="..." class="img-fluid" />
            <h2 class="subtitulo-sm">Cédulas tipo C. En caso de que solo cuenten con titulo físico.</h2>
          </div>
          <div class="contenido">
            <h6 class="fw-bold">Documentación requerida:</h6>
            <ul>
              <li>Original y copia de título digital en formato PDF.</li>
              <li>Original y copia de INE.</li>
              <li>Original y copia de CURP.</li>
              <li>Original y copia de acta de nacimiento.</li>
              <li>Carta de protesta. Obtenla <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-superior/DOC-CARTA-PROTESTA.pdf" target="_blank">aquí</a>.</li>
              <li>Recibo de pago de derechos (Realizado en cajas del IEA costo según Ley de Ingresos Vigente).</li>
            </ul>
            <h6 class="fw-bold">Procesos adicionales:</h6>
            <ul>
              <li>Subir todos los documentos en el siguiente formulario: <a href="https://forms.gle/RsMt5CLM1bQ2NSrL8" target="_blank">Ir al formulario</a>.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="blue-border-card">
          <div class="cabecera d-inline-flex align-items-center mb-3">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_sello.svg" alt="..." class="img-fluid" />
            <h2 class="subtitulo-sm">Cédula de pasante (Artículo 21). Podrán ejercer en el Estado por un período de hasta tres años previa autorización.</h2>
          </div>
          <div class="contenido">
            <h6 class="fw-bold">Documentación requerida:</h6>
            <ul>
              <li>En caso de estar estudiando y haber concluido un 80% de la profesión, traer original y copia de Constancia de Estudios o término no mayor a un año.</li>
              <li>Original y copia de INE.</li>
              <li>Recibo de pago de derechos (Realizado en cajas del IEA costo según Ley de Ingresos Vigente).</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Sandra America Macias Campos',
            area: 'Departamento de Planeacion y Supervisión',
            telefono: '449 910 5600 ext. 7834',
            fecha: '05/04/2024'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "CedulasProfesionalesEstatalesView",
  components: {
    RIComponent
  }
};
</script>

<style>

</style>