<template>
  <!-- Header de la dependencia -->
  <header>
    <HeaderIEAComponent />
  </header>

  <!-- Navbar de la dependencia -->
  <nav>
    <NavbarIEAComponent />
  </nav>

  <!-- Contenido principal de la dependencia -->
  <main id="main">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <!-- Contenido principal -->
          <section>
            <router-view v-slot="{ Component }">
              <transition>
                <keep-alive>
                  <component :is="Component" />
                </keep-alive>
              </transition>
            </router-view>
          </section>
        </div>
        <div class="col-md-4">
          <!-- Sidebar de la dependencia -->
          <aside>
            <SidebarIEAComponent />
          </aside>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import HeaderIEAComponent from "@/pages/root/components/HeaderIEAComponent.vue"
import NavbarIEAComponent from "@/pages/root/components/NavbarIEAComponent.vue"
import SidebarIEAComponent from "@/pages/root/components/SidebarIEAComponent.vue"

export default {
  name: "RootModuleComponent",
  components: {
    HeaderIEAComponent,
    NavbarIEAComponent,
    SidebarIEAComponent,
  }
};
</script>

<style>

</style>
