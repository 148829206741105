<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mini-1">
          <h1>Estrategia Vive Seguro en Internet</h1>
          <img src="https://www.iea.gob.mx/img/servicios-digitales/i_support.svg" />
          <h2>Estrategia de Ciberseguridad</h2>
        </div>
        <p>
          Dentro del marco de la Agenda Digital del Plan Estatal de Desarrollo del
          Gobierno del Estado de Aguascalientes 2022 - 2027, en materia de
          Educación, en el ámbito de la Ciberseguridad surge la
          <b>Estrategia Vive Seguro en Internet</b> como una campaña informativa
          para promover un uso seguro y responsable de Internet. Esta estrategia se
          enfoca en proporcionar herramientas y conocimientos clave a maestros,
          niños y padres de familia, con el objetivo de fortalecer su capacidad para
          navegar por el entorno digital de manera segura y ética, así como generar
          consciencia sobre la importancia de los datos que se hacen públicos.
        </p>
      </div>
    </div>

    <div class="row g-3">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_proteccion.svg" alt="..." />
          <h3 class="titulo">Recursos de Seguridad Digital</h3>
        </div>
      </div>
      <div class="col-12">
        <h4 class="subtitulo">Plan de estudio de Sé genial en Internet</h4>
      </div>
      <div class="col-12 col-lg-8">
        <p>
          El plan de estudio de Sé genial en Internet es una
          <b>Estrategia Google para la Educación</b> que les brinda a los maestros
          de Aguascalientes las herramientas y métodos que necesitan para enseñar
          los conceptos básicos de la seguridad digital. Gracias a los materiales
          que Google desarrolló en asociación con iKeepSafe, ahora es posible llevar
          al aula los conocimientos esenciales y la diversión de Interland.
        </p>
      </div>
      <div class="col-12 col-lg-4">
        <a href="https://storage.googleapis.com/gweb-interland.appspot.com/es-419-all/hub/pdfs/Google_SeGenialEnInternet_ElPlanDeEstudios2019.pdf" target="_blank" class="tarjeta bg_dg-3" rel="noopener noreferrer">
          <p class="inferior">Descargar el Plan de Estudio</p>
        </a>
      </div>
      <div class="col-12">
        <div class="banner_sm bg_dg-7">
          <div class="item1">
            <p class="titulo">Sugerencias para Ser Genial en Internet</p>
          </div>
          <div class="item2">
            <p class="contenido">5 sugerencias a, para un uso seguro y responsable de internet.</p>
          </div>
          <div class="item3">
            <a href="https://storage.googleapis.com/gweb-interland.appspot.com/en-us/hub/pdfs/Google_BeInternetAwesome_DigitalCitiizenshipSafety_Tips_ES.pdf" target="_blank" class="btn btn-principal-light">
              Descargar Sugerencias
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <h4 class="subtitulo">Interland: Un juego para aprender a ser genial en Internet</h4>
      </div>
      <div class="col-12 col-lg-8">
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/KJj2U7XuHdo?si=43g1-2f4Nk-3hPPA" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <p>
          Interland es un juego de aventuras en línea para aprender sobre seguridad
          y ciudadanía digital, tan interactivo y divertido como lo es navegar en
          Internet. Los niños podrán desarrollar habilidades para convertirse en
          buenos ciudadanos y, de esta forma, ayudar a otros internautas a combatir
          el mal comportamiento de hackers, suplantadores de identidad, hostigadores
          y usuarios que comparten demasiado contenido.
        </p>
        <a href="https://beinternetawesome.withgoogle.com/es-419_all/interland" target="_blank" class="tarjeta bg_dg-8" rel="noopener noreferrer">
          <p class="inferior">¡Jugar Ahora!</p>
        </a>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <div class="alert alert-primary d-flex align-items-center" role="alert">
          <span class="iconify" data-icon="ep:info-filled" width="75" height="75"></span>
          <div>
            Este contenido educativo sobre seguridad en Internet y ciudadanía digital
            ha sido obtenido del sitio web "Sé Genial en Internet", mantenido por
            Google para la Educación. Para obtener más información, visita:
            <a href="https://beinternetawesome.withgoogle.com/es-419_all/educadores" target="_blank" rel="noopener noreferrer">Sé Genial en Internet</a>.
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'José Roberto Pereda Delgado',
          area: 'Subdirección de Tecnologías de la Información',
          telefono: '449 910 5600 ext. 4915',
          fecha: '12/04/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "ViveSeguroEnInternetView",
  components: {
    RIComponent
  }
};
</script>

<style lang="scss" scoped>

</style>
