<template>
  <div class="navDependencia">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="routerWrapper">
            
            <router-link to="/educacion-superior">Principal</router-link>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                ¿Quiénes somos?
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-superior/somos">Misión, Visión y Valores</router-link>
                </li>
                <li>
                  <router-link to="/educacion-superior/ejes">Plan Estratégico</router-link>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Oferta Educativa
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-superior/oferta-educativa">TIC's</router-link>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Instituciones
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-superior/universidades-tecnologicas">Universidades Tecnológicas</router-link>
                </li>
                <li>
                  <router-link to="/educacion-superior/escuelas-normales">Escuelas Normales</router-link>
                </li>
              </ul>
            </div>

            <router-link to="/educacion-superior/programas-transversales">Programas Transversales</router-link>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Servicios
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/educacion-superior/cedulas">Expedición de Cédulas Profesionales Estatales</router-link>
                </li>
                <li>
                  <router-link to="/educacion-superior/colegios">Registro de Colegios de Profesionistas</router-link>
                </li>
                <li>
                  <router-link to="/educacion-superior/incorporacion">Reconocimiento de Validez Oficial de Estudios (RVOE)</router-link>
                </li>
              </ul>
            </div>

            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Datos
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link to="/">Indicadores</router-link>
                </li>
                <li>
                  <router-link to="/">Estudios</router-link>
                </li>
              </ul>
            </div>

          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarDESComponent"
}
</script>