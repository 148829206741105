<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - Dirección de Educación Media Superior</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Novedades</h2>
        <CarruselComponent :slides="slides" />
      </div>
    </div>

    <div class="row">
      <div class="col" id="gabinete-cards">
        <div class="card-funcionario">
          <div class="d-flex align-items-center">
            <img src="https://www.iea.gob.mx/img/logo-ags.png" alt="funcionario" class="img-fluid mx-auto" style="width: 150px;" />
            <div class="funcionario-info">
              <p class="funcionario-puesto text-center">Director de Educación Media Superior del IEA</p>
              <h1 class="funcionario-nombre text-center">Noé García Gómez</h1>
              <p class="funcionario-contacto text-center">
                Calle Cerro de la Joya 304, Fracc. Fovissste Ojocaliente II, C.P. 20256 <br />
                Aguascalientes, Ags.
              </p>
              <p class="funcionario-contacto text-center">
                (449) 149 2400 ext.7806
              </p>
              <p class="funcionario-contacto text-center">
                -
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.616784713504!2d-102.2656364247198!3d21.87231247999662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429edfd8e278dc1%3A0xb6b8c1c412e805c3!2sEspacio%20Comun%20Educacion%20Media%20Superior!5e0!3m2!1ses-419!2smx!4v1712256902177!5m2!1ses-419!2smx" class="rounded shadow" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Daniel Ponce Maldonado',
          area: 'Dirección de Educación Media Superior',
          telefono: '449 149 2400 ext. 7800',
          fecha: '02/08/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';
import CarruselComponent from "@/components/CarruselComponent.vue";

export default {
  name: "PrincipalView",
  components: {
    RIComponent,
    CarruselComponent
  },
  data() {
    return {
      slides: [
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/img-carrusel-07.webp",
          link: "/educacion-media-superior/nuevo-ingreso"
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/img-carrusel-05.webp",
          link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/RES-Proceso-de-Reconocimiento-a-la-Práctica-Educativa-en-Educación-Media-Superior.pdf"
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/img-carrusel-04.webp",
          link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Primera-Carrera-Lancera.pdf"
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/img-carrusel-02.webp",
          link: "/educacion-media-superior/revista"
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/img-carrusel-01.webp"
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
