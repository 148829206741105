<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA (DES) - Universidades Tecnológicas</h1>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
      <div class="col">
        <a href="https://www.utags.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 12rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/universidades-tecnologicas/UTA.png" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Universidad Tecnológica de Aguascalientes</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.utcalvillo.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 12rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/universidades-tecnologicas/UTCALVILLO.png" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Universidad Tecnológica de Calvillo</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.utna.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 12rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/universidades-tecnologicas/UTNA.png" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Universidad Tecnológica del Norte de Aguascalientes</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.utma.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 12rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/universidades-tecnologicas/UTMETROPOLITANA.png" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Universidad Tecnológica Metropolitana</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.utr.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 12rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/universidades-tecnologicas/UTR.png" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Universidad Tecnológica "El Retoño"</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://upa.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 12rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/universidades-tecnologicas/UPA.png" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Universidad Politécnica de Aguascalientes</p>
          </div>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Stefany Karina Jiménez Colombo',
            area: 'Departamento de Divulgación y Educación Científica y Tecnológica',
            telefono: '449 149 2400 ext. 7805',
            fecha: '05/04/2024'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "UniversidadesTecnologicasView",
  components: {
    RIComponent
  }
}
</script>

<style>

</style>
