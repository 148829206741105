<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Directorio Telefónico</h1>
      </div>
    </div>

    <div class="row g-3 mb-3">
      <div class="col-12">
        <input placeholder="Buscar" style="text-transform: uppercase;" v-model="searchText" />
      </div>
      <div class="col-12">
        <select class="form-select" v-model="indexDirecciones" @change="searchText = ''">
          <option disabled value="">SELECCIONE UNA DIRECCIÓN</option>
          <option v-for="(direccion, index) in directorio" :value="index">{{ direccion.nombre }}</option>
        </select>
        <hr />
      </div>
    </div>

    <div class="row mb-3" v-if="searchText == ''">
      <div class="col">
        <p class="fw-bold c_base text-center">{{ directorio[indexDirecciones].nombre }}</p>
        <div class="accordion" id="accordionDirectorioTelefonico">
          <div class="accordion-item" v-for="(subdireccion, index) in directorio[indexDirecciones].subdirecciones">
            <h2 class="accordion-header" :id="'heading' + index">
              <button class="accordion-button collapsed subtitulo-sm mb-0" type="button" data-bs-toggle="collapse" :data-bs-target="'#accordion' + index" aria-expanded="false" :aria-controls="'accordion' + index">
                {{ subdireccion.nombre }}
              </button>
            </h2>
            <div :id="'accordion' + index" :class="'accordion-collapse collapse'" :aria-labelledby="'heading' + index" data-bs-parent="#accordionDirectorioTelefonico">
              <div class="accordion-body">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Cargo</th>
                        <th>Teléfono</th>
                        <th>Extensión</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="extension in subdireccion.extensiones">
                        <td>{{ extension.nombre }}</td>
                        <td>{{ extension.cargo }}</td>
                        <td>{{ extension.telefono }}</td>
                        <td>{{ extension.extension }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-3" v-if="searchText != ''">
      <div class="col-12" v-for="direccion in search">
        <p class="fw-bold c_base text-center">{{ direccion.nombre }}</p>
        <div v-for="subdireccion in direccion.subdirecciones">
          <p class="fw-bold c_2">{{ subdireccion.nombre }}</p>
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Cargo</th>
                  <th>Teléfono</th>
                  <th>Extensión</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="extension in subdireccion.extensiones">
                  <td>{{ extension.nombre }}</td>
                  <td>{{ extension.cargo }}</td>
                  <td>{{ extension.telefono }}</td>
                  <td>{{ extension.extension }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-center">
          <a href="https://www.iea.gob.mx/IEA/tramites-y-servicios/DIRECTORIO-IEA-2024-07-17.pdf" target="_blank" class="btn btn-principal">
            <span class="iconify" data-icon="material-symbols:download" width="25" height="25" data-inline="false"></span>
            Descargar Directorio Telefónico
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'David Alejandro López Rubalcava',
          area: 'Coordinación de Comunicación Social',
          telefono: '449 910 5600 ext. 4372',
          fecha: '23/07/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';
import directorioTelefonicoData from '@/assets/json/directorioTelefonicoData.json';

export default {
  name: "DirectorioTelefonicoView",
  data() {
    return {
      directorio: directorioTelefonicoData,
      indexDirecciones: 0,
      searchText: ""
    }
  },
  components: {
    RIComponent
  },
  computed: {
    search() {
      var search = [];

      this.directorio.forEach((direccion) => {
        let direccionS = {
          nombre: direccion.nombre,
          subdirecciones: []
        }
        direccion.subdirecciones.forEach((subdireccion) => {
          let subdireccionS = {
            nombre: subdireccion.nombre,
            extensiones: []
          }
          subdireccion.extensiones.filter((extension) => {
            if (extension.nombre.includes(this.searchText.toUpperCase()) ||
              extension.cargo.includes(this.searchText.toUpperCase()) ||
              extension.telefono.includes(this.searchText) ||
              extension.extension.includes(this.searchText)) {
              subdireccionS.extensiones.push(extension);
            }
          });
          if (subdireccionS.extensiones.length != 0) {
            direccionS.subdirecciones.push(subdireccionS);
          }
        });
        if (direccionS.subdirecciones.length != 0) {
          search.push(direccionS);
        }
      });

      return search;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
