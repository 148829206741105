<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Misión, Visión y Valores Institucionales</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Misión</h2>
        <p>
          Proporcionar servicios educativos con excelencia, equidad, inclusión y
          calidez para coadyuvar en la formación integral de las y los
          estudiantes adquiriendo las competencias necesarias para lograr una
          vida plena que contribuya al desarrollo de una sociedad plural,
          incluyente y humanista.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Visión</h2>
        <p>
          Lograr hacer del Sistema Educativo Estatal líder y ejemplo para la
          construcción de una sociedad evolutiva, humanista, vinculante y pilar
          fundamental en el desarrollo de los individuos, a través de la
          equidad, inclusión y compromiso.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">
          Valores Institucionales <span class="c_2">(1)</span>
        </h2>
        <ul>
          <li>
            <strong>Respeto.</strong> La comunidad se conduce con austeridad y
            sin ostentación, y otorga un trato digno y cordial a las personas en
            general y a sus compañeros de trabajo, estudiantes e investigadores,
            superiores y subordinados, considerando sus derechos, de tal manera
            que propicien el diálogo cortés y la aplicación armónica de
            instrumentos que conduzcan al entendimiento, a través de la eficacia
            y el interés público.
          </li>
          <li>
            <strong>Interés público.</strong> La comunidad actúa buscando en
            todo momento la máxima atención de las necesidades y demandas de la
            sociedad por encima de intereses y beneficios particulares, ajenos a
            la satisfacción colectiva.
          </li>
          <li>
            <strong>Integridad.</strong> La comunidad actúa siempre de manera
            congruente con los principios que se deben observar en el desempeño
            de un empleo, cargo, comisión o función, convencidos en el
            compromiso de ajustar su conducta para que impere en su desempeño
            una ética que responda al interés público y generen certeza plena de
            su conducta frente a todas las personas con las que se vincule u
            observen su actuar.
          </li>
          <li>
            <strong>Cooperación.</strong> La comunidad colabora entre sí y
            propicia el trabajo en equipo para alcanzar los objetivos comunes
            previstos en los planes y programas, generando así una plena
            vocación de servicio en beneficio de la colectividad y confianza de
            sus miembros.
          </li>
          <li>
            <strong>Transparencia.</strong> La comunidad, en el ejercicio de sus
            funciones, protege los datos personales que estén bajo su custodia;
            privilegian el principio de máxima publicidad de la información
            pública, atendiendo con diligencia los requerimientos de acceso y
            proporcionando la documentación que generan, obtienen, adquieren,
            transforman o conservan; y en el ámbito de su competencia, difunden
            de manera proactiva información que genera valor a la sociedad y
            promueve una institución abierta.
          </li>
          <li>
            <strong>Rendición de cuentas.</strong> La comunidad asume plenamente
            ante la sociedad y sus autoridades la responsabilidad que deriva del
            ejercicio de su empleo, cargo o comisión, por lo que informan,
            explican y justifican sus decisiones y acciones, y se sujetan a un
            sistema de sanciones, así como a la evaluación y al escrutinio
            público de sus funciones por parte de la ciudadanía y los miembros
            de la propia comunidad.
          </li>
          <li>
            <strong>Igualdad y no discriminación.</strong> La comunidad presta
            sus servicios a todas las personas sin distinción, exclusión,
            restricción, o preferencia basada en el origen étnico o nacional, el
            color de piel, la cultura, el sexo, el género, la edad, las
            discapacidades, la condición social, económica, de salud o jurídica,
            la religión, la apariencia física, las características genéticas, la
            situación migratoria, el embarazo, la lengua, las opiniones, las
            preferencias sexuales, la identidad o filiación política, el estado
            civil, la situación familiar, las responsabilidades familiares, el
            idioma, los antecedentes penales o en cualquier otro motivo.
          </li>
          <li>
            <strong>Equidad de género.</strong> La comunidad, en el ámbito de
            sus competencias y atribuciones, garantiza que todas las personas
            accedan con las mismas condiciones, posibilidades y oportunidades de
            educación; a los bienes y servicios públicos; a los programas y
            beneficios institucionales, y a los empleos, cargos y comisiones
            otorgados, de modo que se asegure condiciones de igualdad y no
            discriminación.
          </li>
          <li>
            <strong>Entorno cultural y ecológico.</strong> La comunidad en el
            desarrollo de sus actividades evita la afectación del patrimonio
            cultural de cualquier nación y de los ecosistemas del planeta; asume
            una férrea voluntad de respeto, defensa y preservación de la cultura
            y del medio ambiente, y en el ejercicio de sus funciones y conforme
            a sus atribuciones, promueve en la sociedad la protección y
            conservación de la cultura y el medio ambiente, al ser el principal
            legado para las generaciones futuras. De tal suerte, la educación
            que se imparta deberá preparar para el desarrollo sostenible y
            respeto al entorno cultural de educadores y alumnos por igual.
          </li>
        </ul>
        <p class="fw-bold c_1">
          <strong class="c_2">(1)</strong>
          Este apartado se nutre de dos fuentes principales. La primera es el
          acuerdo de los servidores públicos del gobierno federal.
          <a href="http://www.dof.gob.mx/nota_detalle.php?codigo=5404568&fe-cha=20/08/2015" target="_blank">Consultar Aquí</a>. 
          Mientras que la segunda son los distintos esfuerzos realizados por
          la UNESCO a favor de la Educación para el Desarrollo Sostenible.
          <a href="http://www.unesco.org" target="_blank">Consultar Aquí</a>.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'David Alejandro López Rubalcava',
            area: 'Coordinación de Comunicación Social',
            telefono: '449 910 5600 ext. 4372',
            fecha: '5/4/2023'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "SomosView",
  components: {
    RIComponent,
  },
};
</script>

<style></style>
