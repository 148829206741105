<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_video.svg" alt="...">
          <h1 class="titulo">Videoteca del Instituto de Educación de Aguascalientes</h1>
        </div>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 g-3">
      <div class="col">
        <p class="fw-bold c_base">IEA Día Internacional de la Mujer</p>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/OXV87b2M1c8" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col">
        <p class="fw-bold c_base">Cápsula Maestra Ana Sánchez</p>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/zJmA5RW-O6M" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col">
        <p class="fw-bold c_base">"El Aula" Episodio 1 Temporada 2 “La educación como herramienta de igualdad”</p>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/XaZiZ04zlv4" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col">
        <p class="fw-bold c_base">Programa para el Mejoramiento de la Calidad de la Educación Superior (PROMECA)</p>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/_eoxm7N2VS4" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'David Alejandro López Rubalcava',
            area: 'Coordinación de Comunicación Social',
            telefono: '449 910 5600 ext. 4372',
            fecha: '14/3/2023'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "VideotecaView",
  components: {
    RIComponent
  }
}
</script>

<style>

</style>
