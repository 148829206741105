<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - HUB</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          El propósito de este espacio es brindar a los estudiantes, maestros e
          instituciones el ambiente y herramientas necesarias para una
          vinculación y acercamiento a nuevas temáticas tecnológicas de manera
          teórico-práctica.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <CarruselComponent :slides="slides" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="subtitulo">Laboratorio 1</h1>
        <ul>
          <li>
            <strong>Taller de Robótica.</strong> El alumno identifica lo básico
            de estructuras y principios de las articulaciones de un Robotics
            Bioloid, así como el manejo de un lenguaje de programación Arduino a
            través del software RoboPlus con el cual los estudiantes programan y
            controlan los movimientos del robot.
          </li>
          <li>
            <strong>Taller de Diseño e Impresión 3D.</strong> El estudiante
            aprenderá a realizar diseños, modificación e impresión de objetos
            3D, a través del uso del software Inventor Profesional y la
            impresora MakerBot Replicator.
          </li>
        </ul>
        <h1 class="subtitulo">Laboratorio 2</h1>
        <ul>
          <li>
            <strong>Taller de Energías Renovables.</strong>El estudiante
            identificará, reconocerá y se familiariza con los elementos que
            componen una energía renovable y la generación de las mismas a
            través de fuentes naturales inagotables.
          </li>
          <li>
            <strong>Taller de Procesos de Manufactura.</strong>El alumno a
            través del software FLEXSIM aprenderá a modelar y entender la
            simulación de un proceso industrial; visualizando y probando cambios
            en las operaciones y los procesos de logística, manejo de materiales
            y manufactura.
          </li>
          <li>
            <strong>Mecánica Básica.</strong>El alumno será capaz de comprender,
            a través del estudio y la aplicación de los principios de la física
            y mecánica la generación y transmisión de movimiento en sistemas de
            mecánica básica.
          </li>
          <li>
            <strong>Celdas Combustibles.</strong>El alumno aprenderá cómo es que
            funcionan las celdas combustibles a través de un dispositivo
            electroquímico de flujo continuo de combustible y oxidante,
            generando corriente eléctrica a un circuito externo.
          </li>
          <li>
            <strong>Sensores Labdisc.</strong>El alumno conocerá el uso de 14
            sensores inalámbricos; presión del aire, temperatura ambiente,
            corriente, distancia (movimiento), temperatura externa, GPS, luz,
            micrófono, humedad relativa, sonido, entrada universal y voltaje,
            mediante los cuales llevan a cabo una variedad de experimentos
            científicos en el exterior.
          </li>
          <li>
            <strong>Tenología VR.</strong>El estudiante será capaz de recrear
            escenarios de apariencia real por medio de tecnología informática y
            el uso de gafas 3D que le darán la sensación de estar inmerso en él.
          </li>
        </ul>
        <h1 class="subtitulo">Laboratorio 3</h1>
        <ul>
          <li><strong>Mentorías.</strong></li>
          <li><strong>Emprendimiento.</strong></li>
          <li><strong>Desarrollo de procesos industriales.</strong></li>
        </ul>
        <h1 class="subtitulo">Auditorio</h1>
        <li><strong>Conferencias especiales.</strong></li>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
            nombre: 'Valeria Rubí Briones Campos',
            area: 'Dirección de Educación Media Superior',
            telefono: '449 149 2400 ext. 7800',
            fecha: '30/01/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";
import CarruselComponent from "@/components/CarruselComponent.vue";

export default {
  name: "HUBView",
  components: {
    RIComponent,
    CarruselComponent
  },
  data() {
    return {
      slides: [
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/hub/img-carrusel-01.webp",
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/hub/img-carrusel-02.webp",
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/hub/img-carrusel-03.webp",
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/hub/img-carrusel-04.webp",
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/hub/img-carrusel-05.webp",
        },
        {
          img: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/hub/img-carrusel-06.webp",
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
