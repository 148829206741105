<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Educación Media Superior</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          La Educación Media Superior, bachillerato o preparatoria es el período
          de estudio de entre dos y tres años que cursa una persona de
          aproximadamente entre los 15 a 19 años. Algunas modalidades de este
          nivel educativo se puede ingresar hasta los 22 años. La Educación
          Media Superior se divide en varias áreas de especialidad, donde los
          estudiantes adquieren conocimientos básicos. Todas las instituciones
          de Educación Media Superior, incluyendo las preparatorias abiertas,
          deben estar incorporadas a la Secretaría de Educación Pública o a
          alguna universidad estatal o nacional.
        </p>
        <p>
          La esencia de la Educación Media Superior (EMS) en México consiste en
          brindar a los estudiantes oportunidades de aprendizaje con calidad,
          independiente del nivel económico, social o lugar de residencia. La
          EMS se ubica en el nivel intermedio del Sistema Educativo Nacional.
          El Instituto de Educación de Aguascalientes en coordinación con la
          Subsecretaría de Educación Media Superior (SEMS) y la Secretaría de
          Educación Pública y la sociedad en general, son responsables de que
          este nivel educativo funcione bien, con calidad y cumpla un cometido
          propio.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner-body-left bg_base">
          <img src="img/niveles-educativos/educacion-media-superior/banner-educacion-media-superior.webp" alt="..." class="img-fluid"/>
          <div class="baninfo-wrapper">
            <h1>Educación Media Superior</h1>
            <!-- <div class="d-flex align-items-end flex-column">
              <a href="https://educacionmedia.iea.edu.mx" target="_blank" class="btn btn-principal-light float-end">
                Ir al sitio
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Planteles Educativos</h2>
        <div class="banner_sm bg_dg-4 mb-3">
          <div class="item1">
            <p class="titulo">Bachilleratos Incorporados</p>
          </div>
          <div class="item2">
            <p class="contenido">Los Bachilleratos Incorporados tienen un lugar para tí</p>
          </div>
          <div class="item3">
            <a href="IEA/niveles-educativos/educacion-media-superior/DIRECTORIO_ESCUELAS_INCORPORADAS.pdf" target="_blank" class="btn btn-principal-light">
              Consultar bachilleratos
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Preparatoria abierta</h2>
        <p>
          La Preparatoria Abierta SEP o Prepa Abierta SEP es un servicio
          educativo público de modalidad no escolarizada del Sistema Educativo
          Nacional que proporciona la Secretaría de Educación Pública a través
          de la Dirección General de Bachillerato y los gobiernos de todos los
          estados de la República Mexicana que permite iniciar o concluir los
          estudios del nivel bachillerato en México.
        </p>
        <p>
          El objetivo de la preparatoria abierta es brindar nuevas oportunidades
          a cualquier persona que quiera terminar sus estudios de Educación
          Media Superior a través de horarios flexibles.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner-body-left bg_dg-5">
          <img src="img/niveles-educativos/educacion-media-superior/banner-prepa-abierta.webp" alt="..." class="img-fluid"/>
          <div class="baninfo-wrapper">
            <h1>Preparatoria abierta</h1>
            <div class="d-flex align-items-end flex-column">
              <a href="http://www.prepaaguascalientes.sep.gob.mx" target="_blank" class="btn btn-principal-light float-end">
                Ir al sitio
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Juan Manuel Flores de la Rosa',
            area: 'Departamento de Vinculacion con el Sector Productivo y la Formación Especializada',
            telefono: '449 910 5600 ext. 4143',
            fecha: '3/2/2023'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "EducacionMediaSuperiorView",
  components: {
    RIComponent
  }
}
</script>

<style>

</style>
