<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - Bachilleratos Incorporados</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          El objetivo del departamento de bachilleratos incorporados es
          garantizar que el funcionamiento del servicio se desarrolle en sus
          diferentes tipos y modalidades de la Educación Media Superior para
          aquellos planteles particulares incorporados al Instituto de Educación
          de Aguascalientes, conforme a las normas, los lineamientos y los
          criterios establecidos.
        </p>
        <ul>
          <li>
            Asegurar el logro de los objetivos para el servicio educativo.
          </li>
          <li>
            Velar porque el trabajo de las instituciones se ajuste a las normas
            establecidas para propiciar la calidad y oportunidad de los
            servicios educativos proporcionados a los alumnos de educación
            media.
          </li>
          <li>
            Favorecer las relaciones entre los planteles y las respectivas áreas
            para el desarrollo de programas y eventos diversos.
          </li>
          <li>
            Promocionar el crecimiento y desarrollo del alumno y con esto, el
            mejoramiento de la sociedad.
          </li>
        </ul>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-lg-3 g-3">
      <div class="col d-flex justify-content-center" v-for="bachilleratoIncorporado of bachilleratosIncorporados">
        <button class="btn btn-principal-light p-0" @click="showModal(bachilleratoIncorporado)">
          <div class="card" style="max-width: 18rem; min-height: 16rem;">
            <img :src="bachilleratoIncorporado.image" class="card-img-top p-3" alt="..." />
            <p class="fw-bold c_base text-center">{{ bachilleratoIncorporado.name }}</p>
          </div>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Metzli Rodríguez Rodríguez',
          area: 'Departamento de Certificación y Formación para el Trabajo',
          telefono: '449 149 2400 ext. 7800',
          fecha: '05/04/2024',
        }" />
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="bachilleratoIncorporadoModal" tabindex="-1" aria-hidden="true" v-if="bachilleratoIncorporadoModal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="subtitulo">{{ bachilleratoIncorporadoModal.name }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="text-align: justify;">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-lg-4">
                <img :src="bachilleratoIncorporadoModal.image" class="card-img-top p-3" alt="..." />
              </div>
              <div class="col-12 col-lg-8">
                <p class="fw-bold c_base">{{ bachilleratoIncorporadoModal.name }}</p>
                <p class="fw-bold c_base">
                  <span class="iconify" data-icon="majesticons:location-marker" width="25" height="25" data-inline="false"></span>
                  {{ bachilleratoIncorporadoModal.location }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-principal" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "BachilleratosEstatalesView",
  components: {
    RIComponent,
  },
  data() {
    return {
      bachilleratosIncorporados: [
        {
          name: "BACHILLERATO TECNOLÓGICO UNIVERSITARIO DE AGUASCALIENTES",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/BTUA.webp",
          location: "Av. Siglo XXI 402, Municipio Libre, C.P. 20199 Aguascalientes, Ags."
        },
        {
          name: "BOSQUES INTERNATIONAL SCHOOL",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/bosques-international-school.webp",
          location: "Prol. Gral. Ignacio Zaragoza 701, Villa de las Trojes, C.P. 20115 Aguascalientes, Ags."
        },
        {
          name: "INSTITUTO CASA BLANCA HIGH SCHOOL",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/instituto-casa-blanca-high-school.webp",
          location: "C. 30 de Septiembre 1303, Jardines de Casa Blanca, C.P. 20297 Aguascalientes, Ags."
        },
        {
          name: "INSTITUTO VICTOR MANUEL CASTELAZO MURIEL DE AGUASCALIENTES, A.C.",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/instituto-victor-manuel-castelazo-muriel-de-aguascalientes.webp",
          location: "C. Afrodita S/N, Las Hadas, Aguascalientes, Ags."
        },
        {
          name: "CENTRO DE BACHILLERATO TÉCNICO EN COMPUTACIÓN",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/CBTC.webp",
          location: "C: Lic. Francisco Primo Verdad 411, Zona Centro, C.P. 20000 Aguascalientes, Ags."
        },
        {
          name: "CENTRO DE ENSEÑANZA FCO. I. MADERO, A.C.",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/CEFIM.webp",
          location: "C. Rivero y Gutiérrez 315, Barrio de Guadalupe, C.P. 20059 Aguascalientes, Ags."
        },
        {
          name: "CENTRO EDUCATIVO JOSÉ VASCONCELOS",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/CEJV.webp",
          location: "C. Málaga 301, Esq. San Emilión, Fracc. Arboledas Paso Blanco Jesus Maria, Aguascalientes."
        },
        {
          name: "CENTRO UNIVERSITARIO OCTAVIO PAZ",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/CUOP.webp",
          location: "Av. Francisco I. Madero 441, Zona Centro, C.P. 20000 Aguascalientes, Ags."
        },
        {
          name: "COLEGIO ALEXANDER HAMILTON",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/colegio-alexander-hamilton.webp",
          location: "C. Valladolid 309, Zona Centro, C.P. 20000 Aguascalientes, Ags."
        },
        {
          name: "COLEGIO DEL ARTE Y LAS CIENCIAS",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/CAyC.webp",
          location: "C. Rivero y Gutiérrez 320, Zona Centro, C.P. 20000 Aguascalientes, Ags."
        },
        {
          name: "COLEGIO HELEN KELLER, A.C.",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/colegio-helen-keller.webp",
          location: "C. Feliciano Martínez Cruz 600-A, Fracc. Chicahuales, C.P. 20926 Jesús María, Ags."
        },
        {
          name: "COLEGIO LINCOLN, A.C.",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/colegio-lincoln.webp",
          location: "Av. Mahatma Gandhi 350, Desarrollo Especial Villa Asunción, C.P. 20234 Aguascalientes, Ags."
        },
        {
          name: "COLEGIO MARCELO SILVA",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/colegio-marcelo-silva.webp",
          location: "C. Julio Cadena 2021-2023, Los Arroyitos, C.P. 20923 Jesús María, Ags."
        },
        {
          name: "COLEGIO PASCAL, A.C.",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/colegio-pascal.webp",
          location: "C. Juan de Montoro 410, Zona Centro, C.P. 20000 Aguascalientes, Ags."
        },
        {
          name: "COLEGIO SANTA FE",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/colegio-santa-fe.webp",
          location: "Prol. Libertad 1509, Circunvalación Nte., C.P. 20020 Aguascalientes, Ags."
        },
        {
          name: "COLEGIO TIERRA NUEVA",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/colegio-tierra-nueva.webp",
          location: "Hacienda El Toril 201, Haciendas de Aguascalientes, C.P. 20196 Aguascalientes, Ags."
        },
        {
          name: "BACHILLERATO DE LA UNIVERSIDAD DINAMO DE AMÉRICA",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/BUDA.webp",
          location: "Blvd. José María Chávez 2315, San Francisco del Arenal, C.P. 20280 Aguascalientes, Ags."
        },
        {
          name: "ESCUELA PREPARATORIA BACHILLERES DE AGUASCALIENTES",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/escuela-preparatoria-bachilleres-de-aguascalientes.webp",
          location: "Av. Aguascalientes Sur 3011, Prados del Sur, C.P. 20280 Aguascalientes, Ags."
        },
        {
          name: "ESCUELA PREPARATORIA BATENT",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/escuela-preparatoria-batent.webp",
          location: "Av. Eugenio Garza Sada 904, Lomas de San Jose, C.P. 20926 Jesús María, Ags."
        },
        {
          name: "ESCUELA PREPARATORIA LAS AMÉRICAS",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/escuela-preparatoria-las-americas.webp",
          location: "C. José F. Elizondo 105, Las Américas, C.P. 20000 Aguascalientes, Ags."
        },
        {
          name: "ESCUELA PREPARATORIA SEGUNDO CENTENARIO",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/escuela-preparatoria-segundo-centenario.webp",
          location: "C. Independencia 206, Zona Centro, C.P. 20800 Calvillo, Ags."
        },
        {
          name: "PREPARATORIA FORUM INTERNACIONAL",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/preparatoria-forum-internacional.webp",
          location: "Av. Tecnológico 107, Ojocaliente, C.P. 20180 Aguascalientes, Ags."
        },
        {
          name: "UNIVERSIDAD GALILEA",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/UG.webp",
          location: "Av. Esfuerzo Nacional 612, Municipio Libre, C.P. 20199 Aguascalientes, Ags."
        },
        {
          name: "GLOBAL UNIVERSITY",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/GU.webp",
          location: "Av. Independencia 2134, Trojes de Alonso, C.P. 20115 Aguascalientes, Ags."
        },
        {
          name: "HYBRIDGE EDUCATION",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/HE.webp",
          location: "Av. Convención de 1914 Pte. 1132-211, Barranca de Guadalupe, C.P. 20210 Aguascalientes, Ags."
        },
        {
          name: "INSTITUTO INTEGRAL DE EDUCACIÓN MEDIA SUPERIOR",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/IIEMS.webp",
          location: "Av. Morelos 202-D, Zona Centro, C.P. 20400 Rincón de Romos, Ags."
        },
        {
          name: "VIRTUAL INNOVA PREPARATORIA",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/virtual-innova-preparatoria.webp",
          location: "Av. Eugenio Garza Sada 701 Col. Lomas de Jesús María, C.P. 20920 Jesús María, Ags."
        },
        {
          name: "INSTITUTO ALAMEDA, A.C.",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/instituto-alameda.webp",
          location: "C. Lic. Fco. Primo Verdad 410, Barrio de Chora, C.P. 20417 Rincón de Romos, Ags."
        },
        {
          name: "INSTITUTO NIELS BOHR, A.C.",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/instituto-niels-bohr.webp",
          location: "C. Decreto 30 de Enero de 1992 201-C, Col. San José de Buenavista, C.P. 20303 San Francisco de los Romo, Ags."
        },
        {
          name: "INSTITUTO TESLA AGUASCALIENTES",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/instituto-tesla-aguascalientes.webp",
          location: "C. Arroyo Seco 102, Fracc. Villas de Ntra. Sra. de la Asunción, C.P. 20126 Aguascalientes, Ags."
        },
        {
          name: "IT INNOVATION SCHOOL CAMPUS AGUASCALIENTES",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/it-innovation-school-campus-aguascalientes.webp",
          location: "Av. 1° de Diciembre 102, Sandovales, C.P. 20196 Jesús María, Ags."
        },
        {
          name: "COMUNIDAD DE APRENDIZAJE IUVENTUS",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/comunidad-de-aprendizaje-iuventus.webp",
          location: "C. Chasela 303, Arboledas Paso Blanco, C.P. 20907 Jesús María, Ags."
        },
        {
          name: "INSTITUTO KUEPA",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/instituto-kuepa.webp",
          location: "C. Sierra del Laurel 312, Bosques del Prado Nte., C.P. 20127 Aguascalientes, Ags."
        },
        {
          name: "PREPARATORIA JOSÉ MA. MORELOS Y PAVÓN",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/preparatoria-jose-ma-morelos-y-pavon.webp",
          location: "C. Eliseo Trujillo 135, Barrio El Encino, C.P. 20240 Aguascalientes, Ags."
        },
        {
          name: "BACHILLERATO NUEVO MILENIO",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/bachillerato-nuevo-milenio.webp",
          location: "Av. Revolución 14, C.P. 20676 Pabellón de Arteaga, Ags."
        },
        {
          name: "PREPARATORIA MARSELLA",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/preparatoria-marsella.webp",
          location: "Av. Guadalupe Gonzalez 101, Fracc. Unidad Ganadera, C.P. 20130 Aguascalientes, Ags."
        },
        {
          name: "PREPARATORIA POLITÉCNICA DE AGUASCALIENTES",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/preparatoria-politecnica-de-aguascalientes.webp",
          location: "Av. Héroe de Nacozari 2417, Fracc. Jardines del Parque, C.P. 20276 Aguascalientes, Ags."
        },
        {
          name: "PREPARATORIA TERRA NOVA",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/preparatoria-terra-nova.webp",
          location: "Av. Fundición 2308, Fracc. Las Brisas C.P. 20010 Aguascalientes, Ags."
        },
        {
          name: "PREPARATORIA DE LA UNIVERSIDAD CUAUHTÉMOC PLANTEL AGUASCALIENTES",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/UC.webp",
          location: "C. Adolfo López Mateos 102, Trojes de Alonso, C.P. 20908 Aguascalientes, Ags."
        },
        {
          name: "PREPARATORIA DE LA UNIVERSIDAD DE DURANGO CAMPUS AGUASCALIENTES",
          image: "https://www.iea.gob.mx/img/niveles-educativos/educacion-media-superior/bachilleratos-incorporados/UD.webp",
          location: "Carretera Federal 45, salida a Zacatecas Km. 3.6, San Francisco de los Romo, Ags."
        }
      ],
      bachilleratoIncorporadoModal: null
    }
  },
  methods: {
    showModal(bachilleratoIncorporado) {
      this.bachilleratoIncorporadoModal = bachilleratoIncorporado;

      gea.iwin(() => {
        const bachilleratoIncorporadoModal = bootstrap.Modal.getOrCreateInstance("#bachilleratoIncorporadoModal");
        bachilleratoIncorporadoModal.show();
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
