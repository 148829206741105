<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA (DES) - Escuelas Normales</h1>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
      <div class="col">
        <a href="https://crena.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 18rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/escuelas-normales/logo_crena.jpg" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Centro Regional de Educación Normal de Aguascalientes</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.aguascalientes.gob.mx/ENA/" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 18rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/escuelas-normales/logo_ena.jpg" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Escuela Normal de Aguascalientes</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://ensfa.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 18rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/escuelas-normales/logo_ensfa.jpg" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Escuela Normal Superior Federal de Aguascalientes</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.enrjsm.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 18rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/escuelas-normales/logo_enrjsm.jpg" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Escuela Normal Rural "Justo Sierra Méndez"</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.enrr.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 18rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/escuelas-normales/logo_enrr.jpg" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Escuela Normal de Rincón de Romos</p>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="https://upn011.edu.mx" target="_blank">
          <div class="card shadow" style="max-width: 18rem; min-height: 18rem;">
            <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-superior/instituciones/escuelas-normales/logo_upn.jpg" class="card-img-top" alt="...">
            <p class="fw-bold c_base text-center">Universidad Pedagógica Nacional</p>
          </div>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Stefany Karina Jiménez Colombo',
            area: 'Departamento de Divulgación y Educación Científica y Tecnológica',
            telefono: '449 149 2400 ext. 7805',
            fecha: '05/04/2024'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "EscuelasNormalesView",
  components: {
    RIComponent
  }
}
</script>

<style>

</style>