<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Participación Social</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Programas del departamento de Participación Social</h2>
      </div>
      <div class="col-12">
        <div class="banner_sm bg_dg-6 mb-3">
          <div class="item1">
            <p class="titulo">PROAGE</p>
          </div>
          <div class="item2">
            <p class="contenido">Programa de Apoyo a la Gestión Escolar</p>
          </div>
          <div class="item3">
            <router-link to="/educacion-basica/programas-educativos/PROAGE" class="btn btn-principal-light">
              Ver programa
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p>
          Éste programa es público ajeno a cualquier partido político, queda
          prohibido su uso para fines políticos, quien haga uso indebido a los
          recursos de este programa deberá ser denunciado y sancionado ante las
          autoridades conforme a lo que dispone la ley de responsabilidades
          administrativas del Estado de Aguascalientes
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Capacitaciones</h2>
        <div class="container">
          <div class="row row-cols-1 row-cols-md-2">
            <div class="col">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/capacitaciones/DOC-Registro-de-Consejos-de-Participación-Escolar.pdf" target="_blank" class="pillLink">
                <div class="pill-card">
                  <div class="d-inline-flex align-items-center">
                    <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_ciudadano.svg" alt="..." class="img-fluid" />
                    <div>Registro de Consejos de Participación Escolar (Sesión Intermedia)</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/capacitaciones/DOC-Asociaciones-de-Padres-de-Familia.pdf" target="_blank" class="pillLink">
                <div class="pill-card">
                  <div class="d-inline-flex align-items-center">
                    <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_ciudadano.svg" alt="..." class="img-fluid" />
                    <div>Asociaciones de Padres de Familia</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/capacitaciones/DOC-Consejos-de-Participación-Escolar.pdf" target="_blank" class="pillLink">
                <div class="pill-card">
                  <div class="d-inline-flex align-items-center">
                    <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_familiaDos.svg" alt="..." class="img-fluid" />
                    <div>Consejos de Participación Escolar</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/capacitaciones/DOC-Integración-del-Informe-Financiero-Proage-2023-2024.pdf" target="_blank" class="pillLink">
                <div class="pill-card">
                  <div class="d-inline-flex align-items-center">
                    <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_documento.svg" alt="..." class="img-fluid" />
                    <div>Integración del Informe Financiero Proage 2023-2024</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/capacitaciones/DOC-Lineamientos-Proage-2023-2024.pdf" target="_blank" class="pillLink">
                <div class="pill-card">
                  <div class="d-inline-flex align-items-center">
                    <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_copiacertificada.svg" alt="..." class="img-fluid" />
                    <div>Lineamientos Proage 2023-2024</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Formatos del Consejo Escolar de Participación Social en la Educación</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>CONVOCATORIA PARA LA CONSTITUCIÓN DEL CONSEJO DE PARTICIPACIÓN ESCOLAR, CICLO ESCOLAR 2024-2025</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/CEPSE/CPESE-CONVOCATORIA-2024-2025.docx" target="_blank">
                  <span class="iconify" data-icon="icomoon-free:file-word" width="30" height="30"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>EJEMPLOS DE CONFORMACION DE CONSEJO Y COMITÉS</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/CEPSE/CEPSE-EJEMPLOS-DE-CONFORMACIÓN.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>LISTA DE ASISTENCIA DE LA CONSTITUCIÓN DEL CONSEJO DE PARTICIPACIÓN ESCOLAR, CICLO ESCOLAR 2024-2025</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/CEPSE/CEPSE-LISTA-DE-ASISTENCIA-2024-2025.docx" target="_blank">
                  <span class="iconify" data-icon="icomoon-free:file-word" width="30" height="30"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Formatos del Comité de Protección Civil y Seguridad Escolar</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>FORMATO PARA COMITÉ DE PROTECCION CIVIL</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/CPCSE/CPCSE-FORMATO-2024-2025.xlsx" target="_blank">
                  <span class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Formatos del Comité de Contraloría Social</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>CONVOCATORIA DE COMITÉ DE CONTRALORÍA SOCIAL</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/CCS/CCS-CONVOCATORIA-2024-2025.xlsx" target="_blank">
                  <span class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>FORMATO DE ACTA DE COMITÉ DE CONTRALORÍA SOCIAL</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/CCS/CCS-FORMATO-DE-ACTA-2024-2025.xlsx" target="_blank">
                  <span class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>FORMATO DE QUEJAS, DENUNCIAS O SUGERENCIAS</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/CCS/CCS-FORMATO-DE-QUEJAS-DENUNCIAS-SUGERENCIAS-2023-2024.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>CONTRALORÍA SOCIAL Y ÓRGANO ESTATAL DE CONTROL</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/CCS/Contraloria-Social-y-el-Órgano-Estatal-de-Control.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Formatos de la Asociación de Padres de Familia (Mesa Directiva)</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>FORMATOS DE ACTA CONSTITUTIVA DE LA ASOCIACIÓN ESCOLAR DE PADRES DE FAMILIA</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/APF/APF-FORMATOS-2024-2025.xlsx" target="_blank">
                  <span class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>REGLAMENTO DE LAS ASOCIACIONES DE PADRES DE FAMILIA</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/APF/APF-REGLAMENTO.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>REFORMA AL REGLAMENTO DE LAS ASOCIACIONES ESCOLARES DE PADRES DE FAMILIA</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/APF/APF-REGLAMENTO-REFORMA.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Ernesto Flores Flores',
          area: 'Departamento de Participación Social',
          telefono: '449 910 5600 ext. 4176 / 4282',
          fecha: '26/08/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "ParticipacionSocialView",
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>
