<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">
          IEA (DEB) - Programa Estratégico "Los Sueños se Construyen en el Aula"
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          Los sueños se construyen en el aula es un programa estratégico
          generado a iniciativa de nuestra gobernadora, la Dra. María Teresa
          Jiménez Esquivel, quien a través del Mtro. Luis Enrique Reynoso
          Gutiérrez, Director General del Instituto de Educación de
          Aguascalientes, se desarrolla en nuestro Estado.
        </p>
        <p>
          Esta estrategia es de gran trascendencia para Aguascalientes ya que no
          sólo se trata de una política educativa, sino de una convocatoria para
          todos los hidrocálidos que de manera altruista, comprometida y
          corresponsable quieran ayudar a las niñas, niños, adolescentes y
          jóvenes que, en el marco de la pandemia y por alguna razón, han dejado
          la educación preescolar, primaria o secundaria, o bien han bajado su
          rendimiento académico.
        </p>
        <div class="banner_sm bg_dg-7 mb-3">
          <div class="item1">
            <p class="titulo">Los Sueños se Construyen en el Aula</p>
          </div>
          <div class="item2">
            <p class="contenido">Presentación informativa</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/PESCA-Presentación-Informativa.pdf" target="_blank" class="btn btn-principal-light">
              Ver presentación
            </a>
          </div>
        </div>
        <h2 class="subtitulo">Objetivo General</h2>
        <p>
          Generar las condiciones necesarias para que las niñas, niños,
          adolescentes y jóvenes (NNAJ) que durante la pandemia abandonaron sus
          estudios o, que no lograron el óptimo desarrollo de sus competencias
          básicas, puedan consolidarlas, todo ello con la corresponsabilidad de
          la sociedad civil y de las instituciones públicas y privadas de
          educación superior.
        </p>
        <h2 class="subtitulo">Objetivos Específicos</h2>
        <ol>
          <li>
            Localizar y reincorporar al sistema educativo estatal a las NNAJ que
            abandonaron la escuela (Estudiantes Desafiliados) a fin de que
            continúen con sus estudios de educación básica.
          </li>
          <li>
            Fortalecer el desarrollo de las competencias básicas para el
            aprendizaje: lectura, escritura y pensamiento matemático de los
            estudiantes focalizados en rezago educativo profundo con el
            propósito de que cuenten con las herramientas necesarias para
            continuar con el desarrollo de su aprendizaje.
          </li>
          <li>
            Fortalecer los procesos de lectura y pensamiento matemático; así
            como aquellos contenidos curriculares necesarios a fin de mejorar el
            nivel educativo de todos los estudiantes.
          </li>
          <li>
            Desarrollar e implementar programas, estrategias y/o acciones que
            permitan generar una educación con equidad, inclusiva, segura,
            saludable, sustentable y que fortalezca el desarrollo socioemocional
            de los NNAJ que estudian la educación básica.
          </li>
        </ol>
        <h2 class="subtitulo">Metas</h2>
        <ol>
          <li>
            DESAFILIACIÓN: Localizar a 19,003 estudiantes de educación básica
            que estuvieron inscritos en los ciclos escolares 2019-2020,
            2020-2021 y 2021-2022y que NO están inscritos en el ciclo escolar
            2022-2023.
          </li>
          <li>
            REZAGO EDUCATIVO PROFUNDO: Atender a 50,795 niños focalizados en
            rezago educativo profundo con el propósito de desarrollar sus
            competencias básicas para el aprendizaje.
          </li>
          <li>
            FORTALECIMIENTO ACADÉMICO: Ofrecer al 100% de los estudiantes de
            educación básica diversos programas educativos con la finalidad de
            fortalecer su desarrollo educativo.
          </li>
        </ol>
        <div class="banner_sm bg_dg-6 mb-3">
          <div class="item1">
            <p class="titulo">Los Sueños se Construyen en el Aula</p>
          </div>
          <div class="item2">
            <p class="contenido">Plan de trabajo</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/PESCA-Plan-de-Trabajo.pdf" target="_blank" class="btn btn-principal-light">
              Ver plan de trabajo
            </a>
          </div>
        </div>
        <h2 class="subtitulo">Programas Estratégicos, Ciclo Escolar 2023 - 2024</h2>
        <div class="banner_sm bg_dg-5 mb-3">
          <div class="item1">
            <p class="titulo">Los Sueños se Construyen en el Aula</p>
          </div>
          <div class="item2">
            <p class="contenido">Programas Estratégicos</p>
          </div>
          <div class="item3">
            <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/PESCA-Programas.pdf" target="_blank" class="btn btn-principal-light">
              Ver presentación
            </a>
          </div>
        </div>
        <div class="banner-body-left bg_3">
          <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/banner-sueños-aula.webp" alt="..." class="img-fluid" />
          <div class="baninfo-wrapper">
            <h1>Los Sueños se Construyen en el Aula</h1>
            <p>Plataforma Web</p>
            <div class="d-flex align-items-end flex-column">
              <a href="https://suenos.iea.edu.mx" target="_blank" class="btn btn-principal-light float-end">
                Ir a la plataforma
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Jorge Abraham Mejía Reyna',
          area: 'Coordinación del Programa Estratégico “Los Sueños se Construyen en el Aula”',
          telefono: '449 910 5600 ext. 4313',
          fecha: '29/07/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "PESCAView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
