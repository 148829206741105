<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEB) - Educación Inicial</h1>
        <p>
          La Educación Inicial se divide en dos modalidades: Escolarizada, que
          abarca todos los Centros de Atención Infantil públicos y privados
          incorporados al IEA, y No Escolarizada, que se enfoca en las visitas a
          hogares y los Centros Comunitarios de Atención a la Primera Infancia
          (CCAPI), atendiendo a niños de 0 a 2 años 11 meses. El Departamento de
          Educación Inicial capacita, asesora y acompaña a las supervisoras de
          las cuatro zonas escolares y a las coordinadoras de ambas modalidades.
          Además, se gestionan los gastos de operación del Programa Expansión de
          la Educación Inicial (PEEI) y se promueve la Política Nacional de
          Educación Inicial.
        </p>
        <p>
          El principal objetivo de la Educación Inicial es potenciar el
          desarrollo integral de niñas y niños de cero a tres años en
          experiencias afectivas, educativas y sociales, y el acompañamiento a
          las familias en las prácticas de crianza.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Ma. del Carmen Hernández Torres',
            area: 'Departamento de Educación Inicial',
            telefono: '449 910 5600 ext. 4093 y 4342',
            fecha: '23/08/2024',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "EducacionInicialView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped></style>
