<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="titulo">
          Recursos para docentes de Preescolar y Primaria
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Lesson Plan</h2>
        <p>
          La planeación de clase es una invaluable herramienta para el exitoso
          desarrollo de la misma en un contexto donde se promuevan y garanticen
          los aprendizajes esperados de los estudiantes. Por ello, el saber cómo
          planear correctamente tiene gran relevancia para nuestra Coordinación.
        </p>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>LESSON PLAN DESCRIPTION</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/preschool-and-elementary/LESSON-PLAN/LESSON PLAN DESCRIPTION.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>LESSON PLAN EXAMPLE 3RD PRESCHOOL</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/preschool-and-elementary/LESSON-PLAN/LESSON PLAN EXAMPLE 3RD PRESCHOOL.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>LESSON PLAN FORMAT</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/preschool-and-elementary/LESSON-PLAN/LESSON PLAN FORMAT.docx" target="_blank">
                  <span class="iconify" data-icon="icomoon-free:file-word" width="30" height="30"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Evaluation Schedule</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>EVALUATION SCHEDULE</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/preschool-and-elementary/EVALUATION SCHEDULE.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Schedule Format</h2>
      </div>
      <div class="col-12">
        <div class="accordion" id="accordionScheduleFormat">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingScheduleFormat0">
              <button class="accordion-button subtitulo-sm mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionScheduleFormat0" aria-expanded="false" aria-controls="accordionScheduleFormat0">
                Preschool
              </button>
            </h2>
            <div id="accordionScheduleFormat0" class="accordion-collapse collapse" aria-labelledby="headingScheduleFormat0" data-bs-parent="#accordionScheduleFormat">
              <div class="accordion-body">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Descripción</th>
                      <th class="text-end">Enlace</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>PRESCHOOL</td>
                      <td class="text-end">
                        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/preschool-and-elementary/SCHEDULE-FORMAT/PRESCHOOL.pdf" target="_blank">
                          <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingScheduleFormat1">
              <button class="accordion-button subtitulo-sm mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionScheduleFormat1" aria-expanded="false" aria-controls="accordionScheduleFormat1">
                Elementary
              </button>
            </h2>
            <div id="accordionScheduleFormat1" class="accordion-collapse collapse" aria-labelledby="headingScheduleFormat1" data-bs-parent="#accordionScheduleFormat">
              <div class="accordion-body">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Descripción</th>
                      <th class="text-end">Enlace</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>ELEMENTARY</td>
                      <td class="text-end">
                        <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/preschool-and-elementary/SCHEDULE-FORMAT/ELEMENTARY.pdf" target="_blank">
                          <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Schemes of work</h2>
      </div>
      <div class="col-12">
        <div class="accordion" id="accordionSchemesOfWork">
          <div class="accordion-item" v-for="(directory, index) of schemesOfWork">
            <h2 class="accordion-header" :id="'headingSchemesOfWork' + index">
              <button class="accordion-button subtitulo-sm mb-0 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#accordionSchemesOfWork' + index" aria-expanded="false" :aria-controls="'accordionSchemesOfWork' + index">
                {{ directory.name }}
              </button>
            </h2>
            <div :id="'accordionSchemesOfWork' + index" class="accordion-collapse collapse" :aria-labelledby="'headingSchemesOfWork' + index" data-bs-parent="#accordionSchemesOfWork">
              <div class="accordion-body">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Descripción</th>
                      <th class="text-end">Enlace</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(file, fileIndex) of directory.contents">
                      <td>{{ fileIndex + 1}}</td>
                      <td>{{ file.name }}</td>
                      <td class="text-end">
                        <a :href="file.path" target="_blank">
                          <span v-if="file.path.includes('.pdf')" class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                          <span v-if="file.path.includes('.docx') || file.path.includes('.doc')" class="iconify" data-icon="icomoon-free:file-word" width="30" height="30" color="blue"></span>
                          <span v-if="file.path.includes('.xlsx') || file.path.includes('.xls')" class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import schemesOfWork from '@/assets/json/proniData.json'

export default {
  name: "PRONIPrimariaView",
  data() {
    return {
      schemesOfWork: schemesOfWork
    }
  }
}
</script>

<style>

</style>