<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEB) - Consejo Técnico Escolar</h1>
        <p>
          El <b>Consejo Técnico Escolar</b> es el órgano colegiado con mayor
          autoridad técnico-pedagógica en cada escuela de educación básica,
          responsable de tomar e implementar decisiones para maximizar el logro
          de aprendizajes de los estudiantes, desarrollar sus capacidades y
          pensamiento crítico, y fortalecer los lazos entre la escuela y la
          comunidad con un enfoque territorial. Su principal objetivo es
          construir un proyecto educativo basado en la diversidad, analizando
          constantemente los logros de los estudiantes y planificando procesos
          de mejora continua en la escuela. Está integrado por todo el colectivo
          docente.
        </p>
        <p>
          La <b>Coordinación Estatal de Consejos Técnicos Escolares</b> tiene
          como propósito fundamental coordinar la implementación de los Consejos
          Técnicos Escolares, a través de los Jefes de Departamento de niveles
          educativos, Jefes de Sector y Supervisores Escolares.
        </p>
        <p>
          El CTE sesiona 13 días del ciclo escolar distribuidos en dos fases:
        </p>
        <ul>
          <li>
            <b>Fase intensiva:</b> cinco diás hábiles previos al inicio de cada
            ciclo escolar.
          </li>
          <li>
            <b>Fase ordinaria:</b> ocho días distribuidos a lo largo de cada
            ciclo escolar.
          </li>
        </ul>
        <p>
          Dichas sesiones ya se encuentran previstas en el calendario escolar.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Lineamientos</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Lineamiento</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                ACUERDO número 05/04/24 por el que se emiten los Lineamientos
                para la integración, operación y funcionamiento de los Consejos
                Técnicos Escolares de Educación Básica.
              </td>
              <td class="text-end">
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=5722476&fecha=08/04/2024#gsc.tab=0" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Blanca Annette Venegas Haro',
          area: 'Coordinación Estatal de Consejos Técnicos Escolares',
          telefono: '449 910 5600 ext. 4196',
          fecha: '23/08/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "ConsejoTecnicoEscolarView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
