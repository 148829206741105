<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mini-1">
          <h1>Cursos de Capacitación</h1>
          <img src="https://www.iea.gob.mx/img/servicios-digitales/i_support.svg" />
          <h2>Estrategia de Formación de Capacidades</h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_video.svg" alt="..." />
          <h3 class="titulo">
            Curso: Optimiza tus Chromebooks y fortalece tus clases con las
            herramientas de Google Workspace para Educación
          </h3>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <a @click="changeCourse(0)" class="pillLink">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_video.svg" alt="..." class="img-fluid" />
              <div> Primaria Vespertino (8 a 9 hrs)</div>
            </div>
          </div>
        </a>
        <a @click="changeCourse(1)" class="pillLink">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_video.svg" alt="..." class="img-fluid" />
              <div>Primaria Matutino (17 a 18 hrs)</div>
            </div>
          </div>
        </a>
        <a @click="changeCourse(2)" class="pillLink">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_video.svg" alt="..." class="img-fluid" />
              <div>Secundaria Sabatino (9 a 10 hrs)</div>
            </div>
          </div>
        </a>
        <a href="https://www.iea.gob.mx/IEA/sistema-educativo/participacion-social/capacitaciones/DOC-Registro-de-Consejos-de-Participación-Escolar.pdf" target="_blank" class="pillLink">
          <div class="pill-card">
            <div class="d-inline-flex align-items-center">
              <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_registro.svg" alt="..." class="img-fluid" />
              <div>No estoy registrado</div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-12 col-lg-8" v-if="course">
        <h4 class="subtitulo">{{ course.title }}</h4>
        <div class="ratio ratio-16x9">
          <iframe width="100%" height="100%" :src="course.link" class="rounded shadow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-lg-8" v-else>
        Proximamente
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'José Roberto Pereda Delgado',
          area: 'Subdirección de Tecnologías de la Información',
          telefono: '449 910 5600 ext. 4915',
          fecha: '07/05/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "CursosView",
  components: {
    RIComponent,
  },
  data() {
    return {
      courses: [
        {
          title: "Primaria Vespertino (8 a 9 hrs)",
          link: "https://www.youtube.com/embed/OXV87b2M1c8"
        },
        null,
        null
      ],
      course: null
    }
  },
  created() {
    this.course = this.courses[0];
  },
  methods: {
    changeCourse(index) {
      this.course = this.courses[index];
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
