<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEB) - Educación Física</h1>
        <p>
          El Departamento de Educación Física tiene la finalidad de brindar
          atención con sesiones de Educación Física en Preescolares, Primarias y
          Centros de Atención Múltiple. Su principal objetivo es trabajar el
          cuerpo humano de los alumnos de este nivel académico, desde el área de
          salud hasta la iniciación y formación deportiva, construyendo
          habilidades que utilicen en su vida cotidiana a través de las
          siguientes acciones y programas:
        </p>
        <ul>
          <li>
            Trabajo en clase directa, a través de la sesión de Educación Física.
          </li>
          <li>Programas de iniciación deportiva con alumnos de 1°, 2° y 3°.</li>
          <li>Programas de ligas deportivas con alumnos de 4°, 5° y 6°.</li>
          <li>Torneos internos, de zona, regionales y estatales.</li>
        </ul>
        <p>
          El 95% de escuelas cuentan con docentes de educación física brindando
          el servicio y enseñanza al alumnado del estado.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Marcos Francisco Pérez Hernández',
          area: 'Departamento de Educación Física',
          telefono: '449 910 5600 ext. 4251',
          fecha: '23/08/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "EducacionFisicaView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
