<template>
  <div class="container border rounded shadow p-3">
    <div class="row">
      <div class="col">
        <router-link to="/educacion-basica/programas-educativos" class="btn btn-ln-bg1">
          <span class="iconify" data-icon="ph:arrow-bend-down-left-bold"></span>
          Regresar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Programa Soy Anfitrión Kids</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p>
          El programa <b>"Soy Anfitrión Kids"</b> es un programa de cultura
          turística impulsado por la <b>Secretaría de Turismo del Estado de
            Aguascalientes (SECTUR)</b> que tiene el objetivo de que todos los niños
          y niñas del Estado de Aguascalientes se conviertan en embajadores
          turísticos y que cuenten con información para conocer más sobre su Estado.
        </p>
        <div class="banner-body-left bg_dg-8">
          <img src="https://www.iea.gob.mx/img/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/banners/img-banner-soy-anfitrion-kids.webp" alt="..." class="img-fluid" />
          <div class="baninfo-wrapper">
            <h1>En Busca del Gigante</h1>
            <p>Libro y Presentación Sobre Cultura Turística</p>
            <div class="d-flex justify-content-end">
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/soy-anfitrion-kids/Libro-de-Cultura-Turística.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light me-3" style="position: static;">
                Ver Libro
              </a>
              <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/soy-anfitrion-kids/Presentación-de-Cultura-Turística.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light" style="position: static;">
                Ver Presentación
              </a>
            </div>
          </div>
        </div>
        <h2 class="subtitulo">Actividades para Aprender</h2>
        <div class="banner_sm bg_dg-1 mb-3">
          <div class="item1">
            <p class="titulo">Descubre la Imagen Secreta</p>
          </div>
          <div class="item2">
            <p class="contenido">Quiz: ¿Qué Tanto Sabes de Nuestro Centro Histórico?</p>
          </div>
          <div class="item3 d-flex">
            <a href="https://view.genial.ly/661eddd6c73ade00148b1605/interactive-content-quiz-imagen-secreta" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light me-3">
              Iniciar Quiz
            </a>
          </div>
        </div>
        <div class="banner_sm bg_dg-7">
          <div class="item1">
            <p class="titulo">Trivia Museo Nacional de Pueblos Mágicos (MUNPUMA)</p>
          </div>
          <div class="item2">
            <p class="contenido">Trivia Sobre Pueblos Mágicos</p>
          </div>
          <div class="item3 d-flex">
            <a href="https://view.genial.ly/661d723ed251070014e9175b/interactive-content-trivia-munpuma" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light me-3">
              Iniciar Trivia
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Rosario Elizabeth Gutiérrez Carreón',
          area: 'Subdirección de Relación Interinstitucional y Social',
          telefono: '449 910 5600 ext. 4074',
          fecha: '30/04/2024'
        }" />
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "SoyAnfitrionKidsView",
  components: {
    RIComponent,
  }
};
</script>

<style lang="scss" scoped>

</style>
