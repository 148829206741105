<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_contrato.svg" alt="..." />
          <h1 class="titulo">
            IEA (DES) - Registro para Colegio de Profesionistas (Artículo 5°
            Fracc. II y Artículo 13)
          </h1>
        </div>
        <p>
          Para registrar un Colegio de Profesionistas, los interesados pueden
          acudir a las oficinas del área de Profesiones de la Dirección de
          Educación Superior del Instituto de Educación de Aguascalientes (IEA),
          ubicadas en Cerro de la Joya 304, Ojocaliente II, 20256
          Aguascalientes, Ags., y presentar las siguiente documentación:
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Documentación requerida</h2>
        <ol type="A" class="fw-bold c_base">
          <li>
            Solicitud por escrito, firmada por el Representante Legal, dirigida
            a la persona titular del IEA.
          </li>
          <li>
            Original y copia de Acta Constitutiva donde se integren Estatutos
            y/o Reglamento que los rijan, la cual debe estar registrado como
            Asociación Civil y Nombre del Colegio.
          </li>
          <li>Directorio con el total de sus miembros no menor a 50.</li>
          <li>
            Original y Copia de Cédula Federal e INE de cada uno de los
            miembros.
          </li>
          <li>Original y Copia de Registro Estatal.</li>
          <li>
            Pago por registro de Colegios de Profesionista en cajas del IEA y
            expedición de autorización para constituir Colegio de Profesiones en
            cajas de Finanzas del Estado (Según la Ley de Ingreso del Estado).
          </li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Listado de Registro de Colegios de Profesionistas en el Instituto de
          Educación de Aguascalientes
        </h2>
        <ol>
          <li>Colegio de Contadores Públicos de Aguascalientes, A.C.</li>
          <li>Colegio de Químicos Clínicos de Aguascalientes, A.C.</li>
          <li>Colegio de Arquitectos del Estado de Aguascalientes, A.C.</li>
          <li>Colegio de Medicos Generales de Aguascalientes, A.C.</li>
          <li>Colegio de Enfermeras de Aguascalientes, A.C.</li>
          <li>Colegio de Cirujanos Dentistas de Aguascalientes, A.C.</li>
          <li>
            Asociacion Mexicana de Contadores Públicos Colegio Regional de
            Aguascalientes, A.C.
          </li>
          <li>Colegio de Ginecología y Obstetricia de Aguascalientes, A.C.</li>
          <li>Colegio de Médicos Anestesiólogos de Aguascalientes, A.C.</li>
          <li>Colegio de Ortopedia y Traumatología de Aguascalientes, A.C.</li>
          <li>
            Colegio Cirugía Plástica, Estética y Reconstructiva de
            Aguascalientes, A.C.
          </li>
          <li>Colegio de Valuadores de Aguascalientes, A.C.</li>
          <li>
            Colegio de Maestros en Cirugía Estetica de Aguascalientes, A.C.
          </li>
          <li>
            Colegio de Médicos Especialistas en Rehabilitación del Estado de
            Aguascalientes, A.C.
          </li>
          <li>Colegio de Trabajadoras Sociales Hidrocálidas, A.C.</li>
          <li>Colegio Regional de Podólogos en Aguascalientes, A.C.</li>
          <li>
            Asociación de Ortodoncia y Ortopedia Dento Maxilo Facial del Estado
            de Aguascalientes. Colegio De Ortodoncistas, A.C.
          </li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Sandra America Macias Campos',
          area: 'Departamento de Planeacion y Supervisión',
          telefono: '449 910 5600 ext. 7834',
          fecha: '20/08/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "ColegiosProfesionistasView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
