<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Estrategias en el Aula</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Estrategia en el Aula: Prevención de Adicciones
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8">
        <p>
          La <b>Secretaría de Educación Pública (SEP)</b>, a través del
          <b>Instituto de Educación de Aguascalientes (IEA)</b>, impulsa la
          <b>Estrategia en el Aula: Prevención de Adicciones</b> en el Estado de
          Aguascalientes. Esta estrategia tiene el objetivo de instrumentar
          acciones en el aula y una campaña de difusión que contribuyan a la
          prevención del consumo de drogas entre estudiantes de secundaria y de
          educación media superior, a través de espacios de reflexión y mensajes
          de comunicación asertivos.
        </p>
        <p>
          Para cumplir este objetivo, se proporciona una guía que ha sido
          diseñada para las y los docentes como figuras clave que, desde su
          cercanía con el estudiantado, contribuyen a prevenir situaciones de
          riesgo. Para cumplir con este propósito, el documento brinda
          información sobre las consecuencias y daños irreversibles que ocasiona
          el consumo de las drogas presentadas en cada apartado.
        </p>
      </div>
      <div class="col-12 col-lg-4">
        <a href="IEA/sistema-educativo/estrategia-aula/Prevención-de-Adicciones-Guia-Docentes.pdf" target="_blank" rel="noopener noreferrer">
          <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/guia-docentes.png" alt="..." class="img-fluid rounded" />
        </a>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-lg-3">
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/cartel-1.png" alt="..." class="img-fluid">
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/cartel-2.png" alt="..." class="img-fluid">
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/cartel-3.png" alt="..." class="img-fluid">
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <p class="fw-bold">
          Para mayor información sobre la Estrategia en el Aula: Prevención de
          Adicciones, visita la página oficial de la SEP:
        </p>
        <div class="banner-body-left bg_dg-6">
          <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/bg-banner.png" alt="..." class="img-fluid" />
          <div class="baninfo-wrapper">
            <p>Estrategia en el Aula: Prevención de Adicciones <br />
              #EstrategiaEnElAula #LaFelicidadEstáEnTi #NoEsJuego
              #SiTeDrogasTeDañas
            </p>
            <div class="d-flex align-items-end flex-column">
              <a href="https://estrategiaenelaula.sep.gob.mx" target="_blank" class="btn btn-principal-light float-end">
                Ir al sitio
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Instituto de Educación de Aguascalientes',
          area: 'Instituto de Educación de Aguascalientes',
          telefono: '449 910 5600',
          fecha: '30/04/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "EstartegiaAulaView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
