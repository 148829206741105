<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Escalafón</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm bg_dg-3 mb-3">
          <div class="item1">
            <p class="titulo">Actualización de Expediente para PAAE</p>
          </div>
          <div class="item2">
            <p class="contenido">Hasta el 31 de Agosto del 2024</p>
          </div>
          <div class="item3">
            <button type="button" class="btn btn-principal-light" data-bs-toggle="modal" data-bs-target="#modal">
              Ver Aviso
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Escalafón 2024</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>CATÁLOGO ESCALAFONARIO</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/escalafon/DOC-Catálogo-Escalafonario.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>MODIFICACIONES AL CATÁLOGO ESCALAFONARIO</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/escalafon/DOC-Modificaciones-al-Catálogo-Escalafonario.pdf"
                  target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <!-- <tr>
              <td>2</td>
              <td>Instructivo crédito escalafonario anual</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/escalafon/Instructivo crédito escalafonario anual.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Escala estimativa para ficha escalafonaria</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/escalafon/Escala estimativa para ficha escalafonaria.pdf" target="_blank">
                  <span class="iconify" data-icon="carbon:document-pdf" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr> -->
            <tr>
              <td>3</td>
              <td>SOLICITUD DE CORRECCIÓN DE PUNTUACIÓN ESCALAFONARIA</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/escalafon/Solicitud-de-Corrección-de-Puntuación-Escalfonaria.xlsx"
                  target="_blank">
                  <span class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                </a>
              </td>
            </tr>
            <!-- <tr>
              <td>5</td>
              <td>Solicitud de concurso</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/escalafon/Solicitud de concurso.xlsx" target="_blank">
                  <span class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                </a>
              </td>
            </tr> -->
            <tr>
              <td>4</td>
              <td>CRÉDITOS ESCALAFONARIOS</td>
              <td class="text-end">
                <a href="https://www.iea.gob.mx/IEA/escalafon/Creditos-Escalafonarios-2023-2024.xls" target="_blank">
                  <span class="iconify" data-icon="fa:file-excel-o" width="30" height="30" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" v-if="calls.length > 0">
      <div class="col">
        <h2 class="subtitulo">Resultados de la Convocatoria de Boletines 2024</h2>
        <ConvocatoriasComponent :calls="calls" v-if="calls.length > 0" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Alejandra Ortiz Serna',
          area: 'Departamento de Desarrollo Institucional',
          telefono: '449 910 5600 ext. 7900 y 7901',
          fecha: '12/07/2024'
        }" />
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title subtitulo" id="modalLabel">Actualización de Expediente para PAAE</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="stCard cartaInfo">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_informes.svg" alt="icono" class="icono" />
            <p class="titulo">Actualización de Expediente para PAAE</p>
            <div class="descripcion">
              <p>
                A todo el Personal de Asistencia y Apoyo a la Educación (PAAE´s), se
                convoca a actualizar su expediente antes del 31 de Agosto. <br />
              </p>
              <p>Requisitos:</p>
              <div class="text-start">
                <p>
                  Acudir a las instalaciones establecidas con ORIGINAL Y UNA COPIA
                  de:
                </p>
                <ul>
                  <li>
                    Constancia de Servicios expedida por la Subdirección de Capital
                    Humano.
                  </li>
                  <li>Formato Único de Personal (FUP).</li>
                  <li>Último Talón de pago.</li>
                  <li>Formato de Crédito Escalafonario/Ficha escalafonaria.</li>
                </ul>
              </div>
              <p>
                Fecha de recepción de documentos:
                <b>A partir del 17 de Julio y hasta el 31 de Agosto</b>.
              </p>
              <p>
                Domicilio:
                <b>C. Cotorinas 203, Esquina con Av. Ojocaliente, Fracc. Ojocaliente I, 20190 Aguascalientes, Ags.</b>.
              </p>
              <p>Teléfono: <b>449 910 5600 ext. 7900 y 7901</b>.</p>
              <p>
                Atentamente: <br />
                Comisión Estatal Mixta de Escalafón
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ConvocatoriasComponent from '../components/ConvocatoriasComponent.vue';
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "EscalafonView",
  components: {
    ConvocatoriasComponent,
    RIComponent
  },
  data() {
    return {
      calls: [
        // {
        //   title: "Boletín (01) 01S/2024 (Educación Inicial / Intendencia)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-01S-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-01S-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 02A/2024 (Educación Preescolar / Administrativo)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-02A-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-02A-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 02S/2024 (Educación Preescolar / Intendencia)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-02S-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-02S-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 03S/2024 (Educación Primaria / Intendencia)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-03S-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-03S-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 04A/2024 (Educación Especial / Administrativo)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-04A-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-04A-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 04S/2024 (Educación Especial / Intendencia)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-04S-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-04S-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 06A/2024 (Secundarias Generales / Administrativo)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-06A-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-06A-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 07A/2024 (Secundarias Técnicas / Administrativo)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-07A-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-07A-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 07S/2024 (Secundarias Técnicas / Intendencia)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-07S-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-07S-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 13A/2024 (IEA / Administrativo)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-13A-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-13A-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 13S/2024 (IEA / Intendencia)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-13S-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-13S-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 18A/2024 (Estado y Municipio / Administrativo)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-18A-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-18A-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
        // {
        //   title: "Boletín (01) 19A/2024 (Telesecundarias / Administrativo)",
        //   type: "Concurso",
        //   links: [
        //     {
        //       description: "Ver Boletín",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/CVT-BOLETIN-(01)-19A-2024.pdf",
        //       validity: "10/06/2024 - 21/06/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/escalafon/convocatorias/2024/RES-BOLETIN-(01)-19A-2024.pdf",
        //       validity: "03/07/2024 - 26/07/2024"
        //     }
        //   ],
        //   validity: "10/06/2024 - 26/07/2024"
        // },
      ]
    };
  },
  mounted() {
    gea.iwin(() => {
      const myModal = bootstrap.Modal.getOrCreateInstance("#modal");
      myModal.show()
    });
  },
  updated() {
    gea.iwin(() => {
      const myModal = bootstrap.Modal.getOrCreateInstance("#modal");
      myModal.show()
    });
  }
}
</script>

<style lang="scss" scoped></style>
