<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Historia</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          El Proceso de Federalización - Descentralización de la Educación en
          México
        </h2>
        <p>
          En el Estado de Aguascalientes, desde 1940 hasta 1976 la
          administración de los servicios educativos había dependido
          directamente del Gobierno federal. En 1940 se firmó el acuerdo por
          medio del cual el sistema educativo estatal pasaba a depender del
          Gobierno federal.
        </p>
        <p>
          Al principio el Gobierno del Estado de Aguascalientes no conservó bajo
          su control ninguna escuela primaria, solo algunas escuelas secundarias
          y jardines de niños, por lo que el tratamiento de los asuntos
          educativos eran lentos y difícil, por lo que el 22 de marzo de 1978 se
          instaló una Delegación General de la SEP, dándose una desconcentración
          netamente administrativa más que una descentralización.
        </p>
        <p>
          El primer delegado de la SEP, el CP. Humberto Martínez de León,
          comenzó con el proceso de desconcentración administrativa, en un lapso
          de dos años, lo sucedió el profesor Mario Aguilere Dorantes quien
          continuó con la desconcentración.
        </p>
        <p>
          El 12 de noviembre de 1984, el gobernador Rodolfo Landeros Gallegos
          firmó el Convenio de Coordinación entre el Gobierno del Estado y la
          SEP para la prestación coordinada de los servicios educativos en el
          estado, lo que dio paso a la Secretaría de los Servicios Coordinados
          de Educación Pública en el Estado (SSCEPE), que funcionó hasta 1986.
        </p>
        <p>
          Durante este periodo se formuló un Programa Estatal de Educación 1984
          - 1988, estableciendose también el Consejo Estatal de Educación
          Pública y el Consejo Estatal Técnico de la Educación.
        </p>
        <p>
          El 7 de junio de 1992, después de la firma del Acuerdo Nacional para
          la Modernización Educativa, el Congreso del Estado expidió la Ley por
          la que se creó el Instituto de Educación de Aguascalientes, que se
          encargaría de la educación básica y normal, y se ocuparía de todos los
          niveles educativos en el Estado.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Creación del Instituto de Educación de Aguascalientes
        </h2>
        <p>
          La creación del Instituto de Educación de Aguascalientes se dió el 17
          de enero de 1993. Se definió como un organismo descentralizado del
          Gobierno del Estado, con personalidad jurídica y patrimonio propios,
          con responsabilidad sobre todos los tipos, niveles y modalidades
          educativos que se impartan, ofrezcan o promuevan en la entidad.
        </p>
        <p>
          El objetivo del IEA es llegar a la municipalización educativa, de
          reconocimiento y fortalecimiento del municipio como entidad soberana
          que puede hacerse cargo de su propio sistema educativo.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Directores del Instituto de Educación de Aguascalientes
        </h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Cargo</th>
              <th>Nombre</th>
              <th>Periodo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="9">
                Directores Federales de Educación de Aguascalientes
              </td>
              <td>Profr. Rafael Villena</td>
              <td>(1945 - 1947)</td>
            </tr>
            <tr>
              <td>Profr. Enrique García Gallegos</td>
              <td>(1947 - 1949)</td>
            </tr>
            <tr>
              <td>Profr. Luis Medellín Niño</td>
              <td>(1949 - 1953)</td>
            </tr>
            <tr>
              <td>Profr. Oscar M. González</td>
              <td>(1953 - 1958)</td>
            </tr>
            <tr>
              <td>Profr. Faustino Villaverde</td>
              <td>(1958 - 1962)</td>
            </tr>
            <tr>
              <td>Profr. Luis Regalado Ceceña</td>
              <td>(1962 - 1965)</td>
            </tr>
            <tr>
              <td>Profr. Enrique Martínez Monroy</td>
              <td>(1965 - 1975)</td>
            </tr>
            <tr>
              <td>Profr. Alfredo Macías Reyes</td>
              <td>(1975 1979)</td>
            </tr>
            <tr>
              <td>Profr. José Amador Gil</td>
              <td>(1979)</td>
            </tr>
            <tr>
              <td rowspan="6">Delegados Generales de la SEP</td>
              <td>C.P. Humberto Martínez de León</td>
              <td>(1979 - 1980)</td>
            </tr>
            <tr>
              <td>Profr. Mario Aguilera Dorantes</td>
              <td>(1980 - 1985)</td>
            </tr>
            <tr>
              <td>Profr. J. Santos Reyna Martínez</td>
              <td>(1985 - 1986)</td>
            </tr>
            <tr>
              <td>Profr. Salvador Martínez Macías</td>
              <td>(1986 - 1989)</td>
            </tr>
            <tr>
              <td>Profr. José T. Quiroz González</td>
              <td>(1989 - 1992)</td>
            </tr>
            <tr>
              <td>Lic. Jorge Varona Rodríguez</td>
              <td>(Junio de 1992 - Noviembre de 1992)</td>
            </tr>
            <tr>
              <td>
                Responsables de la Sub-Unidad de Servicios Descentralizados
              </td>
              <td>Profr. Rubén Ortíz Aréchar</td>
              <td>(1971 - 1979)</td>
            </tr>
            <tr>
              <td rowspan="12">
                Directores del Instituto de Educación de Aguascalientes
              </td>
              <td>Lic. Jesús Álvarez Gutiérrez</td>
              <td>(Diciembre de 1992 - 30 de Noviembre de 1998)</td>
            </tr>
            <tr>
              <td>Dr. Miguel Ángel Ochoa Sánchez</td>
              <td>(Diciembre de 1998 - Abril de 2003)</td>
            </tr>
            <tr>
              <td>Lic. Jorge Guillén Muñoz</td>
              <td>(6 de Abril de 2003 - 4 de Agosto de 2005)</td>
            </tr>
            <tr>
              <td>Lic. Jorge Enrique Juárez Barba</td>
              <td>(Agosto de 2005 - Agosto de 2007)</td>
            </tr>
            <tr>
              <td>Ing. Oscar Ponce Hernández</td>
              <td>(20 de Agosto de 2007 - Noviembre de 2010)</td>
            </tr>
            <tr>
              <td>Mtro. Francisco J. Chávez Rangel</td>
              <td>(1 de Diciembre de 2010 - 22 de Febrero de 2013)</td>
            </tr>
            <tr>
              <td>Mtro. Fernando Macías Garnica</td>
              <td>(3 Junio de 2013 - 25 de Febrero de 2014)</td>
            </tr>
            <tr>
              <td>Mtro. Francisco J. Chávez Rangel</td>
              <td>(25 de Febrero de 2014 - 30 de Noviembre de 2016)</td>
            </tr>
            <tr>
              <td>Profr. Raúl Silva Perezchica</td>
              <td>(1 de Diciembre de 2016 - 10 de Marzo de 2021)</td>
            </tr>
            <tr>
              <td>Mtro. Ulises Reyes Esparza</td>
              <td>(10 de Marzo de 2021 - 30 de Septiembre de 2022)</td>
            </tr>
            <tr>
              <td>Mtra. Lorena Martínez Rodríguez</td>
              <td>(1 de Octubre de 2022 - 31 de Enero de 2024)</td>
            </tr>
            <tr>
              <td>Mtro. Luis Enrique Gutiérrez Reynoso</td>
              <td>(1 de Febrero de 2024 - Actualidad)</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'David Alejandro López Rubalcava',
          area: 'Coordinación de Comunicación Social',
          telefono: '449 910 5600 ext. 4372',
          fecha: '10/05/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "HistoriaView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
