<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DES) - Misión, Visión y Valores</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Misión</h2>
        <p>
          Proporcionar a la población del Estado de Aguascalientes, servicios de
          apoyo eficientes y con equidad, así como programas educativos de
          calidad, y pertinentes a las necesidades de la comunidad y el mercado
          laboral, a fin de contribuir al desarrollo pleno de la sociedad.
        </p>
        <h2 class="subtitulo">Visión</h2>
        <p>
          Ser reconocidos nacional e internacionalmente por la calidad del
          sistema de educación superior del Estado, que contribuye a mejorar la
          calidad de vida de la sociedad.
        </p>
        <h2 class="subtitulo">Valores</h2>
        <ul>
          <li>Excelencia.</li>
          <li>Solidaridad.</li>
          <li>Equidad.</li>
          <li>Integridad.</li>
          <li>Innovación.</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Stefany Karina Jiménez Colombo',
            area: 'Departamento de Divulgación y Educación Científica y Tecnológica',
            telefono: '449 149 2400 ext. 7805',
            fecha: '05/04/2024'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "SomosView",
  components: {
    RIComponent
  }
};
</script>

<style>

</style>
