<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA (DEMS) - Telebachilleratos Comunitarios</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          Los planteles del subsistema de Telebachilleratos Comunitarios del
          estado de Aguascalientes se encuentran ubicados en comunidades rurales
          de la entidad. En Aguascalientes estos planteles dependen de la
          Dirección de Educación Media Superior del Instituto de Educación de
          Aguascalientes, a través del departamento de Telebachilleratos
          Comunitarios.
        </p>
        <p>
          Actualmente existen 106 planteles en todo el estado, mismo que se
          ubican en los municipios de Asientos, Calvillo, Cosío, Jesús María, El
          Llano, Pabellón de Arteaga, Rincón de Romos, San José de Gracia,
          Tepezalá, San Francisco de los Romo y en el municipio capital.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
            nombre: 'Juan Luis Medina Ibarra',
            area: 'Departamento de Sistemas de Telebachilleratos',
            telefono: '449 149 2400 ext. 7800',
            fecha: '08/01/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "TelebachilleratosComunitariosView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped></style>
